import { useQuery } from "@apollo/client";
import { compact, find, some } from "lodash";
import { gql } from "../__generatedGQL__/gql";
import { GetResourcesQuery, ResourceType } from "../__generatedGQL__/graphql";
import { GET_REPOS } from "../containers/RepoSettings";

export const GET_RESOURCES = gql(`
  query GetResources {
    resources {
      id
      externalId
      type
      userId
      createdAt
      updatedAt
      config
    }
  }
`);

type UseExternalResourcesHookResult = {
  loading: boolean;
  error: any;
  isExternalTicketingConnected: boolean;
  githubResource?: GetResourcesQuery["resources"][number];
  gitlabResource?: GetResourcesQuery["resources"][number];
  connectedTicketingResources: Array<GetResourcesQuery["resources"][number]>;
  hasEnabledRepos: boolean;
  hasTestingEnabledRepos: boolean;
  refetchResources: () => Promise<void>;
};

export const useExternalResourcesHook = (): UseExternalResourcesHookResult => {
  const {
    loading: resourcesLoading,
    error: resourcesError,
    data: resourcesData,
    refetch: refetchResources,
  } = useQuery(GET_RESOURCES);
  const githubResource = find(resourcesData?.resources, { type: ResourceType.Github });
  const gitlabResource = find(resourcesData?.resources, { type: ResourceType.Gitlab });

  const codeHostingResource = githubResource || gitlabResource;
  const {
    loading: reposLoading,
    error: reposError,
    data: reposData,
  } = useQuery(GET_REPOS, {
    variables: { resourceId: codeHostingResource?.id ?? 0 },
    skip: !codeHostingResource?.id,
  });

  if (resourcesLoading || reposLoading || resourcesError || reposError) {
    return {
      loading: resourcesLoading || reposLoading,
      error: resourcesError || reposError,
      isExternalTicketingConnected: false,
      githubResource: undefined,
      gitlabResource: undefined,
      connectedTicketingResources: [],
      hasEnabledRepos: false,
      hasTestingEnabledRepos: false,
      refetchResources: async () => {
        await refetchResources();
      },
    };
  }

  const linearResource = find(resourcesData?.resources, { type: ResourceType.Linear });
  const jiraResource = find(resourcesData?.resources, { type: ResourceType.Jira });
  const notionResource = find(resourcesData?.resources, { type: ResourceType.Notion });

  const isGithubConnected = !!githubResource;
  const isExternalTicketingConnected =
    !!linearResource ||
    !!jiraResource ||
    !!notionResource ||
    (isGithubConnected && githubResource.config?.issuesIntegrationEnabled);

  const hasEnabledRepos = some(reposData?.repos, "enabled");
  const hasTestingEnabledRepos = some(
    reposData?.repos,
    (repo) => repo.testingConfig?.testCheckEnabled,
  );

  return {
    loading: false,
    error: null,
    isExternalTicketingConnected,
    githubResource,
    gitlabResource,
    connectedTicketingResources: compact([
      linearResource,
      jiraResource,
      notionResource,
      githubResource?.config?.issuesIntegrationEnabled ? githubResource : undefined,
    ]),
    hasEnabledRepos,
    hasTestingEnabledRepos,
    refetchResources: async () => {
      await refetchResources();
    },
  };
};
