import { useNavigate } from "react-router-dom";
import { Button } from "./Button";

import { useNotificationContext } from "../providers";
import { MARKETING_WEB_URL } from "../utils/environment";

interface IProps {
  errorCode?: string | null;
  errorTitle?: string;
  errorDescription?: string;
  homePage?: "app" | "marketing";
}

export const ErrorPage = ({
  errorCode = "404",
  errorTitle = "Page not found",
  errorDescription = "Sorry, we couldn't find the page you're looking for.",
  homePage = "app",
}: IProps) => {
  const navigate = useNavigate();
  const { showNotification } = useNotificationContext();

  const copySupportEmail = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    navigator.clipboard.writeText("support@usetusk.ai");
    showNotification({
      title: `Support email copied 📋`,
    });
  };

  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          {errorCode && <p className="text-base font-semibold text-purple-600">{errorCode}</p>}
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {errorTitle}
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">{errorDescription}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Button
              onClick={() => {
                if (homePage === "app") {
                  navigate("/app");
                } else {
                  window.location.href = MARKETING_WEB_URL;
                }
              }}
              variant="primary"
              size="lg"
            >
              Go back home
            </Button>
            <a
              className="text-sm font-semibold text-gray-900 hover:text-purple-600 hover:cursor-pointer"
              onClick={copySupportEmail}
            >
              Contact support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
};
