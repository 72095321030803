// @ts-nocheck

import { useMutation } from "@apollo/client";
import { gql } from "../__generatedGQL__/gql";

const RETRY_TASK = gql(`
  mutation RetryTask($id: String!, $additionalContext: String) {
    retryTask(id: $id, additionalContext: $additionalContext)
  }
`);

const TEST_TASK = gql(`
  mutation TestTask($pullRequestId: String!) {
    testTask(pullRequestId: $pullRequestId)
  }
`);

const DELETE_TASK = gql(`
  mutation DeleteTask($id: String!) {
    deleteTask(id: $id)
  }
`);

const CREATE_PULL_REQUEST = gql(`
  mutation CreatePullRequest($taskId: String!) {
    createPullRequest(taskId: $taskId) {
      id
      externalUrl
    }
  }
`);

export const useTaskHook = () => {
  const [retryTaskMutation, { loading: retryingTask }] = useMutation(RETRY_TASK);
  const [testTaskMutation, { loading: testingTask }] = useMutation(TEST_TASK);
  const [deleteTaskMutation, { loading: deletingTask }] = useMutation(DELETE_TASK);
  const [createPullRequestMutation, { loading: creatingPullRequest }] =
    useMutation(CREATE_PULL_REQUEST);

  const retryTask = async (id: string, additionalContext?: string) => {
    await retryTaskMutation({ variables: { id, additionalContext } });
  };

  const testTask = async (pullRequestId: string) => {
    await testTaskMutation({ variables: { pullRequestId } });
  };

  const deleteTask = async (id: string) => {
    await deleteTaskMutation({ variables: { id } });
  };

  const createPullRequestFromTask = async (taskId: string): Promise<string> => {
    const mutationOutput = await createPullRequestMutation({ variables: { taskId } });
    return mutationOutput.data.createPullRequest.externalUrl;
  };

  return {
    retryTask,
    testTask,
    deleteTask,
    createPullRequestFromTask,
  };
};
