import { useState } from "react";
import { Button } from "./Button";
import { IconTooltip } from "./IconTooltip";
import { TooltipWrapper } from "./Tooltip";

interface IProps {
  resourceName: string;
  resourceLogo: string;
  resourceDescription: string;
  authed: boolean;
  onConnect: () => void;
  connectDisabled?: boolean;
  connectDisabledTooltip?: string;
  connectCopy?: string;
  connectedCopy?: string;
}

export const ConnectExternalResource = ({
  onConnect,
  resourceName,
  resourceLogo,
  resourceDescription,
  authed,
  connectCopy = "Connect",
  connectedCopy = "Connected",
  connectDisabled = false,
  connectDisabledTooltip,
}: IProps) => {
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnect = async () => {
    setIsConnecting(true);
    await onConnect();
    setIsConnecting(false);
  };

  return (
    <div className="justify-center items-center py-1 rounded-lg border border-gray-300 w-80">
      <div className="flex flex-col p-4 text-center">
        <img
          className="mx-auto h-10 w-10 shrink-0 rounded-full"
          src={resourceLogo}
          alt={`${resourceName} logo`}
        />
        <h3 className="mt-1 text-sm font-medium text-gray-900">{resourceName}</h3>
        <dl className="mt-1 flex grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{resourceDescription}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            {authed ? (
              <div className="">
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm text-green-700 ring-1 ring-inset ring-green-600/20">
                  {connectedCopy}
                </span>
              </div>
            ) : (
              <Button
                onClick={handleConnect}
                loading={isConnecting}
                disabled={connectDisabled}
                tooltipText={connectDisabled ? connectDisabledTooltip : undefined}
                size="md"
              >
                {connectCopy}
              </Button>
            )}
          </dd>
        </dl>
      </div>
    </div>
  );
};

export const NonAuthExternalResource = ({
  resourceName,
  resourceLogo,
  resourceDescription,
  tooltipText,
}: {
  resourceName: string;
  resourceLogo: string;
  resourceDescription: string;
  tooltipText?: string;
}) => {
  return (
    <div className="justify-center items-center py-1 rounded-lg border border-gray-300 w-80">
      <div className="flex flex-col p-4 text-center">
        <img
          className="mx-auto h-10 w-10 shrink-0 rounded-full"
          src={resourceLogo}
          alt={`${resourceName} logo`}
        />
        <h3 className="mt-1 text-sm font-medium text-gray-900">{resourceName}</h3>
        <dl className="mt-1 flex grow flex-col justify-between">
          <dt className="sr-only">Title</dt>
          <dd className="text-sm text-gray-500">{resourceDescription}</dd>
          <dt className="sr-only">Role</dt>
          <dd className="mt-3">
            <TooltipWrapper tooltipText={tooltipText}>
              <div className="">
                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-sm text-green-700 ring-1 ring-inset ring-green-600/20">
                  Enabled
                </span>
              </div>
            </TooltipWrapper>
          </dd>
        </dl>
      </div>
    </div>
  );
};
