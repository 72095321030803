import { clsx } from "clsx";
import { PiTreeViewFill } from "react-icons/pi";
import { Button } from "../../../components/Button";
import { TestingSandboxConfigType } from "../../../__generatedGQL__/graphql";

interface INavigationTestingSandboxConfig {
  id: string;
  name: string;
  type: TestingSandboxConfigType;
  parentConfigId?: string;
}

interface VerticalNavigationProps {
  currentTestingSandboxConfigId: string;
  currentTab: "instructions" | "execution";
  testingSandboxConfigOptions: INavigationTestingSandboxConfig[];
  onSelectTestingSandboxConfig: (id: string, tab: "instructions" | "execution") => void;
}

interface ConfigWithChildren {
  config: INavigationTestingSandboxConfig;
  children?: INavigationTestingSandboxConfig[];
}

export const VerticalNavigation = ({
  currentTestingSandboxConfigId,
  currentTab,
  testingSandboxConfigOptions,
  onSelectTestingSandboxConfig,
}: VerticalNavigationProps) => {
  // Sort configs so parents come before children
  const sortedConfigs = [...testingSandboxConfigOptions].sort((a, b) => {
    // If a is a parent (no parentConfigId) and b is a child, a comes first
    if (!a.parentConfigId && b.parentConfigId) return -1;
    // If b is a parent and a is a child, b comes first
    if (a.parentConfigId && !b.parentConfigId) return 1;
    // Otherwise maintain original order
    return 0;
  });

  // Organize configs into parent-children structure
  const organizedConfigs = sortedConfigs.reduce<ConfigWithChildren[]>((acc, config) => {
    if (!config.parentConfigId) {
      // This is a top-level config
      acc.push({ config });
    } else {
      // Find parent and add to its children
      const parent = acc.find((item) => item.config.id === config.parentConfigId);
      if (parent) {
        if (!parent.children) parent.children = [];
        parent.children.push(config);
      }
    }
    return acc;
  }, []);

  const renderConfigItem = (config: INavigationTestingSandboxConfig, isChild: boolean = false) => (
    <li key={config.id}>
      <div
        className={clsx(
          "text-sm font-medium px-3 py-2",
          currentTestingSandboxConfigId === config.id
            ? "text-purple-600 cursor-default"
            : "text-gray-500 hover:text-gray-800 cursor-pointer",
          isChild && "pl-8",
        )}
        onClick={() => onSelectTestingSandboxConfig(config.id, "instructions")}
      >
        <span className="flex items-center gap-x-1">
          {config.type === TestingSandboxConfigType.Parent && (
            <PiTreeViewFill className="w-4 h-4" />
          )}
          {config.name}
        </span>
      </div>
      {/* <ul className={clsx("space-y-1", isChild ? "pl-7" : "pl-4")}>
        {false && (
          <li>
            <a
              onClick={() => onSelectTestingSandboxConfig(config.id, "instructions")}
              className={clsx(
                currentTestingSandboxConfigId === config.id && currentTab === "instructions"
                  ? "bg-gray-50 text-gray-600"
                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-700",
                "group flex gap-x-3 rounded-md p-2 pl-3 text-sm/6 font-semibold cursor-pointer",
              )}
            >
              Custom Instructions
            </a>
          </li>
        )}
        {false && (
          // TODO-CUSTOMIZATION: add test execution tab
          <li>
            <a
              onClick={() => onSelectTestingSandboxConfig(config.id, "execution")}
              className={clsx(
                currentTestingSandboxConfigId === config.id && currentTab === "execution"
                  ? "bg-gray-50 text-purple-600"
                  : "text-gray-700 hover:bg-gray-50 hover:text-purple-600",
                "group flex gap-x-3 rounded-md p-2 pl-3 text-sm/6 font-semibold cursor-pointer",
              )}
            >
              Test Execution
            </a>
          </li>
        )}
      </ul> */}
    </li>
  );

  return (
    <nav aria-label="Sidebar" className="flex flex-1 flex-col">
      <ul role="list" className="-mx-2 space-y-1">
        {organizedConfigs.map(({ config, children }) => (
          <div key={config.id}>
            {renderConfigItem(config)}
            {children?.map((childConfig) => renderConfigItem(childConfig, true))}
          </div>
        ))}
      </ul>
      {/* {false && (
        // TODO-CUSTOMIZATION: add new config button, maybe just have it contact founders?
        <Button variant="secondary" size="md" className="mt-4">
          Add new config
        </Button>
      )} */}
    </nav>
  );
};
