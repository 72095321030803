import { InformationCircleIcon } from "@heroicons/react/20/solid";

export const InformationBanner = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="mt-2 rounded-md bg-purple-50 p-4">
      <div className="flex items-center">
        <div className="shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-purple-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm text-gray-6000">{children}</h3>
        </div>
      </div>
    </div>
  );
};
