import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { CustomAuth0Provider, NotificationProvider } from "./providers";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";
import { useEffect } from "react";

export const ExternalRedirect = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

export const App = () => {
  return (
    <BrowserRouter>
      <NotificationProvider>
        <CustomAuth0Provider>
          <Routes>
            <Route path="/*" element={<Navigate to={"/app"} replace />} />
            <Route path="/app/*" element={<AuthenticatedRoute />} />
          </Routes>
        </CustomAuth0Provider>
      </NotificationProvider>
    </BrowserRouter>
  );
};
