import {
  GetTestingCommitCheckRunQuery,
  ExcludedReason,
  SampleContext,
  SymbolDroppedReason,
} from "../../__generatedGQL__/graphql";

export type GraphQLTestScenario = NonNullable<
  GetTestingCommitCheckRunQuery["testingCommitCheckRun"]
>["testScenarios"][number];

export type GraphQLTestIterationHistory = NonNullable<
  NonNullable<
    NonNullable<GetTestingCommitCheckRunQuery["testingCommitCheckRun"]>["testScenarios"]
  >[number]["testIterationHistory"]
>[number];

export type TestScenarioFilter = "included-in-check-output" | "excluded-in-check-output";

export const shouldShowTestScenario = ({
  testScenario,
  filters,
  showDetailedView,
}: {
  testScenario: GraphQLTestScenario;
  filters: Set<TestScenarioFilter>;
  showDetailedView: boolean;
}) => {
  // If no filters are selected, show all test scenarios
  if (filters.size === 0) {
    return true;
  }

  // OR filter logic - show if it matches any selected filter
  if (filters.has("included-in-check-output") && testScenario.includedInCheckOutput) {
    return true;
  }
  if (filters.has("excluded-in-check-output") && !testScenario.includedInCheckOutput) {
    // If detailed view is not enabled, we only want to show relevant excluded test scenarios to user
    if (!showDetailedView && testScenario.excludedReason) {
      const excludedReasonShowInExcludedTestsView =
        ExcludedReasonMap[testScenario.excludedReason].showInExcludedTestsView;
      if (!excludedReasonShowInExcludedTestsView) {
        return false;
      }
    }
    return true;
  }

  // If we get here, the scenario doesn't match any selected filter
  return false;
};

export const SymbolDroppedReasonMap: Record<
  SymbolDroppedReason,
  {
    humanReadable: string;
  }
> = {
  [SymbolDroppedReason.IsNotTestable]: {
    humanReadable: "Classified as not testable",
  },
  [SymbolDroppedReason.IsNotWorthTesting]: {
    humanReadable: "Classified as not worth testing",
  },
  [SymbolDroppedReason.LlmRanking]: {
    humanReadable: "Ranked low importance",
  },
  [SymbolDroppedReason.OverLimitWithBackfill]: {
    humanReadable: "Prioritized backfill symbols",
  },
  [SymbolDroppedReason.ParentSymbolsOverLimit]: {
    humanReadable: "Parent symbols over limit",
  },
};

export const ExcludedReasonMap: Record<
  ExcludedReason,
  {
    humanReadable: string;
    showInExcludedTestsView: boolean;
    adminReadable?: string;
  }
> = {
  [ExcludedReason.Classification]: {
    humanReadable: "Classification",
    showInExcludedTestsView: false,
  },
  [ExcludedReason.SourceFunctionErrorTestFailed]: {
    humanReadable: "Source code issue",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.Importance]: {
    humanReadable: "Low importance",
    showInExcludedTestsView: false,
  },
  [ExcludedReason.DeduplicatedTests]: {
    humanReadable: "Duplicate test",
    showInExcludedTestsView: false,
  },
  [ExcludedReason.FailedToLintTest]: {
    humanReadable: "Failed to lint test",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.FailedToFixTest]: {
    humanReadable: "Failed to fix test",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.IncorporationFailedGeneration]: {
    humanReadable: "Incorporation failed generation",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.IncorporationFailedIntegration]: {
    humanReadable: "Incorporation failed integration",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.IncorporationFailedLinting]: {
    humanReadable: "Incorporation failed linting",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.IncorporationFailedNoRepresentativeHappyPathTest]: {
    humanReadable: "Failed to consolidate",
    adminReadable: "Failed to consolidate (no seed test)",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.IncorporationFailedTestExecution]: {
    humanReadable: "Failed to consolidate",
    adminReadable: "Failed to consolidate (test execution failed)",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.LimitingEdgeCases]: {
    humanReadable: "Limiting edge cases",
    showInExcludedTestsView: false,
  },
  [ExcludedReason.Other]: {
    humanReadable: "Other",
    showInExcludedTestsView: false,
  },
  [ExcludedReason.NoHappyPathTestScenarios]: {
    humanReadable: "No seed tests",
    showInExcludedTestsView: false,
  },
  [ExcludedReason.RepresentativeHappyPathTestFailed]: {
    humanReadable: "Seed test failed",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.RepresentativeHappyPathNoValue]: {
    humanReadable: "Low value seed test",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.RunCancelled]: {
    humanReadable: "Check cancelled",
    showInExcludedTestsView: false,
  },
  [ExcludedReason.SandboxFailedToStart]: {
    humanReadable: "Testing environment failed",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.TestFailedToGetResults]: {
    humanReadable: "No test results",
    showInExcludedTestsView: true,
  },
  [ExcludedReason.ValueClassification]: {
    humanReadable: "Value classification",
    showInExcludedTestsView: true,
    adminReadable: "Value classification",
  },
};

export const SampleContextMap: Record<
  SampleContext,
  {
    humanReadable: string;
    adminReadable?: string;
  }
> = {
  [SampleContext.ChildrenMocks]: {
    humanReadable: "Children mocks",
  },
  [SampleContext.ChildrenTestFiles]: {
    humanReadable: "Children test files",
  },
  [SampleContext.SymbolMocks]: {
    humanReadable: "Symbol mocks",
  },
  [SampleContext.SymbolTestFiles]: {
    humanReadable: "Symbol test files",
  },
};
