import { Fragment } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import {
  TestScenarioPositiveFeedback,
  TestScenarioNegativeFeedback,
} from "../__generatedGQL__/graphql";

export const TestScenarioFeedbackModal = ({
  open,
  setOpen,
  feedbackType,
  positiveFeedback,
  setPositiveFeedback,
  negativeFeedback,
  setNegativeFeedback,
  comment,
  setComment,
  onSubmit,
  onCancel,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  feedbackType: "positive" | "negative" | null;
  positiveFeedback: TestScenarioPositiveFeedback[];
  setPositiveFeedback: (feedback: TestScenarioPositiveFeedback[]) => void;
  negativeFeedback: TestScenarioNegativeFeedback[];
  setNegativeFeedback: (feedback: TestScenarioNegativeFeedback[]) => void;
  comment: string;
  setComment: (comment: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const togglePositiveFeedback = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: TestScenarioPositiveFeedback,
  ) => {
    e.stopPropagation();
    setPositiveFeedback(
      positiveFeedback.includes(type)
        ? positiveFeedback.filter((t) => t !== type)
        : [...positiveFeedback, type],
    );
  };

  const toggleNegativeFeedback = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: TestScenarioNegativeFeedback,
  ) => {
    e.stopPropagation();
    setNegativeFeedback(
      negativeFeedback.includes(type)
        ? negativeFeedback.filter((t) => t !== type)
        : [...negativeFeedback, type],
    );
  };

  const isSubmitDisabled =
    (feedbackType === "positive" && positiveFeedback.length === 0 && comment.trim() === "") ||
    (feedbackType === "negative" && negativeFeedback.length === 0 && comment.trim() === "");

  const renderFeedbackOptions = (
    feedbackEnum: typeof TestScenarioPositiveFeedback | typeof TestScenarioNegativeFeedback,
    selectedOptions: any[],
    toggleHandler: (e: React.ChangeEvent<HTMLInputElement>, type: any) => void,
    prefix: string,
  ) => {
    return Object.values(feedbackEnum)
      .sort((a, b) => (a === "OTHER" ? 1 : b === "OTHER" ? -1 : a.localeCompare(b)))
      .map((type) => (
        <div key={type} className="flex items-center">
          <input
            type="checkbox"
            id={`${prefix}-${type}`}
            checked={selectedOptions.includes(type)}
            onChange={(e) => toggleHandler(e, type)}
            className="h-4 w-4 rounded-sm border-gray-300 text-purple-600 focus:ring-purple-500"
            onClick={(e) => e.stopPropagation()}
          />
          <label htmlFor={`${prefix}-${type}`} className="ml-2 text-sm text-gray-700">
            {type
              .replace(/_/g, " ")
              .toLowerCase()
              .replace(/^\w/, (c: string) => c.toUpperCase())}
          </label>
        </div>
      ));
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={onCancel}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {feedbackType === "positive"
                        ? "What did you like about this test?"
                        : "What's the issue with this test?"}
                    </DialogTitle>
                    <div className="mt-4">
                      <div className="space-y-2 mb-4">
                        {feedbackType === "positive"
                          ? renderFeedbackOptions(
                              TestScenarioPositiveFeedback,
                              positiveFeedback,
                              togglePositiveFeedback,
                              "positive",
                            )
                          : renderFeedbackOptions(
                              TestScenarioNegativeFeedback,
                              negativeFeedback,
                              toggleNegativeFeedback,
                              "negative",
                            )}
                      </div>

                      <div className="mt-4">
                        <label
                          htmlFor="comment"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Additional comments
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="comment"
                            name="comment"
                            rows={3}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            className="block w-full rounded-md border-gray-300 shadow-xs focus:border-purple-500 focus:ring-purple-500 sm:text-sm px-3 py-2"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                  <Button
                    className={`${
                      false ? "bg-gray-400 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-500"
                    } text-white shadow-sm sm:ml-3 sm:w-auto`}
                    onClick={onSubmit}
                    disabled={false}
                  >
                    Submit
                  </Button>
                  <Button variant="secondary" onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
