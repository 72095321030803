import { useMutation, useQuery } from "@apollo/client";
import { ErrorPage, LoadingSpinner, Button, CopyText } from "../../components";
import { useAppContext, useNotificationContext } from "../../providers";
import { VerticalNavigation } from "./components/VerticalNavigation";
import { gql } from "../../__generatedGQL__/gql";
import { useEffect, useState } from "react";
import { TestingSandboxCustomization } from "./TestingSandboxCustomization";
import { useExternalResourcesHook } from "../../hooks/useExternalResourcesHook";
import { size } from "lodash";
import { GetReposWithTestingConfigsQuery } from "../../__generatedGQL__/graphql";
import { useNavigate, useParams } from "react-router-dom";
import { getTestingSandboxConfigName } from "./utils";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";

export const GET_REPOS_WITH_TESTING_CONFIGS = gql(`
  query GetReposWithTestingConfigs($resourceId: Int!) {
    repos(resourceId: $resourceId) {
      id
      name
      enabled
      testingConfig {
        testCheckEnabled
      }
      testingSandboxConfigs {
        id
        type
        parentConfigId
        appDir
        description
        testFramework
      }
    }
  }
  `);

const AdditionalSetupRequiredAlert = ({ className }: { className?: string }) => {
  return (
    <div className={clsx("rounded-md bg-yellow-50 p-4", className)}>
      <div className="flex">
        <div className="shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Additional setup required</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>
              Additional setup is required to enable Tusk Tester for this repo. Please contact{" "}
              <CopyText text="founders@usetusk.ai" type="email" /> to enable Tusk Tester for this
              repo.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RepoConfigSettings = () => {
  const { selectedClientId } = useAppContext();
  const navigate = useNavigate();
  const {
    repoId: repoIdParam,
    testingSandboxConfigId: testingSandboxConfigIdParam,
    tab: tabParam,
  } = useParams<{
    repoId: string;
    testingSandboxConfigId: string;
    tab: "instructions" | "execution";
  }>();
  const { loading: loadingResources, githubResource, gitlabResource } = useExternalResourcesHook();
  const codeHostingResource = githubResource || gitlabResource;
  const {
    loading: loadingRepos,
    data: reposData,
    refetch: refetchRepos,
  } = useQuery(GET_REPOS_WITH_TESTING_CONFIGS, {
    variables: { resourceId: codeHostingResource?.id || 0 },
    skip: !codeHostingResource?.id,
  });
  const enabledRepos = reposData?.repos.filter((repo) => repo.enabled);
  const testingEnabledRepos = enabledRepos?.filter((repo) => repo.testingConfig?.testCheckEnabled);

  const [selectedRepo, setSelectedRepo] = useState<
    GetReposWithTestingConfigsQuery["repos"][number] | undefined
  >(undefined);
  const [selectedTestingSandboxConfigId, setSelectedTestingSandboxConfigId] = useState<
    string | undefined
  >(undefined);
  const [currentTab, setCurrentTab] = useState<"instructions" | "execution">("instructions");

  // Effect to handle URL updates when repo changes
  useEffect(() => {
    if (!enabledRepos) return;

    let repoFromParams = enabledRepos?.find((repo) => repo.id === Number(repoIdParam));
    if (repoFromParams) {
      setSelectedRepo(repoFromParams);

      const testingSandboxConfigFromParams = repoFromParams.testingSandboxConfigs?.find(
        (config) => config.id === testingSandboxConfigIdParam,
      );
      const firstTestingSandboxConfig = repoFromParams.testingSandboxConfigs?.[0];
      if (testingSandboxConfigFromParams) {
        setSelectedTestingSandboxConfigId(testingSandboxConfigFromParams.id);
        setCurrentTab(tabParam || "instructions");
      } else if (firstTestingSandboxConfig) {
        setSelectedTestingSandboxConfigId(firstTestingSandboxConfig.id);
        setCurrentTab("instructions");
        navigate(
          `/app/settings/customization/repo/${repoFromParams.id}/testing-sandbox-config/${firstTestingSandboxConfig.id}/instructions`,
          { replace: true },
        );
      }
    }
  }, [repoIdParam, enabledRepos, testingSandboxConfigIdParam, tabParam]);

  // Effect to handle setting initial repo id, if no repo exists select first repo with a testing sandbox config
  useEffect(() => {
    if (!enabledRepos || repoIdParam) return; // Don't redirect if we already have a repo in the URL

    const firstRepo =
      enabledRepos.find((repo) => repo.testingSandboxConfigs?.length) || enabledRepos[0];
    if (firstRepo) {
      navigate(`/app/settings/customization/repo/${firstRepo.id}?client=${selectedClientId}`, {
        replace: true,
      });
    }
  }, [enabledRepos]);

  const handleRepoChange = (id: string) => {
    const newRepo = enabledRepos?.find((repo) => repo.id === Number(id)) || enabledRepos?.[0];
    if (!newRepo) return;
    navigate(`/app/settings/customization/repo/${newRepo.id}?client=${selectedClientId}`);
  };

  const handleTestingSandboxConfigChange = (id: string, tab: "instructions" | "execution") => {
    if (!selectedRepo) return;
    setSelectedTestingSandboxConfigId(id);
    setCurrentTab(tab);
    navigate(
      `/app/settings/customization/repo/${selectedRepo.id}/testing-sandbox-config/${id}/${tab}?client=${selectedClientId}`,
    );
  };

  if (
    loadingResources ||
    loadingRepos ||
    (size(testingEnabledRepos) > 0 && !selectedTestingSandboxConfigId)
  )
    return <LoadingSpinner className="mt-12" />;
  if (!selectedRepo) return <div>No repo selected</div>;
  if (size(enabledRepos) === 0) return <div>No enabled repos found</div>;

  return (
    <>
      <div className="flex items-center justify-between mb-8">
        <div>
          <h2 className="text-lg font-semibold leading-7 text-gray-900">Repo Customization</h2>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
            Customize the testing environments for your repositories. Contact{" "}
            <CopyText text="founders@usetusk.ai" type="email" /> to add a new testing environment.
          </p>
        </div>
        <div className="w-60">
          <label
            htmlFor="repo-select"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Repo
          </label>
          <select
            id="repo-select"
            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-purple-600 sm:text-sm sm:leading-6"
            value={selectedRepo?.id}
            onChange={(e) => handleRepoChange(e.target.value)}
          >
            {enabledRepos?.map((repo) => (
              <option key={repo.id} value={repo.id}>
                {repo.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {(!selectedRepo?.testingConfig?.testCheckEnabled ||
        size(selectedRepo?.testingSandboxConfigs) === 0) && (
        <AdditionalSetupRequiredAlert className="mb-8" />
      )}

      {size(selectedRepo?.testingSandboxConfigs) > 0 && selectedTestingSandboxConfigId && (
        <div className="flex border-t border-gray-900/10">
          <div className="w-64 pt-6 pr-4 border-r border-gray-900/10">
            <VerticalNavigation
              currentTestingSandboxConfigId={selectedTestingSandboxConfigId}
              currentTab={currentTab}
              testingSandboxConfigOptions={
                selectedRepo?.testingSandboxConfigs?.map((config) => ({
                  id: config.id,
                  name: getTestingSandboxConfigName(config),
                  type: config.type,
                  parentConfigId: config.parentConfigId || undefined,
                })) || []
              }
              onSelectTestingSandboxConfig={(id, tab) => handleTestingSandboxConfigChange(id, tab)}
            />
          </div>
          <div className="flex-1">
            {currentTab === "instructions" && (
              <TestingSandboxCustomization
                testingSandboxConfigId={selectedTestingSandboxConfigId}
              />
            )}
            {/* TODO-CUSTOMIZATION: add test execution tab */}
            {currentTab === "execution" && (
              <TestingSandboxCustomization
                testingSandboxConfigId={selectedTestingSandboxConfigId}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
