import { clsx } from "clsx";
import { ButtonHTMLAttributes, useState } from "react";
import { Transition } from "@headlessui/react";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode | string;
  variant?: "primary" | "secondary";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  tooltipText?: string;
  tooltipClassName?: string;
  link?: string;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
}

const buttonVariants = {
  primary:
    "bg-purple-600 font-semibold text-white shadow-xs hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600",
  secondary:
    "bg-white text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
};

const disabledVariants = {
  primary: "bg-purple-600 font-semibold text-white shadow-xs opacity-50",
  secondary: "bg-white text-sm font-semibold text-gray-900 shadow-xs opacity-50 ",
};

const sizes = {
  xs: "px-2 py-1 text-xs rounded-sm",
  sm: "px-2 py-1 text-sm rounded-sm",
  md: "px-2.5 py-1.5 text-sm rounded-md",
  lg: "px-3 py-2 text-sm rounded-md",
  xl: "px-3.5 py-2.5 text-sm rounded-md",
};

const loadingSizes = {
  xs: "h-4 w-4",
  sm: "h-6 w-6",
  md: "h-6 w-6",
  lg: "h-6 w-6",
  xl: "h-6 w-6",
};

const tooltipPositionClasses = {
  top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
  bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
  left: "right-full top-1/2 transform -translate-y-1/2 mr-2",
  right: "left-full top-1/2 transform -translate-y-1/2 ml-2",
};

export const Button = (props: IProps) => {
  const {
    children,
    variant = "primary",
    size = "md",
    onClick,
    className,
    disabled = false,
    loading = false,
    tooltipText,
    tooltipClassName,
    link,
    tooltipPosition = "top",
    ...otherButtonProps
  } = props;

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const handleClick = () => {
    if (link) {
      window.open(link, "_blank"); // Open the link in a new tab
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      <button
        type="button"
        onClick={!disabled && !loading ? handleClick : undefined}
        className={clsx(
          sizes[size],
          disabled ? disabledVariants[variant] : buttonVariants[variant],
          {
            "opacity-50": loading,
          },
          "flex items-center justify-center relative",
          className,
        )}
        disabled={disabled}
        {...otherButtonProps}
      >
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <svg
              className={clsx(
                loadingSizes[size],
                "animate-spin",
                variant === "primary" ? "text-white" : "text-gray-900",
              )}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
        <div className={clsx(loading && "invisible")}>{children}</div>
      </button>
      <Transition
        show={Boolean(tooltipText && isTooltipVisible)}
        enter="transition-opacity ease-linear duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={clsx(
            "w-48 absolute z-50 bg-gray-900 text-white text-sm rounded-sm py-2 px-4",
            tooltipPositionClasses[tooltipPosition],
            tooltipClassName,
          )}
        >
          {tooltipText}
        </div>
      </Transition>
    </div>
  );
};
