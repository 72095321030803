// @ts-nocheck

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const REDIRECT_URI = `${window.location.origin}/app`;

export const CustomAuth0Provider = ({ children }) => {
  const navigate = useNavigate();
  const auth0state = useAuth0();

  const onRedirectCallback = async (appState) => {
    if (appState?.returnTo && appState?.returnTo?.startsWith("/app")) {
      navigate(appState.returnTo);
    } else {
      navigate("/app");
    }
  };

  return (
    <Auth0Provider
      domain={process.env.AUTH_DOMAIN}
      clientId={process.env.AUTH_CLIENT_ID}
      // if we don't do this, user is always redirected to home page on safari
      // https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: REDIRECT_URI,
        audience: process.env.AUTH_AUDIENCE,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
