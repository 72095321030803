// @ts-nocheck

import { useMemo } from "react";

import { ConnectExternalResource } from "../../components";
import { useAppContext } from "../../providers";
import { GetResourcesQuery } from "../../__generatedGQL__/graphql";

export const NotionAuth = ({ resource }: { resource: GetResourcesQuery["resources"][0] }) => {
  const { user, selectedClientId, showOldTuskUI } = useAppContext();
  const notionEnabled = !!resource;

  const notionAuthUrl = useMemo(() => {
    const state = {
      clientId: selectedClientId,
      userId: user.id,
    };
    const redirectUri = encodeURIComponent(process.env.NOTION_REDIRECT_URI);
    return `https://api.notion.com/v1/oauth/authorize?client_id=${
      process.env.NOTION_CLIENT_ID
    }&response_type=code&owner=user&redirect_uri=${redirectUri}&state=${encodeURIComponent(
      JSON.stringify(state),
    )}`;
  }, [selectedClientId, user.id]);

  return (
    <ConnectExternalResource
      authed={notionEnabled}
      onConnect={() => window.location.assign(notionAuthUrl)}
      resourceName="Notion"
      resourceLogo="https://asset.brandfetch.io/idPYUoikV7/iduXtIiAG2.png"
      resourceDescription={
        showOldTuskUI
          ? "Assign issues from a Notion table"
          : "Analyze Notion tickets linked to your PRs"
      }
    />
  );
};
