import { Fragment, useState } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";

export const IncorporateTestsConfirmationModal = ({
  open,
  setOpen,
  onConfirm,
  title,
  descriptions,
  testPathMappingIssues,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => Promise<void>;
  title?: string;
  descriptions?: string[];
  testPathMappingIssues?: Array<{
    currentPath: string;
    suggestedPath: string;
  }> | null;
}) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start sm:pr-4">
                  <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-purple-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 flex"
                    >
                      {title || "Incorporate Tests"}
                    </DialogTitle>
                    <div className="mt-4">
                      {descriptions && descriptions.length > 0 ? (
                        <div className="mt-2">
                          <ul className="list-disc pl-5 space-y-1 text-sm text-gray-500">
                            {descriptions.map((desc, index) => (
                              <li key={index}>{desc}</li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <p className="text-sm text-gray-500 text-left">
                          You are about to incorporate tests for an older commit in the branch.
                          These tests may be outdated if you have made changes to the code under
                          test.
                        </p>
                      )}

                      {testPathMappingIssues && testPathMappingIssues.length > 0 && (
                        <div className="mt-4">
                          <p className="text-sm font-medium text-gray-600 mb-2">
                            File path recommendations:
                          </p>
                          <div className="bg-gray-50 p-3 rounded-sm max-h-60 overflow-auto text-sm">
                            {testPathMappingIssues.map((issue, index) => (
                              <div
                                key={index}
                                className="mb-2 last:mb-0 pb-2 border-b border-gray-200 last:border-b-0"
                              >
                                <p>
                                  <span className="font-medium">Current:</span>{" "}
                                  <code className="bg-gray-100 px-1 py-0.5 rounded-sm font-mono text-xs">
                                    {issue.currentPath}
                                  </code>
                                </p>
                                <p>
                                  <span className="font-medium">Suggested:</span>{" "}
                                  <code className="bg-gray-100 px-1 py-0.5 rounded-sm font-mono text-xs">
                                    {issue.suggestedPath}
                                  </code>
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:pr-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="bg-purple-600 text-white shadow-xs hover:bg-purple-500 sm:ml-3 sm:w-auto"
                    onClick={async () => {
                      setOpen(false);
                      try {
                        await onConfirm();
                      } catch (error) {
                        console.error("Error incorporating tests:", error);
                      }
                    }}
                  >
                    Continue
                  </Button>
                  <Button variant="secondary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
