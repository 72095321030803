// @ts-nocheck

import { createContext, useContext, useState, useEffect } from "react";
import { map } from "lodash";

import { Notification, TNotificationType } from "../components";

let nextNotificationId = 1; // To generate unique IDs for notifications

interface IShowNotificationConfig {
  id: number;
  title: string;
  type?: TNotificationType;
  message?: string;
  duration?: number;
}

export interface INotificationContext {
  showNotification: (config: Omit<IShowNotificationConfig, "id">) => void;
}

export const NotificationContext = createContext<INotificationContext>(null);
export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState<IShowNotificationConfig[]>([]);
  const timeoutIds = new Set<NodeJS.Timeout>(); // Store timeout IDs

  const dismiss = (id: number) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notif) => notif.id !== id));
  };

  const showNotification = (config: Omit<IShowNotificationConfig, "id">) => {
    const id = nextNotificationId++;
    const fullConfig = { id, ...config };

    setNotifications([...notifications, fullConfig]);

    const timer = setTimeout(() => {
      dismiss(id);
    }, config.duration || 10000);

    timeoutIds.add(timer); // Add timeout ID to the set
  };

  useEffect(() => {
    return () => {
      // Clear all timeouts when component unmounts
      timeoutIds.forEach((timeoutId) => {
        clearTimeout(timeoutId);
      });
    };
  }, []);

  // TODO: Currently notifications just stack on top of each other, but we'll eventually want to have a notification drawer
  return (
    <NotificationContext.Provider
      value={{
        showNotification,
      }}
    >
      {children}
      {map(notifications, (notification, i) => (
        <Notification
          key={notification.id}
          type={notification.type}
          title={notification.title}
          message={notification.message}
          visible={true}
          setVisible={() => dismiss(notification.id)}
        />
      ))}
    </NotificationContext.Provider>
  );
};
