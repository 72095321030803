import { InputHTMLAttributes } from "react";
import clsx from "clsx";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  helpText?: string;
  error?: string;
  wrapperClassName?: string;
}

export function Input({
  label,
  helpText,
  error,
  id,
  wrapperClassName,
  className,
  "aria-label": ariaLabel,
  ...props
}: InputProps) {
  const inputId = id || label?.toLowerCase().replace(/\s+/g, "-");

  return (
    <div className={wrapperClassName}>
      {label && (
        <label htmlFor={inputId} className="block text-sm/6 font-medium text-gray-900">
          {label}
        </label>
      )}
      <div className={clsx(label && "mt-2", "grid grid-cols-1")}>
        <input
          {...props}
          id={inputId}
          aria-label={label ? undefined : ariaLabel || props.name}
          aria-describedby={helpText || error ? `${inputId}-description` : undefined}
          aria-invalid={error ? "true" : undefined}
          className={clsx(
            "col-start-1 row-start-1 block w-full rounded-md bg-white px-3 py-1.5 text-base",
            "outline-1 -outline-offset-1",
            error
              ? "text-red-900 outline-red-300 placeholder:text-gray-400 focus:outline-red-400"
              : "text-gray-900 outline-gray-300 placeholder:text-gray-400 focus:outline-purple-600",
            "focus:ring-0 focus:outline-2 focus:-outline-offset-2",
            "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:outline-gray-200",
            error && "pr-10 sm:pr-9",
            "sm:text-sm/6",
            className,
          )}
        />
        {error && (
          <ExclamationCircleIcon
            aria-hidden="true"
            className="w-5 h-5 pointer-events-none col-start-1 row-start-1 mr-3 self-center justify-self-end text-red-400 sm:w-4 sm:h-4"
          />
        )}
      </div>
      {(helpText || error) && (
        <p
          id={`${inputId}-description`}
          className={clsx("mt-2 text-sm", error ? "text-red-400" : "text-gray-500")}
        >
          {error || helpText}
        </p>
      )}
    </div>
  );
}
