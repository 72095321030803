// @ts-nocheck

import { PhotoIcon, XMarkIcon, PlusIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { isEmpty, isFunction, map, size } from "lodash";
import { clsx } from "clsx";

import { LoadingSpinner } from "../components";
import { gql } from "../__generatedGQL__";
import { useNotificationContext } from "../providers";

const GET_SIGNED_URL = gql(`
  mutation GetSignedWriteUrl($fileName: String!, $bucketName: String!, $contentType: String) {
    getSignedWriteUrl(fileName: $fileName, bucketName: $bucketName, contentType: $contentType)
  }
`);

export interface IFileUploadFile {
  id: string;
  url?: string;
  uploading: boolean;
}

interface IProps {
  files: IFileUploadFile[];
  setFiles: React.Dispatch<React.SetStateAction<IFileUploadFile[]>>;
  onFileClick?: (file: IFileUploadFile) => void;
  filePrefix?: string;
  acceptedFileTypes?: string[];
}

export const FileUpload = ({
  files,
  setFiles,
  filePrefix = "file",
  acceptedFileTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg"],
  onFileClick,
}: IProps) => {
  const { showNotification } = useNotificationContext();
  const [getSignedWriteUrl, { loading: getSignedWriteUrlLoading }] = useMutation(GET_SIGNED_URL);

  const [dragging, setDragging] = useState(false);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!dragging) {
      setDragging(true);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleUploadFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const handleUploadFiles = async (files: HTMLInputElement["files"]) => {
    if (files) {
      const uploadedFiles = files;
      const filesToUpload = Array.from(uploadedFiles).filter((file) => {
        if (!acceptedFileTypes.includes(file.type)) {
          console.error(`File type ${file.type} not accepted`);
          showNotification({
            title: `File type ${file.type} not allowed`,
            type: "error",
          });
          return false;
        }
        return true;
      });

      filesToUpload.forEach(async (file) => {
        const fileId = `${filePrefix}-${Date.now()}`;
        const newFile: IFileUploadFile = { id: fileId, uploading: true };
        setFiles((prev) => [...prev, newFile]);

        let signedUrl;
        try {
          const { data } = await getSignedWriteUrl({
            variables: {
              fileName: fileId,
              bucketName: process.env.CLOUD_STORAGE_BUCKET,
              contentType: file.type,
            },
          });
          signedUrl = data.getSignedWriteUrl;
        } catch (err) {
          console.error("Error getting signed URL");
          console.error(err);
          showNotification({
            title: "Error uploading file, please contact support",
            type: "error",
          });
          setFiles((prev) => prev.filter((file) => file.id !== fileId));
          return;
        }

        try {
          // Note: for some reason axios doesn't work, have to use fetch
          await fetch(signedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });
        } catch (err) {
          console.error("Error uploading file", err?.response || err?.message);
          console.error(err);
          showNotification({
            title: "Error uploading file, please contact support",
            type: "error",
          });
          setFiles((prev) => prev.filter((file) => file.id !== fileId));
          return;
        }

        const fileUrl = signedUrl.split("?")[0];
        setFiles((prev) =>
          prev.map((f) => (f.id === fileId ? { ...f, url: fileUrl, uploading: false } : f)),
        );
      });
    }
  };

  const handleDelete = (fileId: string) => {
    setFiles((prev) => prev.filter((file) => file.id !== fileId));
  };

  return (
    <div
      className={clsx(
        "flex justify-center rounded-lg border px-4 py-4",
        dragging ? "border-purple-500" : "border-dashed border-gray-900/25",
      )}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {size(files) === 0 ? (
        <div className="text-center">
          <PhotoIcon className="mx-auto h-8 w-8 text-gray-300" aria-hidden="true" />
          <div className="mt-2 flex text-sm leading-6 text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-hidden focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
            >
              <span>Upload a file</span>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={(e) => handleUploadFiles(e.target.files)}
                multiple
                accept={acceptedFileTypes.join(", ")}
              />
            </label>
            <p className="pl-1">or drag and drop</p>
          </div>
          <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
        </div>
      ) : (
        <>
          <div className="flex space-x-4">
            {map(files, (file, index) => (
              <div key={index} className="relative h-20 w-20 rounded-lg">
                {file.uploading ? (
                  <div className="flex items-center justify-center h-full w-full">
                    <LoadingSpinner size="sm" />
                  </div>
                ) : (
                  <img
                    onClick={isFunction(onFileClick) ? () => onFileClick(file) : undefined}
                    src={file.url}
                    alt={`uploaded-file-${index}`}
                    className={clsx(
                      "h-full w-full object-cover rounded-lg",
                      isFunction(onFileClick) && "hover:cursor-pointer",
                    )}
                  />
                )}
                {!file.uploading && (
                  <XMarkIcon
                    className="absolute top-0 right-0 h-5 w-5 text-gray-600 cursor-pointer"
                    onClick={() => handleDelete(file.id)}
                  />
                )}
              </div>
            ))}
            <div className="flex items-center justify-center">
              <label htmlFor="file-upload" className="cursor-pointer">
                <PlusIcon className="mx-auto h-8 w-8 text-gray-900" />
              </label>
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={(e) => handleUploadFiles(e.target.files)}
                multiple
                accept={acceptedFileTypes.join(", ")}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
