import { Button } from "../../../components/Button";
import { useState } from "react";
import { GoGitPullRequest, GoGitCommit, GoCopy, GoFileSymlinkFile } from "react-icons/go";
import { TestScenarioFilter } from "../utils";
import { RunType } from "../../../__generatedGQL__/graphql";
import { TrashIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import pluralize from "pluralize";
export interface SymbolSelection {
  filePath: string;
  symbol: string;
  selected: boolean;
  allScenariosExcluded: boolean;
}

interface IProps {
  externalPullRequestUrl?: string;
  prTitle?: string;
  externalCommitUrl?: string;
  commitSha?: string;
  copiedFromData?: {
    testingCommitCheckRunId: string;
    commitSha: string;
    tuskUIUrl: string;
  };
  title?: string;
  showActions: boolean;
  incorporateAllDisabled?: boolean;
  incorporateAllTooltip?: string;
  onIncorporateAllClick?: () => Promise<void>;
  incorporateAllLoading?: boolean;
  allowShowingDetailedView: boolean;
  showDetailedView: boolean;
  onDetailedViewChange: (detailed: boolean) => void;
  selectedFilters: Set<TestScenarioFilter>;
  toggleFilter: (filter: TestScenarioFilter) => void;
  runType: RunType;
  onDeleteClick?: () => Promise<void>;
  latestRunUrlForCommit?: string;
  showRetryButton?: boolean;
  retryDisabled?: boolean;
  onRetryClick?: () => void;
  allSymbolsSelected?: boolean;
  selectedScenariosCount?: number;
  totalScenariosCount?: number;
  // Note: Remove this when we eventually support incorporating tests when previous runs have incorporated tests
  hasTestsIncorporated?: boolean;
}

export const PageHeader = ({
  externalPullRequestUrl,
  externalCommitUrl,
  commitSha,
  prTitle,
  title,
  showActions,
  copiedFromData,
  onIncorporateAllClick,
  incorporateAllDisabled,
  incorporateAllTooltip,
  incorporateAllLoading,
  allowShowingDetailedView,
  showDetailedView,
  onDetailedViewChange,
  selectedFilters,
  toggleFilter,
  runType,
  onDeleteClick,
  latestRunUrlForCommit,
  showRetryButton,
  retryDisabled,
  onRetryClick,
  allSymbolsSelected,
  selectedScenariosCount,
  totalScenariosCount,
  hasTestsIncorporated,
}: IProps) => {
  const [isIncorporatingAll, setIsIncorporatingAll] = useState(false);

  const incorporateButtonText =
    totalScenariosCount && !allSymbolsSelected && !hasTestsIncorporated
      ? `Incorporate ${selectedScenariosCount} of ${totalScenariosCount} ${pluralize(
          "tests",
          totalScenariosCount,
        )}`
      : "Incorporate tests";
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="text-2xl font-bold text-gray-900">{title || "Commit Check"}</h2>
        <div className="mt-3 flex flex-wrap gap-2 items-center text-sm text-gray-500">
          {externalPullRequestUrl && (
            <a
              href={externalPullRequestUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-600 hover:text-purple-700"
            >
              <GoGitPullRequest className="mr-2 h-4 w-4" />
              {prTitle || "Pull Request"}
            </a>
          )}
          {externalCommitUrl && commitSha && (
            <a
              href={externalCommitUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-gray-600 hover:text-purple-700"
            >
              <GoGitCommit className="mr-2 h-4 w-4" />
              {commitSha.slice(0, 6)}
            </a>
          )}
          {copiedFromData && (
            <a
              href={copiedFromData.tuskUIUrl}
              target="_blank"
              className="flex items-center text-gray-600 hover:text-purple-700"
            >
              <GoCopy className="mr-2 h-4 w-4" />
              Copied from {copiedFromData.commitSha.slice(0, 6)}
            </a>
          )}
          <div className="flex items-center">
            <button
              onClick={() => toggleFilter("excluded-in-check-output")}
              className={`flex items-center px-3 py-1 rounded-full text-xs font-medium focus:outline-hidden ${
                selectedFilters.has("excluded-in-check-output")
                  ? "bg-gray-200 text-gray-800"
                  : "bg-gray-50 text-gray-500 hover:bg-gray-100"
              }`}
            >
              <span className="inline-block w-2 h-2 bg-red-400 rounded-full mr-2" />
              Excluded tests
            </button>
          </div>
          {allowShowingDetailedView && (
            <div className="flex items-center">
              <div className="flex items-center space-x-2 mr-4">
                <span
                  className={`text-xs ${!showDetailedView ? "text-gray-700" : "text-gray-400"}`}
                >
                  Simple
                </span>
                <button
                  onClick={() => onDetailedViewChange(!showDetailedView)}
                  className={`relative inline-flex h-5 w-9 items-center rounded-full transition-colors focus:outline-hidden focus:ring-2 focus:ring-gray-500 focus:ring-offset-1 ${
                    showDetailedView ? "bg-gray-600" : "bg-gray-200"
                  }`}
                >
                  <span
                    className={`inline-block h-3 w-3 transform rounded-full bg-white transition-transform ${
                      showDetailedView ? "translate-x-5" : "translate-x-1"
                    }`}
                  />
                </button>
                <span className={`text-xs ${showDetailedView ? "text-gray-700" : "text-gray-400"}`}>
                  Detailed
                </span>
              </div>
              {runType === RunType.DryRun && onDeleteClick && (
                <Button
                  variant="secondary"
                  onClick={async () => {
                    await onDeleteClick();
                  }}
                >
                  <TrashIcon className="h-4 w-4" />
                </Button>
              )}
            </div>
          )}
        </div>
        {latestRunUrlForCommit && (
          <div className="mt-2 text-sm">
            <a
              href={latestRunUrlForCommit}
              target="_blank"
              className="items-center text-gray-600 hover:text-purple-700 inline-flex"
            >
              <GoFileSymlinkFile className="mr-2 h-4 w-4" />
              View latest run for this commit
            </a>
          </div>
        )}
      </div>
      <div className="mt-4 flex lg:ml-4 lg:mt-0 space-x-3 items-center">
        {showRetryButton && (
          <Button
            variant="secondary"
            disabled={retryDisabled}
            onClick={onRetryClick}
            tooltipText="Retry test generation"
            tooltipPosition="bottom"
            size="md"
          >
            <ArrowPathIcon className="h-4 w-4 my-1" />
          </Button>
        )}

        {showActions && (
          <>
            <Button
              variant="primary"
              tooltipText={incorporateAllTooltip}
              tooltipPosition="bottom"
              disabled={incorporateAllDisabled || selectedScenariosCount === 0}
              loading={incorporateAllLoading}
              onClick={async () => {
                if (!onIncorporateAllClick) return;
                setIsIncorporatingAll(true);
                await onIncorporateAllClick();
                setIsIncorporatingAll(false);
              }}
            >
              {incorporateButtonText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
