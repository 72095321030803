import { Switch as HeadlessSwitch } from "@headlessui/react";
import { clsx } from "clsx";

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export const Switch = ({ checked, onChange, disabled = false }: SwitchProps) => {
  return (
    <div className={"flex items-center gap-2"}>
      <HeadlessSwitch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={clsx(
          "group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-100 ease-in-out focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 focus:outline-hidden",
          disabled
            ? checked
              ? "bg-purple-300"
              : "bg-gray-200"
            : checked
              ? "bg-purple-600"
              : "bg-gray-200",
          disabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
      >
        <span className="sr-only">Toggle switch</span>
        <span
          className={clsx(
            "relative inline-block h-5 w-5 transform rounded-full bg-white ring-0 shadow-xs transition duration-100 ease-in-out",
            checked ? "translate-x-5" : "",
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              "absolute inset-0 flex size-full items-center justify-center transition-opacity duration-100 ease-in",
              checked ? "opacity-0 duration-50 ease-out" : "",
            )}
          >
            <svg fill="none" viewBox="0 0 12 12" className="size-3 text-gray-400">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            aria-hidden="true"
            className={clsx(
              "absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-50 ease-out",
              checked ? "opacity-100 duration-100 ease-in" : "",
            )}
          >
            <svg fill="currentColor" viewBox="0 0 12 12" className="size-3 text-purple-600">
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </HeadlessSwitch>
    </div>
  );
};
