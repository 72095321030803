// @ts-nocheck

import { MutationFunction } from "@apollo/client";
import TurndownService from "turndown";

import {
  GetSignedWriteUrlMutation,
  GetSignedWriteUrlMutationVariables,
} from "../__generatedGQL__/graphql";
import { INotificationContext } from "../providers/NotificationProvider";

export const handleRichTextHtml = async ({
  htmlContent,
  getSignedWriteUrl,
  showNotification,
  filePrefix = "file",
}: {
  htmlContent: string;
  getSignedWriteUrl: MutationFunction<
    GetSignedWriteUrlMutation,
    GetSignedWriteUrlMutationVariables
  >;
  showNotification: INotificationContext["showNotification"];
  filePrefix?: string;
}): Promise<{ markdownDescription: string; imageUrls: string[] }> => {
  const acceptedFileTypes = ["image/png", "image/jpeg", "image/gif", "image/jpg"];
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  const images = doc.querySelectorAll("img");
  const imageUrls = [];

  const handleImageUploads = async (images: NodeListOf<HTMLImageElement>) => {
    const imagesToUpload = Array.from(images).filter((img) => {
      const fileType = img.getAttribute("src").split(";")[0].split(":")[1];
      if (!acceptedFileTypes.includes(fileType)) {
        console.error(`File type ${img.getAttribute("src")} not accepted`);
        showNotification({
          title: `File type ${img.getAttribute("src")} not allowed`,
          type: "error",
        });
        return false;
      }
      return true;
    });
    for (const img of imagesToUpload) {
      const fileId = `${filePrefix}-${Date.now()}`;
      let signedUrl;
      try {
        const fileType = img.getAttribute("src")?.split(";")[0].split(":")[1];
        const { data } = await getSignedWriteUrl({
          variables: {
            fileName: fileId,
            bucketName: process.env.CLOUD_STORAGE_BUCKET,
            contentType: fileType,
          },
        });
        signedUrl = data.getSignedWriteUrl;
      } catch (err) {
        console.error("Error getting signed URL", err);
        showNotification({
          title: "Error uploading file, please contact support",
          type: "error",
        });
        continue;
      }

      try {
        const response = await fetch(img.src);
        const blob = await response.blob();

        await fetch(signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": blob.type,
          },
          body: blob,
        });

        const fileUrl = signedUrl.split("?")[0];
        imageUrls.push(fileUrl);
        console.log(`Image uploaded successfully. URL: ${fileUrl}`);
      } catch (err) {
        console.error("Error uploading image", err);
        showNotification({
          title: "Error uploading image, please contact support",
          type: "error",
        });
        continue;
      }
    }
  };

  await handleImageUploads(images);

  let counter = 0;
  const turndownService = new TurndownService();
  turndownService.addRule("image", {
    filter: "img",
    replacement: (_, node) => {
      const src = node.getAttribute("src");
      const fileType = src?.split(";")[0].split("/")[1];
      const fileName = `image.${fileType}`;
      return `![${node.getAttribute("alt") || fileName}](${imageUrls[counter++]})`;
    },
  });
  const markdownDescription = turndownService.turndown(doc.body.innerHTML);
  return { markdownDescription, imageUrls };
};
