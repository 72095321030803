import { Link, useParams, useSearchParams } from "react-router-dom";
import path from "path";
import pluralize from "pluralize";

import { gql } from "../../__generatedGQL__/gql";
import {
  GeneratedTestsStatus,
  TestCheckRunStatus,
  TestScenarioType,
  RetryTestingCommitCheckRunMutation,
  RetryTestingCommitCheckRunMutationVariables,
  RunType,
  IndividualSymbolTestFile,
  TrackTuskUiViewMutation,
  TrackTuskUiViewMutationVariables,
  CheckPullRequestStatus,
  UpdateTestScenarioFeedbackMutation,
  UpdateTestScenarioFeedbackMutationVariables,
  TestScenarioFeedbackSentiment,
  TestScenarioPositiveFeedback,
  TestScenarioNegativeFeedback,
  LintedGeneratedTestFile,
  IncorporateSelectTestScenariosInput,
  IncorporateSelectTestsAttemptStatus,
  ValueClassification,
} from "../../__generatedGQL__/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { size, isEmpty, truncate } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoCopy } from "react-icons/go";
import { format } from "date-fns";
import { TooltipWrapper } from "../../components/Tooltip";
import {
  CustomMarkdown,
  ErrorPage,
  LoadingSpinner,
  RetryTestGenerationModal,
  TNotificationType,
  IncorporateTestsConfirmationModal,
} from "../../components";
import { CodeEditor, getLanguageFromFilePath } from "../../components/editors/code/CodeEditor";

import { ExpandableCard } from "./components/ExpandableCard";
import { PageHeader, SymbolSelection } from "./components/PageHeader";
import { useAppContext, useNotificationContext } from "../../providers";
import { getAppUrl } from "../../utils";
import { FiCheckSquare, FiSquare, FiThumbsUp, FiThumbsDown, FiFile } from "react-icons/fi";

import {
  shouldShowTestScenario,
  GraphQLTestIterationHistory,
  GraphQLTestScenario,
  TestScenarioFilter,
  ExcludedReasonMap,
  SampleContextMap,
  SymbolDroppedReasonMap,
} from "./utils";
import clsx from "clsx";
import { TestScenarioFeedbackModal } from "../../components/TestScenarioFeedbackModal";
import { createPortal } from "react-dom";

// Define the IShowNotificationConfig interface
interface IShowNotificationConfig {
  id: number;
  title: string;
  type?: TNotificationType;
  message?: string;
  duration?: number;
}

const GET_TESTING_COMMIT_CHECK_RUN = gql(`
  query GetTestingCommitCheckRun($id: ID!) {
    testingCommitCheckRun(id: $id) {
      id
      createdAt
      status
      checkCommit {
        id
        commitSha
        branchName
        baseBranchName
        metadata {
          message
        }
        checkPullRequest {
          externalCreatorId
          metadata {
            title
            body
          }
          status
        }
        diff
      }
      runType
      externalCommitUrl
      externalPullRequestUrl
      generatedTestsStatus
      isLatestCommitOnBranch
      modifiedSymbols {
        symbolName
        filePath
        dropped
        droppedReason
        symbolContext {
          definition
        }
      }
      incorporateSelectTestsAttempts {
        selectedTestScenarioIndexes
        status
      }
      generatedTests {
        path
        content
        fileDiff
        symbols
        actualPath
        individualSymbolTestFilesArray {
          symbolName
          path
          content
        }
      }
      generatedTestsForPassingTests {
        path
        content
        fileDiff
      }
      result {
        checkOutput {
          testCounts {
            newPassingTests
            newFailingTests
            newErrorTests
          }
          coverageGains {
            fileUnderTest
            originalCoverage
            tuskCoverage
            coverageGain
          }
          title
          summary
        }
        copiedFromPreviousRunId
      }
      testScenarios {
        id
        scenarioIndex
        includedInCheckOutput
        excludedReason
        createdAt
        testingSandboxConfigId
        fileUnderTest
        symbolUnderTest
        testFile {
          filePath
          isNewTestFile
        }
        isRepresentativeHappyPath
        testScenarioMetadata {
          testScenario
          scenarioType
          summaryOfTestIterations
          checkOutputDetails {
            heading
            summaryBulletPoints
            assumption
            potentialFixBulletPoints
          }
          valueClassification {
            classification
          }
        }
        testIterationHistory {
          timestamp
          testCode
          passed
          hasError
          testResult
          reasoning
          sampleId
          sampleContext
          sampleSelected
        }
        testCode
        isPassing
        hasError
        testOutput
        incorporationDetails {
          branchIncorporationCommitSha
          isIncorporatedInPullRequestMerge
        }
        userFeedback {
          sentiment
          positiveFeedback
          negativeFeedback
          comment
        }
      }
    }
  }
`);

const GET_TESTING_COMMIT_CHECK_RUNS_FOR_CHECK_COMMIT = gql(`
  query GetTestingCommitCheckRunsForCheckCommit($checkCommitId: ID!) {
    testingCommitCheckRunsForCheckCommit(checkCommitId: $checkCommitId) {
      id
      status
      generatedTestsStatus
      runType
      createdAt
      tuskTestingCommitCheckUrl
    }
  }
`);

const INCORPORATE_TESTS_MUTATION = gql(`
  mutation IncorporateTests(
    $testingCommitCheckRunId: ID!, 
    $incorporateSelectTestScenariosInput: IncorporateSelectTestScenariosInput,
    $selectedSymbolsToIncorporate: [SymbolToIncorporateInput!]
  ) {
    incorporateTests(
      testingCommitCheckRunId: $testingCommitCheckRunId, 
      incorporateSelectTestScenariosInput: $incorporateSelectTestScenariosInput,
      selectedSymbolsToIncorporate: $selectedSymbolsToIncorporate
    )
  }
`);

const DELETE_CHECK_RUN_MUTATION = gql(`
  mutation DeleteTestingCommitCheckRun($testingCommitCheckRunId: ID!) {
    deleteTestingCommitCheckRun(testingCommitCheckRunId: $testingCommitCheckRunId)
  }
`);

const RETRY_TESTING_COMMIT_CHECK_RUN = gql(`
  mutation RetryTestingCommitCheckRun($testingCommitCheckRunId: ID!) {
    retryTestingCommitCheckRun(testingCommitCheckRunId: $testingCommitCheckRunId)
  }
`);

const TRACK_TUSK_UI_VIEW = gql(`
  mutation TrackTuskUiView($testingCommitCheckRunId: ID!) {
    trackTuskUiView(testingCommitCheckRunId: $testingCommitCheckRunId)
  }
`);

const UPDATE_TEST_SCENARIO_FEEDBACK = gql(`
  mutation UpdateTestScenarioFeedback($input: TestScenarioFeedbackInput!) {
    updateTestScenarioFeedback(input: $input)
  }
`);

export const TestingCommitCheckPage = () => {
  const { testingCommitCheckRunId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedClientId, isAdmin, allowSelectingTests } = useAppContext();
  const { showNotification } = useNotificationContext();

  const [retryDisabled, setRetryDisabled] = useState(false);
  const [showRetryTestGenerationModal, setShowRetryTestGenerationModal] = useState(false);
  const [showIncorporateTestsConfirmationModal, setShowIncorporateTestsConfirmationModal] =
    useState(false);

  const [isIncorporating, setIsIncorporating] = useState(false);

  const [symbolSelections, setSymbolSelections] = useState<SymbolSelection[]>([]);
  const [scenarioSelections, setScenarioSelections] = useState<{
    [scenarioId: string]: boolean;
  }>({});
  const [testPathMappingIssues, setTestPathMappingIssues] = useState<
    Array<{
      currentPath: string;
      suggestedPath: string;
    }>
  >([]);

  const [trackTuskUiView] = useMutation<TrackTuskUiViewMutation, TrackTuskUiViewMutationVariables>(
    TRACK_TUSK_UI_VIEW,
  );

  const [retryTestingCommitCheckRun] = useMutation<
    RetryTestingCommitCheckRunMutation,
    RetryTestingCommitCheckRunMutationVariables
  >(RETRY_TESTING_COMMIT_CHECK_RUN);

  const [updateFeedback] = useMutation<
    UpdateTestScenarioFeedbackMutation,
    UpdateTestScenarioFeedbackMutationVariables
  >(UPDATE_TEST_SCENARIO_FEEDBACK);

  const [selectedFilters, setSelectedFilters] = useState<Set<TestScenarioFilter>>(
    new Set(["included-in-check-output" as const]),
  );
  const toggleFilter = (filter: TestScenarioFilter) => {
    const newFilters = new Set(selectedFilters);
    if (newFilters.has(filter)) {
      newFilters.delete(filter);
    } else {
      newFilters.add(filter);
    }
    setSelectedFilters(newFilters);
  };

  // For now, only allow showing detailed view if admin
  const allowShowingDetailedView = isAdmin;

  const [showDetailedView, setShowDetailedView] = useState(() => {
    return searchParams.get("detailed") === "true";
  });

  const handleDetailedViewToggle = (detailed: boolean) => {
    setShowDetailedView(detailed);
    searchParams.set("detailed", detailed.toString());
    setSearchParams(searchParams);
  };

  const handleRetryTestGeneration = async () => {
    try {
      const { data } = await retryTestingCommitCheckRun({
        variables: {
          testingCommitCheckRunId: testingCommitCheckRunId!,
        },
      });

      if (!data?.retryTestingCommitCheckRun) {
        throw new Error("Failed to get new test run ID");
      }

      return data.retryTestingCommitCheckRun; // URL for redirection
    } catch (error) {
      console.error("Failed to retry test generation:", error);
      throw error;
    }
  };

  const {
    data,
    loading,
    error,
    refetch: refetchTestingCommitCheckRun,
  } = useQuery(GET_TESTING_COMMIT_CHECK_RUN, {
    variables: { id: testingCommitCheckRunId || "" },
    skip: !testingCommitCheckRunId,
    pollInterval: 60000,
  });

  const {
    data: copiedRunData,
    loading: copiedRunLoading,
    error: copiedRunError,
  } = useQuery(GET_TESTING_COMMIT_CHECK_RUN, {
    variables: { id: data?.testingCommitCheckRun?.result?.copiedFromPreviousRunId || "" },
    skip: !data?.testingCommitCheckRun?.result?.copiedFromPreviousRunId,
  });

  const {
    data: runsData,
    loading: runsDataLoading,
    error: runsDataError,
    refetch: refetchRunsData,
  } = useQuery(GET_TESTING_COMMIT_CHECK_RUNS_FOR_CHECK_COMMIT, {
    variables: { checkCommitId: data?.testingCommitCheckRun?.checkCommit?.id || "" },
    skip: !data?.testingCommitCheckRun?.checkCommit?.id,
  });

  // Track Tusk UI views only on page load
  useEffect(() => {
    if (testingCommitCheckRunId) {
      trackTuskUiView({
        variables: { testingCommitCheckRunId },
      }).catch((error) => {
        console.error("Failed to track Tusk UI view", error);
      });
    }
  }, [testingCommitCheckRunId]);

  useEffect(() => {
    if (data?.testingCommitCheckRun?.checkCommit?.id) {
      refetchRunsData();
    }
  }, [data, refetchRunsData]);

  const [incorporateTests] = useMutation(INCORPORATE_TESTS_MUTATION);

  const navigate = useNavigate();

  const [deleteTestingCommitCheckRun] = useMutation<
    { deleteTestingCommitCheckRun: boolean },
    { testingCommitCheckRunId: string }
  >(DELETE_CHECK_RUN_MUTATION, {
    onCompleted: () => {
      showNotification({
        title: "Successfully deleted commit check",
        type: "success",
      });
      navigate("/app/");
    },
    onError: (error) => {
      console.error("Error deleting commit check", error);
      showNotification({
        title: "Failed to delete commit check",
        message: "Please contact support if this persists",
        type: "error",
      });
    },
  });

  // Get the URL of the latest run for the commit
  const latestRunUrlForCommit = useMemo(() => {
    if (!runsData?.testingCommitCheckRunsForCheckCommit || !data?.testingCommitCheckRun) {
      return undefined;
    }

    const currentRunId = data.testingCommitCheckRun.id;
    const currentRunCreatedAt = new Date(data.testingCommitCheckRun.createdAt).getTime();

    // Get later runs that are not dry runs
    const laterRuns = runsData.testingCommitCheckRunsForCheckCommit.filter((run) => {
      return (
        run.id !== currentRunId &&
        new Date(run.createdAt).getTime() > currentRunCreatedAt &&
        run.runType !== RunType.DryRun
      );
    });

    const sortedRuns = laterRuns.sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });

    return sortedRuns.length > 0 ? sortedRuns[0].tuskTestingCommitCheckUrl : undefined;
  }, [runsData?.testingCommitCheckRunsForCheckCommit, data?.testingCommitCheckRun]);

  const pullRequestStatus = useMemo(() => {
    return data?.testingCommitCheckRun?.checkCommit?.checkPullRequest?.status;
  }, [data?.testingCommitCheckRun?.checkCommit?.checkPullRequest?.status]);

  const droppedSymbols = data?.testingCommitCheckRun?.modifiedSymbols?.filter(
    (symbol) => symbol.dropped,
  );

  const { testScenariosByFile, allTestScenarios, excludedSymbols } = useMemo<{
    testScenariosByFile: {
      filePath: string;
      isNewFile: boolean;
      passingCount: number;
      failingCount: number;
      errorCount: number;
      scenariosBySymbol: {
        symbol: string;
        passingCount: number;
        failingCount: number;
        errorCount: number;
        testScenarios: GraphQLTestScenario[];
      }[];
    }[];
    allTestScenarios: GraphQLTestScenario[];
    excludedSymbols: { symbolName: string; filePath: string }[];
  }>(() => {
    if (!data?.testingCommitCheckRun)
      return { testScenariosByFile: [], allTestScenarios: [], excludedSymbols: [] };
    if (!data.testingCommitCheckRun.testScenarios)
      return { testScenariosByFile: [], allTestScenarios: [], excludedSymbols: [] };

    const testScenarios = data.testingCommitCheckRun.testScenarios || [];

    // Calculate excluded symbols first, without applying filters
    const symbolScenarioMap = new Map<
      string,
      { symbolName: string; filePath: string; scenarios: GraphQLTestScenario[] }
    >();

    // Group scenarios by symbol and file path
    for (const scenario of testScenarios) {
      const key = `${scenario.symbolUnderTest}:${scenario.fileUnderTest}`;
      if (!symbolScenarioMap.has(key)) {
        symbolScenarioMap.set(key, {
          symbolName: scenario.symbolUnderTest,
          filePath: scenario.fileUnderTest,
          scenarios: [],
        });
      }
      symbolScenarioMap.get(key)!.scenarios.push(scenario);
    }

    // Find symbols where all scenarios are excluded
    const excludedSymbols: { symbolName: string; filePath: string }[] = [];
    for (const [_, symbolData] of Array.from(symbolScenarioMap.entries())) {
      if (
        symbolData.scenarios.length > 0 &&
        symbolData.scenarios.every((scenario) => !scenario.includedInCheckOutput)
      ) {
        excludedSymbols.push({
          symbolName: symbolData.symbolName,
          filePath: symbolData.filePath,
        });
      }
    }

    const fileMap = new Map<
      string,
      {
        filePath: string;
        isNewFile: boolean;
        passingCount: number;
        failingCount: number;
        errorCount: number;
        scenariosBySymbol: Map<
          string,
          {
            symbol: string;
            passingCount: number;
            failingCount: number;
            errorCount: number;
            testScenarios: GraphQLTestScenario[];
          }
        >;
      }
    >();

    // Process each test scenario
    for (const scenario of testScenarios) {
      // Don't show scenarios that are not included in the check output unless detailed view is enabled
      if (
        !shouldShowTestScenario({
          testScenario: scenario,
          filters: selectedFilters,
          showDetailedView,
        })
      )
        continue;
      const filePath = scenario.testFile.filePath;
      const symbol = scenario.symbolUnderTest;

      // Initialize file entry if it doesn't exist
      if (!fileMap.has(filePath)) {
        fileMap.set(filePath, {
          filePath,
          isNewFile: scenario.testFile.isNewTestFile,
          passingCount: 0,
          failingCount: 0,
          errorCount: 0,
          scenariosBySymbol: new Map(),
        });
      }

      const fileEntry = fileMap.get(filePath)!;

      // Initialize symbol entry if it doesn't exist
      if (!fileEntry.scenariosBySymbol.has(symbol)) {
        fileEntry.scenariosBySymbol.set(symbol, {
          symbol,
          passingCount: 0,
          failingCount: 0,
          errorCount: 0,
          testScenarios: [],
        });
      }

      const symbolEntry = fileEntry.scenariosBySymbol.get(symbol)!;

      // Update counts only if it was included in the check output
      if (scenario.includedInCheckOutput) {
        if (scenario.hasError) {
          fileEntry.errorCount++;
          symbolEntry.errorCount++;
        } else if (scenario.isPassing) {
          fileEntry.passingCount++;
          symbolEntry.passingCount++;
        } else {
          fileEntry.failingCount++;
          symbolEntry.failingCount++;
        }
      }

      // Add scenario to the list
      symbolEntry.testScenarios.push(scenario);
    }

    const testScenariosByFile = [];

    for (const file of Array.from(fileMap.values())) {
      const scenariosBySymbol = Array.from(file.scenariosBySymbol.values());
      for (const symbol of scenariosBySymbol) {
        symbol.testScenarios.sort((a, b) => {
          // First prioritize includedInCheckOutput
          if (a.includedInCheckOutput !== b.includedInCheckOutput) {
            return a.includedInCheckOutput ? -1 : 1;
          }
          const aValue =
            a.testScenarioMetadata?.valueClassification?.classification || ValueClassification.Low;
          const bValue =
            b.testScenarioMetadata?.valueClassification?.classification || ValueClassification.Low;
          const aIsPassing = a.isPassing ?? true; // Default to true if undefined
          const bIsPassing = b.isPassing ?? true;

          // Convert value classification to numeric value for easier comparison
          const getValueScore = (value: ValueClassification) => {
            switch (value) {
              case ValueClassification.High:
                return 3;
              case ValueClassification.Medium:
                return 2;
              case ValueClassification.Low:
                return 1;
              default:
                return 0;
            }
          };

          const aScore = getValueScore(aValue) * 2 + (aIsPassing ? 0 : 1);
          const bScore = getValueScore(bValue) * 2 + (bIsPassing ? 0 : 1);

          // Higher scores should come first
          if (aScore !== bScore) {
            return bScore - aScore;
          }

          // If scores are equal, maintain stable order using creation date
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
        });
      }

      const sortedScenariosBySymbol = scenariosBySymbol.sort((a, b) => {
        // First compare by total count
        const totalA = a.passingCount + a.failingCount;
        const totalB = b.passingCount + b.failingCount;

        if (totalA !== totalB) {
          return totalB - totalA; // Higher counts first
        }

        // If totals are equal, sort alphabetically by symbol name for stability
        return a.symbol.localeCompare(b.symbol);
      });

      testScenariosByFile.push({
        ...file,
        scenariosBySymbol: sortedScenariosBySymbol,
      });
    }

    const sortedTestScenariosByFile = testScenariosByFile.sort((a, b) => {
      // First sort by file name alphabetically
      const fileNameA = path.basename(a.filePath);
      const fileNameB = path.basename(b.filePath);
      const fileNameComparison = fileNameA.localeCompare(fileNameB);

      // If file names are different, return that comparison
      if (fileNameComparison !== 0) {
        return fileNameComparison;
      }

      // If file names are the same, sort by test counts (descending)
      return b.passingCount + b.failingCount - (a.passingCount + a.failingCount);
    });

    return {
      testScenariosByFile: sortedTestScenariosByFile,
      allTestScenarios: testScenarios,
      excludedSymbols,
    };
  }, [
    data?.testingCommitCheckRun?.testScenarios,
    copiedRunData?.testingCommitCheckRun?.testScenarios,
    showDetailedView,
    selectedFilters,
  ]);

  // No chance to be plural for now, but may be in the future.
  const otherRunsWithIncorporatedTests = useMemo(() => {
    const currentRunId = data?.testingCommitCheckRun?.id;

    return (
      runsData?.testingCommitCheckRunsForCheckCommit?.filter(
        (run) =>
          run.id !== currentRunId &&
          run.generatedTestsStatus &&
          [GeneratedTestsStatus.Incorporated, GeneratedTestsStatus.PartiallyIncorporated].includes(
            run.generatedTestsStatus,
          ),
      ) || []
    );
  }, [data?.testingCommitCheckRun, runsData?.testingCommitCheckRunsForCheckCommit]);

  // Scenario indexes of the scenarios we are currently trying to incorporate
  const activeIncorporationScenarioIds = useMemo(() => {
    const attempts = data?.testingCommitCheckRun?.incorporateSelectTestsAttempts || [];
    if (attempts.length === 0) return [];
    const lastIndex = attempts.length - 1;
    const lastAttempt = attempts[lastIndex];
    if (lastAttempt.status !== IncorporateSelectTestsAttemptStatus.Incorporating) return [];
    return lastAttempt.selectedTestScenarioIndexes;
  }, [data?.testingCommitCheckRun?.incorporateSelectTestsAttempts]);

  // Effect to initialize symbol selections when test data is loaded
  useEffect(() => {
    if (!testScenariosByFile) return;

    const initialSelections: SymbolSelection[] = [];
    const initialScenarioSelections: { [scenarioId: string]: boolean } = {};

    const runIsIncorporated = Boolean(
      data?.testingCommitCheckRun?.generatedTestsStatus &&
        [GeneratedTestsStatus.Incorporated, GeneratedTestsStatus.PartiallyIncorporated].includes(
          data?.testingCommitCheckRun?.generatedTestsStatus,
        ),
    );

    // Technically we can also figure this out if we have > 0 activeIncorporationScenarioId
    const runIsIncorporatingSelectedTests = Boolean(
      data?.testingCommitCheckRun?.generatedTestsStatus &&
        data?.testingCommitCheckRun?.generatedTestsStatus ===
          GeneratedTestsStatus.IncorporatingSelectedTests,
    );

    if (runIsIncorporated || runIsIncorporatingSelectedTests) {
      // Checkbox will be checked for incorporated symbols but disabled
      testScenariosByFile.forEach((fileInfo) => {
        fileInfo.scenariosBySymbol.forEach((symbolInfo) => {
          // Check if all scenarios for this symbol have been incorporated
          // or if all scenarios for this symbol are being incorporated
          const isIncorporatedOrBeingIncorporated = symbolInfo.testScenarios.every(
            (scenario) =>
              (scenario.incorporationDetails !== null &&
                scenario.incorporationDetails !== undefined) ||
              activeIncorporationScenarioIds.includes(scenario.scenarioIndex),
          );

          initialSelections.push({
            filePath: fileInfo.filePath,
            symbol: symbolInfo.symbol,
            selected: isIncorporatedOrBeingIncorporated,
            allScenariosExcluded: false,
          });

          symbolInfo.testScenarios.forEach((scenario) => {
            if (scenario.includedInCheckOutput) {
              const isScenarioIncorporatedOrBeingIncorporated =
                (scenario.incorporationDetails !== null &&
                  scenario.incorporationDetails !== undefined) ||
                activeIncorporationScenarioIds.includes(scenario.scenarioIndex);
              initialScenarioSelections[scenario.id] = isScenarioIncorporatedOrBeingIncorporated;
            }
          });
        });
      });
    } else {
      testScenariosByFile.forEach((fileInfo) => {
        fileInfo.scenariosBySymbol.forEach((symbolInfo) => {
          const allScenariosExcluded =
            symbolInfo.testScenarios.length > 0 &&
            symbolInfo.testScenarios.every((scenario) => !scenario.includedInCheckOutput);

          initialSelections.push({
            filePath: fileInfo.filePath,
            symbol: symbolInfo.symbol,
            selected: !allScenariosExcluded,
            allScenariosExcluded,
          });

          // Initialize scenario selections
          symbolInfo.testScenarios.forEach((scenario) => {
            if (scenario.includedInCheckOutput) {
              initialScenarioSelections[scenario.id] = true;
            }
          });
        });
      });
    }

    setSymbolSelections(initialSelections);
    setScenarioSelections(initialScenarioSelections);
  }, [testScenariosByFile]);

  const totalSymbols = symbolSelections.filter((s) => !s.allScenariosExcluded).length;
  const totalScenarios = allTestScenarios.filter((s) => s.includedInCheckOutput).length;

  const selectedSymbols = symbolSelections.filter((s) => s.selected).length;
  const selectedScenarios = Object.values(scenarioSelections).filter((s) => s).length;

  const allSymbolsSelected = totalSymbols > 0 && selectedSymbols === totalSymbols;
  const allScenariosSelected = totalScenarios > 0 && selectedScenarios === totalScenarios;

  if (loading || copiedRunLoading) return <LoadingSpinner />;

  if (!data?.testingCommitCheckRun) {
    return (
      <ErrorPage
        errorTitle="Commit check not found"
        errorDescription="Sorry, we couldn't find the commit check you're looking for. If this persists, please contact support."
      />
    );
  }

  if ((error && !data) || (copiedRunError && !copiedRunData)) {
    console.warn("Error when fetching commit check", error);
    return (
      <ErrorPage
        errorCode={null}
        errorTitle="Error loading commit check"
        errorDescription="If this persists, please contact support."
      />
    );
  }

  const shouldShowTestScenarios = size(testScenariosByFile) > 0;

  const { incorporateAllDisabled, incorporateAllTooltip } = (() => {
    let incorporateAllDisabled = false;
    let incorporateAllTooltip = "Create new commit with selected tests";

    if (otherRunsWithIncorporatedTests.length > 0) {
      incorporateAllDisabled = true;
      incorporateAllTooltip = "Tests for this commit already incorporated from another run";
    }

    if (size(data.testingCommitCheckRun.generatedTests) === 0) {
      incorporateAllDisabled = true;
      incorporateAllTooltip =
        "Unexpected error when consolidating tests, contact support if this persists";
    }

    if (data.testingCommitCheckRun.status === TestCheckRunStatus.Skipped) {
      incorporateAllDisabled = true;
      incorporateAllTooltip = "No tests generated";
    }

    // This is a positive disabled case, so keep it at the end
    if (
      data.testingCommitCheckRun.generatedTestsStatus &&
      [GeneratedTestsStatus.Incorporated, GeneratedTestsStatus.PartiallyIncorporated].includes(
        data.testingCommitCheckRun.generatedTestsStatus,
      )
    ) {
      incorporateAllDisabled = true;
      incorporateAllTooltip = "Tests already incorporated into branch";
    }

    if (
      data.testingCommitCheckRun.generatedTestsStatus &&
      data.testingCommitCheckRun.generatedTestsStatus ===
        GeneratedTestsStatus.IncorporatingSelectedTests
    ) {
      incorporateAllDisabled = true;
      incorporateAllTooltip = "Test file generation in progress";
    }

    if (pullRequestStatus === CheckPullRequestStatus.Closed) {
      incorporateAllDisabled = true;
      incorporateAllTooltip = "PR is closed";
    } else if (pullRequestStatus === CheckPullRequestStatus.Merged) {
      incorporateAllDisabled = true;
      incorporateAllTooltip = "PR is merged";
    }

    return {
      incorporateAllDisabled,
      incorporateAllTooltip,
    };
  })();

  const toggleSymbolSelection = (filePath: string, symbol: string) => {
    const currentSelection = symbolSelections.find(
      (s) => s.filePath === filePath && s.symbol === symbol,
    );
    const isSelected = !currentSelection?.selected;

    setSymbolSelections((prev) =>
      prev.map((item) =>
        item.filePath === filePath && item.symbol === symbol
          ? { ...item, selected: isSelected }
          : item,
      ),
    );

    // Find all scenarios for this symbol and update their selection state
    const scenariosForSymbol =
      testScenariosByFile
        .find((file) => file.filePath === filePath)
        ?.scenariosBySymbol.find((s) => s.symbol === symbol)?.testScenarios || [];

    setScenarioSelections((prev) => {
      const updated = { ...prev };
      scenariosForSymbol.forEach((scenario) => {
        // Do not want to toggle scenarios that are not included in the check output
        if (scenario.includedInCheckOutput) {
          updated[scenario.id] = isSelected;
        }
      });
      return updated;
    });
  };

  const toggleScenarioSelection = (scenarioId: string, filePath: string, symbol: string) => {
    // Toggle the individual scenario
    setScenarioSelections((prev) => {
      const newSelections = {
        ...prev,
        [scenarioId]: !prev[scenarioId],
      };

      // Find all scenarios for this symbol
      const scenariosForSymbol =
        testScenariosByFile
          .find((file) => file.filePath === filePath)
          ?.scenariosBySymbol.find((s) => s.symbol === symbol)?.testScenarios || [];

      // Check if all scenarios are selected using the updated selections
      const allSelected = scenariosForSymbol
        .filter((scenario) => scenario.includedInCheckOutput)
        .every((scenario) => newSelections[scenario.id]);

      // Update symbol selection in the next tick
      setTimeout(() => {
        setSymbolSelections((prev) =>
          prev.map((item) =>
            item.filePath === filePath && item.symbol === symbol
              ? { ...item, selected: allSelected }
              : item,
          ),
        );
      }, 0);

      return newSelections;
    });
  };

  const toggleAllSymbols = (selected: boolean) => {
    setSymbolSelections((prev) => prev.map((item) => ({ ...item, selected })));

    const newScenarioSelections = { ...scenarioSelections };
    allTestScenarios
      .filter((scenario) => scenario.includedInCheckOutput)
      .forEach((scenario) => {
        newScenarioSelections[scenario.id] = selected;
      });
    setScenarioSelections(newScenarioSelections);
  };

  // Modified incorporate tests function to handle symbol selection
  const handleIncorporateTests = async () => {
    try {
      setIsIncorporating(true);

      let incorporateSelectTestScenariosInput: IncorporateSelectTestScenariosInput | undefined =
        undefined;

      if (allowSelectingTests) {
        if (!allScenariosSelected) {
          const selectedTestScenarioIndexes = allTestScenarios
            .filter((scenario) => scenario.includedInCheckOutput && scenarioSelections[scenario.id])
            .map((scenario) => scenario.scenarioIndex);

          const unselectedTestScenarioIndexes = allTestScenarios
            .filter(
              (scenario) => scenario.includedInCheckOutput && !scenarioSelections[scenario.id],
            )
            .map((scenario) => scenario.scenarioIndex);

          incorporateSelectTestScenariosInput = {
            selectedTestScenarioIndexes,
            unselectedTestScenarioIndexes,
          };
        }
        await incorporateTests({
          variables: {
            testingCommitCheckRunId: data.testingCommitCheckRun!.id,
            incorporateSelectTestScenariosInput,
          },
        });
      } else {
        // Even if AllowSelectingTests feature flag is not enabled, still allow selecting symbols

        // Build symbolsToIncorporate array from selected symbols
        const symbolsToIncorporate = symbolSelections
          .filter((s) => s.selected)
          .map((s) => ({
            symbolName: s.symbol,
            testFile: s.filePath,
          }));

        await incorporateTests({
          variables: {
            testingCommitCheckRunId: data.testingCommitCheckRun!.id,
            selectedSymbolsToIncorporate: allSymbolsSelected ? undefined : symbolsToIncorporate,
          },
        });
      }

      let title = "";
      let message = "";
      if (incorporateSelectTestScenariosInput) {
        title = `Adding tests to ${
          data.testingCommitCheckRun?.checkCommit?.branchName || "branch"
        }`;
        message =
          "This may take a few minutes, Tusk will add a comment to the PR/MR when finished.";
      } else {
        title = `Incorporated ${allSymbolsSelected ? "all" : "selected"} tests into ${
          data.testingCommitCheckRun?.checkCommit?.branchName || "branch"
        }`;
      }
      showNotification({
        type: "success",
        title,
        message,
      });

      await refetchTestingCommitCheckRun();
    } catch (e) {
      console.error("Error when incorporating tests", e);
      showNotification({
        title: "Unexpected error when incorporating tests",
        message: "Please contact support if this persists",
        type: "error",
      });
    } finally {
      setIsIncorporating(false);
    }
  };

  const handleIncorporateButtonClick = async () => {
    // Keep track of test path mapping issues
    // Can happen for two reasons
    //   Scenario 1: actualPath is set (meaning we failed to consolidate symbol test file)
    //      a. In this case, currentPath is generatedTest.path and suggestedPath is generatedTest.actualPath
    //   Scenario 2: all of the symbols in generatedTest.symbols are NOT being incorporated
    //      a. IMPORTANT: this is only valid if AllowSelectingTests feature flag is not enabled
    //         i. This is because when AllowSelectingTests is enabled, we try to incorporate the tests into the actual test file path
    //            even if all of the symbols in generatedTest.symbols are not being incorporated
    //      b. In this case, currentPath is generatedTest.individualSymbolTestFiles[symbolName].path and suggestedPath is generatedTest.actualPath or generatedTest.path

    const generatedTests = data.testingCommitCheckRun?.generatedTests || [];
    const testPathMappingIssues: {
      currentPath: string;
      suggestedPath: string;
    }[] = [];

    const selectedSymbols = symbolSelections.filter((symbol) => {
      if (symbol.selected) {
        return true;
      }

      // Currently s.selected is false as long as not all test scenarios are selected (this is so that the toggling of test scenarios is clean)
      // However, in this situation, we want to consider a symbol selected if at least one test scenario is selected
      const scenariosForSymbol = allTestScenarios.filter(
        (scenario) =>
          scenario.symbolUnderTest === symbol.symbol &&
          scenario.testFile.filePath === symbol.filePath,
      );

      // Check if at least one scenario is selected
      return scenariosForSymbol.some(
        (scenario) => scenario.includedInCheckOutput && scenarioSelections[scenario.id],
      );
    });

    for (const generatedTest of generatedTests) {
      const generatedTestSymbols = generatedTest.symbols;
      if (!generatedTestSymbols) {
        // Should never happen
        console.error(`generatedTestSymbols is undefined for generatedTest ${generatedTest.path}`);
        continue;
      }

      // Check if any symbols are selected for this test
      const isSelected = selectedSymbols.some(
        (s) => s.filePath === generatedTest.path && generatedTestSymbols.includes(s.symbol),
      );

      if (!isSelected) {
        continue;
      }

      // Scenario 1
      if (generatedTest.actualPath) {
        testPathMappingIssues.push({
          currentPath: generatedTest.path,
          suggestedPath: generatedTest.actualPath,
        });
        continue;
      }

      // Get all of the symbolNames that are selected for this path
      const selectedSymbolsForPath = selectedSymbols.filter(
        (s) => s.filePath === generatedTest.path,
      );

      // Scenario 2 (only valid if AllowSelectingTests feature flag is not enabled)
      // With AllowSelectingTests enabled, we try to incorporate the tests into the actual test file path
      // even if all the symbols are not selected
      if (!allowSelectingTests) {
        if (selectedSymbolsForPath.length !== generatedTestSymbols.length) {
          if (!generatedTest.individualSymbolTestFilesArray) {
            console.error(
              `generatedTest.individualSymbolTestFilesArray is undefined for generatedTest ${generatedTest.path}`,
            );
            continue;
          }

          for (const selectedSymbol of selectedSymbolsForPath) {
            // Check if keys and values exist
            if (!generatedTest.individualSymbolTestFilesArray) {
              console.error(
                `Either keys or values is undefined for generatedTest ${generatedTest.path}`,
                {
                  individualSymbolTestFilesArray: generatedTest.individualSymbolTestFilesArray,
                },
              );
              continue;
            }

            // Find the index in the keys array
            const symbolIndex = generatedTest.individualSymbolTestFilesArray.findIndex(
              (symbol: IndividualSymbolTestFile) => symbol.symbolName === selectedSymbol.symbol,
            );

            // Get the corresponding path from the values array at the same index
            const symbolPath =
              symbolIndex >= 0
                ? generatedTest.individualSymbolTestFilesArray[symbolIndex]?.path
                : undefined;

            if (symbolPath) {
              // Should always be true
              testPathMappingIssues.push({
                currentPath: symbolPath,
                suggestedPath: generatedTest.path,
              });
            } else {
              console.error(
                `Symbol ${selectedSymbol.symbol} not found in generatedTest.individualSymbolTestFiles`,
                {
                  selectedSymbol,
                  individualSymbolTestFilesArrayLength:
                    generatedTest.individualSymbolTestFilesArray?.length,
                },
              );
            }
          }
        }
      }
    }

    setTestPathMappingIssues(testPathMappingIssues.length > 0 ? testPathMappingIssues : []);
    if (data.testingCommitCheckRun?.isLatestCommitOnBranch && testPathMappingIssues.length === 0) {
      await handleIncorporateTests();
    } else {
      setShowIncorporateTestsConfirmationModal(true);
    }
  };

  const handleTestScenarioFeedback = async (params: {
    testScenarioId: string;
    sentiment?: TestScenarioFeedbackSentiment;
    positiveFeedback?: TestScenarioPositiveFeedback[];
    negativeFeedback?: TestScenarioNegativeFeedback[];
    comment?: string;
    submitButtonClicked: boolean;
  }) => {
    try {
      await updateFeedback({
        variables: {
          input: {
            testingCommitCheckRunId: testingCommitCheckRunId || "",
            testScenarioId: params.testScenarioId,
            userId: selectedClientId || "",
            sentiment: params.sentiment,
            positiveFeedback: params.positiveFeedback,
            negativeFeedback: params.negativeFeedback,
            comment: params.comment,
          },
        },
        // This updates the Apollo cache immediately so the UI reflects the change
        update: (cache, { data }) => {
          if (data?.updateTestScenarioFeedback) {
            // Refetch to get updated data
            refetchTestingCommitCheckRun();
          }
        },
      });

      if (params.submitButtonClicked) {
        showNotification({
          title: "Feedback submitted",
          type: "success",
        });
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);

      if (params.submitButtonClicked) {
        showNotification({
          title: "Failed to submit feedback",
          message: "Please try again",
          type: "error",
        });
      }
    }
  };

  // We shouldn't allow selection of symbols or test scenarios if any of the tests have been incorporated or are being incorporated
  // Or isIncorporating (either awaiting cloud task or committing tests)
  const disableSymbolAndTestScenarioSelection = Boolean(
    (data?.testingCommitCheckRun?.generatedTestsStatus &&
      (data?.testingCommitCheckRun?.generatedTestsStatus ===
        GeneratedTestsStatus.IncorporatingSelectedTests ||
        data?.testingCommitCheckRun?.generatedTestsStatus === GeneratedTestsStatus.Incorporated ||
        data?.testingCommitCheckRun?.generatedTestsStatus ===
          GeneratedTestsStatus.PartiallyIncorporated)) ||
      pullRequestStatus === CheckPullRequestStatus.Closed ||
      pullRequestStatus === CheckPullRequestStatus.Merged ||
      isIncorporating,
  );
  return (
    <>
      <PageHeader
        externalPullRequestUrl={data.testingCommitCheckRun.externalPullRequestUrl || undefined}
        externalCommitUrl={data.testingCommitCheckRun.externalCommitUrl || undefined}
        commitSha={data.testingCommitCheckRun.checkCommit?.commitSha || undefined}
        prTitle={
          data.testingCommitCheckRun.checkCommit?.checkPullRequest?.metadata?.title || undefined
        }
        title={
          data.testingCommitCheckRun.status === TestCheckRunStatus.Running
            ? "Commit check running..."
            : data.testingCommitCheckRun.result?.checkOutput?.title || undefined
        }
        showActions={shouldShowTestScenarios}
        incorporateAllDisabled={incorporateAllDisabled || isIncorporating}
        incorporateAllLoading={isIncorporating}
        incorporateAllTooltip={incorporateAllTooltip}
        allowShowingDetailedView={allowShowingDetailedView}
        showDetailedView={showDetailedView}
        onDetailedViewChange={handleDetailedViewToggle}
        copiedFromData={
          copiedRunData?.testingCommitCheckRun
            ? {
                testingCommitCheckRunId: copiedRunData.testingCommitCheckRun.id,
                commitSha: copiedRunData.testingCommitCheckRun.checkCommit?.commitSha || "",
                tuskUIUrl: `${getAppUrl()}/app/testing-commit-check/${
                  copiedRunData.testingCommitCheckRun.id
                }?client=${selectedClientId}`,
              }
            : undefined
        }
        selectedFilters={selectedFilters}
        toggleFilter={toggleFilter}
        runType={data.testingCommitCheckRun.runType}
        onDeleteClick={async () => {
          await deleteTestingCommitCheckRun({
            variables: { testingCommitCheckRunId: data.testingCommitCheckRun!.id },
          });
        }}
        latestRunUrlForCommit={latestRunUrlForCommit}
        showRetryButton={
          ![TestCheckRunStatus.Running, TestCheckRunStatus.Pending].includes(
            data.testingCommitCheckRun.status,
          )
        }
        retryDisabled={
          retryDisabled ||
          ![RunType.CommitCheck, RunType.Retry].includes(data.testingCommitCheckRun.runType)
        }
        onRetryClick={() => setShowRetryTestGenerationModal(true)}
        onIncorporateAllClick={handleIncorporateButtonClick}
        allSymbolsSelected={allSymbolsSelected}
        selectedScenariosCount={selectedScenarios}
        totalScenariosCount={totalScenarios}
        hasTestsIncorporated={
          (data.testingCommitCheckRun.generatedTestsStatus &&
            [
              GeneratedTestsStatus.Incorporated,
              GeneratedTestsStatus.PartiallyIncorporated,
            ].includes(data.testingCommitCheckRun.generatedTestsStatus)) ||
          undefined
        }
      />
      <div className="border-t border-gray-200 mt-6 mb-8" />
      {data.testingCommitCheckRun.result?.checkOutput?.coverageGains && (
        <CoverageGainsTable
          coverageGains={data.testingCommitCheckRun.result.checkOutput.coverageGains}
        />
      )}

      {[TestCheckRunStatus.Running, TestCheckRunStatus.Pending].includes(
        data.testingCommitCheckRun.status,
      ) && (
        <div className="mt-2 mb-6 prose max-w-none prose-a:text-purple-600 hover:prose-a:text-purple-800">
          Tusk Tester is generating tests. Tests will appear here once finished.
        </div>
      )}
      {showDetailedView && (
        <div className="mb-8">
          <ExpandableCard
            key={`pull-request-details`}
            header={
              <>
                <div className="flex flex-wrap items-center gap-2">
                  <span className="flex text-sm items-center gap-2">Pull request info</span>
                </div>
              </>
            }
            defaultOpen={false}
          >
            <div className="space-y-2 text-sm">
              <div>
                <b>PR title:</b>&nbsp;
                {data.testingCommitCheckRun.checkCommit?.checkPullRequest?.metadata?.title}
              </div>
              <div>
                <b>PR creator:</b>&nbsp;
                {data.testingCommitCheckRun.checkCommit?.checkPullRequest?.externalCreatorId}
              </div>
              {data.testingCommitCheckRun.checkCommit?.checkPullRequest?.metadata?.body && (
                <div>
                  <b>Pull request body:</b>&nbsp;
                  <CustomMarkdown>
                    {data.testingCommitCheckRun.checkCommit?.checkPullRequest?.metadata?.body}
                  </CustomMarkdown>
                </div>
              )}
              <div>
                <b>Current commit message:</b>&nbsp;
                {data.testingCommitCheckRun.checkCommit?.metadata?.message}
              </div>
            </div>
            <div className="mt-4">
              <ExpandableCard
                header={
                  <h4 className="font-small text-sm">
                    Commit diff (compared to{" "}
                    {data.testingCommitCheckRun.checkCommit?.baseBranchName})
                  </h4>
                }
                defaultOpen={true}
                actions={[
                  {
                    icon: <GoCopy className="h-5 w-5" />,
                    tooltip: "Copy code",
                    onClick: () => {
                      navigator.clipboard.writeText(
                        data.testingCommitCheckRun?.checkCommit?.diff || "",
                      );
                      showNotification({
                        title: "Copied code",
                        type: "success",
                      });
                    },
                  },
                ]}
                includeDivider={false}
                bodyClassName="p-0"
              >
                <div className="h-[400px]">
                  <CodeEditor
                    initialCode={data.testingCommitCheckRun.checkCommit?.diff || ""}
                    language="plaintext"
                    readOnly={true}
                    className="h-[400px]"
                  />
                </div>
              </ExpandableCard>
            </div>
          </ExpandableCard>
        </div>
      )}
      {shouldShowTestScenarios ? (
        <>
          {totalSymbols > 0 && (
            <div className="my-4 flex items-center justify-between">
              <div className="flex items-center space-x-2">
                {data.testingCommitCheckRun.generatedTestsStatus !==
                  GeneratedTestsStatus.Incorporated &&
                  data.testingCommitCheckRun.generatedTestsStatus !==
                    GeneratedTestsStatus.PartiallyIncorporated &&
                  data.testingCommitCheckRun.generatedTestsStatus !==
                    GeneratedTestsStatus.IncorporatingSelectedTests &&
                  otherRunsWithIncorporatedTests.length === 0 &&
                  pullRequestStatus !== CheckPullRequestStatus.Closed &&
                  pullRequestStatus !== CheckPullRequestStatus.Merged && (
                    <button
                      onClick={() => toggleAllSymbols(!allSymbolsSelected)}
                      className={`flex items-center text-sm text-gray-600 hover:text-gray-900`}
                    >
                      {allSymbolsSelected ? (
                        <>
                          <FiCheckSquare className="mr-1" /> Deselect All
                        </>
                      ) : (
                        <>
                          <FiSquare className="mr-1" /> Select All
                        </>
                      )}
                    </button>
                  )}
                {otherRunsWithIncorporatedTests.length > 0 && (
                  <span className="text-sm text-gray-500">
                    Tests for this branch are already incorporated from{" "}
                    <Link
                      to={`/app/testing-commit-check/${otherRunsWithIncorporatedTests[0].id}?client=${selectedClientId}`}
                      className="text-purple-600 hover:text-purple-800"
                      target="_blank"
                    >
                      this run
                    </Link>
                    .
                  </span>
                )}
                {otherRunsWithIncorporatedTests.length === 0 && (
                  <span className="text-sm text-gray-500">
                    {data.testingCommitCheckRun.generatedTestsStatus ===
                      GeneratedTestsStatus.Incorporated ||
                    data.testingCommitCheckRun.generatedTestsStatus ===
                      GeneratedTestsStatus.PartiallyIncorporated
                      ? `Incorporated ${selectedScenarios} of ${totalScenarios} ${pluralize(
                          "tests",
                          totalScenarios,
                        )}`
                      : pullRequestStatus !== CheckPullRequestStatus.Closed &&
                          pullRequestStatus !== CheckPullRequestStatus.Merged
                        ? `${selectedScenarios} of ${totalScenarios} ${pluralize(
                            "tests",
                            totalScenarios,
                          )} selected`
                        : null}
                  </span>
                )}
              </div>
            </div>
          )}
          {testScenariosByFile.map((testScenariosByFile) => (
            <TestFileTestScenarios
              key={testScenariosByFile.filePath}
              filePath={testScenariosByFile.filePath}
              isNewFile={testScenariosByFile.isNewFile}
              testScenarios={testScenariosByFile.scenariosBySymbol}
              detailedView={showDetailedView}
              showExcludedTests={selectedFilters.has("excluded-in-check-output")}
              isAdmin={isAdmin}
              symbolSelections={symbolSelections}
              scenarioSelections={scenarioSelections}
              toggleSymbolSelection={toggleSymbolSelection}
              toggleScenarioSelection={toggleScenarioSelection}
              allowSelectingTests={allowSelectingTests}
              onFeedbackSubmit={handleTestScenarioFeedback}
              generatedTests={data.testingCommitCheckRun?.generatedTests?.map((test) => ({
                path: test.path,
                content: test.content,
                fileDiff: test.fileDiff,
                hasLintError: false,
              }))}
              disableSymbolAndTestScenarioSelection={disableSymbolAndTestScenarioSelection}
              modifiedSymbols={
                data.testingCommitCheckRun?.modifiedSymbols?.map((symbol) => ({
                  symbolName: symbol.symbolName,
                  filePath: symbol.filePath,
                  symbolContext: symbol.symbolContext
                    ? {
                        definition: symbol.symbolContext.definition,
                      }
                    : undefined,
                })) || undefined
              }
              showNotification={showNotification}
            />
          ))}

          {((droppedSymbols && droppedSymbols.length > 0) || excludedSymbols.length > 0) &&
            selectedFilters.has("excluded-in-check-output") && (
              <div className="mb-8">
                <div className="border-t border-gray-200 mt-6 mb-8" />
                <h3 className="text-md font-medium mb-2">Symbols not tested</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white border border-gray-200 rounded-lg text-sm">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="py-1.5 px-3 border-b text-left text-xs">Symbol name</th>
                        <th className="py-1.5 px-3 border-b text-left text-xs">File path</th>
                        <th className="py-1.5 px-3 border-b text-left text-xs">Reason</th>
                      </tr>
                    </thead>
                    <tbody className="text-sm">
                      {droppedSymbols &&
                        droppedSymbols.length > 0 &&
                        droppedSymbols.map((symbol, index) => (
                          <tr
                            key={`${symbol.symbolName}-${index}`}
                            className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                          >
                            <td className="py-1.5 px-3 border-b">
                              <code className="text-xs bg-gray-100 px-1.5 py-0.5 rounded-sm break-all">
                                {symbol.symbolName}
                              </code>
                            </td>
                            <td className="py-1.5 px-3 border-b">
                              <code className="text-xs bg-gray-100 px-1.5 py-0.5 rounded-sm break-all">
                                {symbol.filePath}
                              </code>
                            </td>
                            <td className="py-1.5 px-3 border-b">
                              <div className="flex flex-col gap-1.5">
                                {symbol.droppedReason &&
                                  symbol.droppedReason.map((reason, reasonIndex) => {
                                    const reasonData = SymbolDroppedReasonMap[reason];
                                    return reasonData ? (
                                      <span
                                        key={`${reason}-${reasonIndex}`}
                                        className="px-2 py-0.5 text-xs bg-gray-100 text-gray-700 rounded-full inline-block w-fit"
                                      >
                                        {reasonData.humanReadable}
                                      </span>
                                    ) : null;
                                  })}
                              </div>
                            </td>
                          </tr>
                        ))}

                      {excludedSymbols.map((symbol, index) => (
                        <tr
                          key={`excluded-${symbol.symbolName}-${index}`}
                          className={
                            ((droppedSymbols?.length || 0) + index) % 2 === 0
                              ? "bg-white"
                              : "bg-gray-50"
                          }
                        >
                          <td className="py-2.5 px-3 border-b">
                            <code className="text-xs bg-gray-100 px-1.5 py-0.5 rounded-sm break-all">
                              {symbol.symbolName}
                            </code>
                          </td>
                          <td className="py-2.5 px-3 border-b">
                            <code className="text-xs bg-gray-100 px-1.5 py-0.5 rounded-sm break-all">
                              {symbol.filePath}
                            </code>
                          </td>
                          <td className="py-2.5 px-3 border-b">
                            <div className="flex flex-col gap-2.5">
                              <span className="px-2 py-0.5 text-xs bg-gray-100 text-gray-700 rounded-full inline-block w-fit">
                                Failed to generate valid test code
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
        </>
      ) : (
        <div className="mt-2 prose max-w-none prose-a:text-purple-600 hover:prose-a:text-purple-800">
          <CustomMarkdown>
            {data.testingCommitCheckRun.result?.checkOutput?.summary || ""}
          </CustomMarkdown>
        </div>
      )}
      {showRetryTestGenerationModal && (
        <RetryTestGenerationModal
          open={showRetryTestGenerationModal}
          setOpen={setShowRetryTestGenerationModal}
          onRetry={handleRetryTestGeneration}
        />
      )}
      {showIncorporateTestsConfirmationModal && (
        <IncorporateTestsConfirmationModal
          open={showIncorporateTestsConfirmationModal}
          setOpen={setShowIncorporateTestsConfirmationModal}
          onConfirm={() => {
            setIsIncorporating(true);
            return handleIncorporateTests();
          }}
          title={
            !data.testingCommitCheckRun?.isLatestCommitOnBranch || testPathMappingIssues?.length
              ? "Incorporation details"
              : "Incorporate tests"
          }
          descriptions={(() => {
            const descriptions = [];

            if (!data.testingCommitCheckRun?.isLatestCommitOnBranch) {
              descriptions.push(
                "You are incorporating tests for an older commit in the branch. These tests may be outdated if you have made changes to the code under test.",
              );
            }

            if (testPathMappingIssues?.length) {
              descriptions.push(
                `The following file ${pluralize(
                  "path",
                  testPathMappingIssues.length,
                )} may not follow your naming conventions. Consider modifying ${pluralize(
                  "this",
                  testPathMappingIssues.length,
                )} in your IDE after incorporating tests.`,
              );
            }

            return descriptions.length > 0 ? descriptions : undefined;
          })()}
          testPathMappingIssues={testPathMappingIssues}
        />
      )}
    </>
  );
};

type SmallBadgeColor = "gray" | "green" | "red" | "purple" | "yellow" | "blue";

const SmallBadge = ({ text, color }: { text: string; color: SmallBadgeColor }) => {
  const colorClasses: Record<SmallBadgeColor, string> = {
    gray: "text-gray-700 bg-gray-100",
    green: "text-green-700 bg-green-100",
    red: "text-red-700 bg-red-100",
    purple: "text-purple-700 bg-purple-100",
    yellow: "text-yellow-700 bg-yellow-100",
    blue: "text-blue-700 bg-blue-100",
  };

  return (
    <span className={`px-2 py-0.5 text-xs font-medium ${colorClasses[color]} rounded-full`}>
      {text}
    </span>
  );
};

const getTestScenarioBadges = ({
  testScenario,
  showExcludedTests,
  isAdmin,
}: {
  testScenario: GraphQLTestScenario;
  showExcludedTests: boolean;
  isAdmin: boolean;
}) => {
  const badges: JSX.Element[] = [];

  if (showExcludedTests) {
    if (testScenario.includedInCheckOutput) {
      badges.push(
        <span
          key="included-in-check-output"
          className="inline-block w-2 h-2 bg-green-500 rounded-full"
        />,
      );
    } else {
      badges.push(
        <span
          key="excluded-in-check-output"
          className="inline-block w-2 h-2 bg-red-400 rounded-full"
        />,
      );
    }
    if (testScenario.testScenarioMetadata.scenarioType === TestScenarioType.HappyPath) {
      if (testScenario.isRepresentativeHappyPath) {
        badges.push(<SmallBadge key="seed-test" text="Seed test" color="gray" />);
      }
    }
  }

  if (
    testScenario.testScenarioMetadata.valueClassification?.classification ===
    ValueClassification.High
  ) {
    badges.push(<SmallBadge key="high-value" text="High value" color="green" />);
  } else if (
    testScenario.testScenarioMetadata.valueClassification?.classification ===
    ValueClassification.Medium
  ) {
    badges.push(<SmallBadge key="medium-value" text="Medium value" color="yellow" />);
  } else if (
    testScenario.testScenarioMetadata.valueClassification?.classification ===
    ValueClassification.Low
  ) {
    badges.push(<SmallBadge key="low-value" text="Low value" color="gray" />);
  }

  if (testScenario.excludedReason) {
    const reasonData = ExcludedReasonMap[testScenario.excludedReason];
    const badgeText =
      isAdmin && reasonData.adminReadable ? reasonData.adminReadable : reasonData.humanReadable;
    badges.push(
      <SmallBadge key={`excluded-${testScenario.excludedReason}`} text={badgeText} color="gray" />,
    );
  }

  return badges;
};

const TestIterationHistory = ({
  testIterationHistory,
  showNotification,
}: {
  testIterationHistory: GraphQLTestIterationHistory[];
  showNotification: (config: Omit<IShowNotificationConfig, "id">) => void;
}) => {
  // Group iterations by sample index
  const groupedIterations = useMemo(() => {
    const groups = new Map<string | null, GraphQLTestIterationHistory[]>();

    // Handle iterations without sample index
    const noSampleGroup: GraphQLTestIterationHistory[] = [];

    testIterationHistory.forEach((iteration) => {
      if (!iteration.sampleId) {
        noSampleGroup.push(iteration);
      } else {
        const group = groups.get(iteration.sampleId) || [];
        group.push(iteration);
        groups.set(iteration.sampleId, group);
      }
    });

    // Convert map to array of groups
    const result = Array.from(groups.entries()).map(([sampleIndex, iterations]) => ({
      sampleIndex,
      iterations,
      selected: iterations.some((i) => i.sampleSelected),
      // Take the first available sample context in the group
      sampleContext: iterations.find((i) => i.sampleContext)?.sampleContext,
    }));

    // Add ungrouped iterations if they exist
    if (noSampleGroup.length > 0) {
      result.unshift({
        sampleIndex: null,
        iterations: noSampleGroup,
        selected: false,
        sampleContext: null,
      });
    }

    return result;
  }, [testIterationHistory]);
  return (
    <div className="space-y-4">
      <div className="text-md text-gray-500">Test iteration history</div>
      {groupedIterations.map((group) => (
        <div
          key={group.sampleIndex ?? "ungrouped"}
          className={`space-y-4 p-4 rounded-lg ${group.selected ? "bg-green-50" : "bg-gray-50"}`}
        >
          {group.sampleIndex !== null && (
            <div className="flex items-center gap-2">
              <span className="font-medium">
                Sample context:{" "}
                {group.sampleContext ? SampleContextMap[group.sampleContext].humanReadable : "N/A"}
              </span>
              {group.selected && <SmallBadge text="Selected sample" color="green" />}
            </div>
          )}

          {group.iterations.map((iteration, index) => {
            const emoji = iteration.passed ? "✅" : iteration.hasError ? "❓" : "❌";
            const handleCopyCode = () => {
              navigator.clipboard.writeText(iteration.testCode || "");
              showNotification({
                title: "Copied code",
                type: "success",
              });
            };
            const handleCopyResult = () => {
              navigator.clipboard.writeText(iteration.testResult || "");
              showNotification({
                title: "Copied result",
                type: "success",
              });
            };

            return (
              <ExpandableCard
                key={iteration.timestamp}
                header={
                  <div className="font-medium text-sm">
                    Iteration {index + 1} -{" "}
                    {format(new Date(iteration.timestamp), "MM/dd/yy HH:mm:ss")}
                    &nbsp;&nbsp;{emoji}
                  </div>
                }
                defaultOpen={false}
              >
                <div className="space-y-4">
                  <div className="prose prose-sm max-w-none prose-li:-my-1 prose-ul:mt-2 prose-fp:my-1 bg-gray-50 p-4 rounded-md [&>*:first-child]:mt-0!">
                    {iteration.reasoning && (
                      <>
                        <div>
                          <b>Reasoning for iteration</b>
                        </div>
                        <CustomMarkdown className="prose prose-sm">
                          {iteration.reasoning}
                        </CustomMarkdown>
                      </>
                    )}
                  </div>
                  {iteration.testCode && (
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="font-medium text-sm">Test code</h4>
                        <button
                          onClick={handleCopyCode}
                          className="text-gray-500 hover:text-gray-700"
                          title="Copy code"
                        >
                          <GoCopy className="h-5 w-5" />
                        </button>
                      </div>
                      <div className="h-[600px]">
                        <CodeEditor
                          initialCode={iteration.testCode}
                          language="typescript"
                          readOnly={true}
                          className="h-[600px]"
                        />
                      </div>
                    </div>
                  )}
                  {iteration.testResult && (
                    <div>
                      <div className="flex items-center justify-between mb-2">
                        <h4 className="font-medium text-sm">Test result</h4>
                        <button
                          onClick={handleCopyResult}
                          className="text-gray-500 hover:text-gray-700"
                          title="Copy result"
                        >
                          <GoCopy className="h-5 w-5" />
                        </button>
                      </div>
                      <div className="h-[400px]">
                        <CodeEditor
                          initialCode={iteration.testResult}
                          language="plaintext"
                          readOnly={true}
                          className="h-[400px]"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </ExpandableCard>
            );
          })}
        </div>
      ))}
    </div>
  );
};

const TestScenarioCard = ({
  index,
  testFilePath,
  testScenario,
  detailedView,
  showExcludedTests,
  isAdmin,
  onFeedbackSubmit,
  symbolName,
  disableSymbolAndTestScenarioSelection,
  scenarioSelections,
  isSelected,
  toggleScenarioSelection,
  allowSelectingTests,
  showNotification,
}: {
  index: number;
  testFilePath: string;
  testScenario: GraphQLTestScenario;
  detailedView: boolean;
  showExcludedTests: boolean;
  isAdmin: boolean;
  onFeedbackSubmit: (params: {
    testScenarioId: string;
    sentiment?: TestScenarioFeedbackSentiment;
    positiveFeedback?: TestScenarioPositiveFeedback[];
    negativeFeedback?: TestScenarioNegativeFeedback[];
    comment?: string;
    submitButtonClicked: boolean;
  }) => void;
  disableSymbolAndTestScenarioSelection: boolean;
  scenarioSelections: { [scenarioId: string]: boolean };
  symbolName: string;
  isSelected?: boolean;
  toggleScenarioSelection?: (scenarioId: string, filePath: string, symbol: string) => void;
  allowSelectingTests: boolean;
  showNotification: (config: Omit<IShowNotificationConfig, "id">) => void;
}) => {
  let emoji = "";
  if (testScenario.includedInCheckOutput) {
    emoji = testScenario.isPassing ? "✅" : testScenario.hasError ? "❓" : "❌";
  }

  const badges = getTestScenarioBadges({ testScenario, showExcludedTests, isAdmin });

  const handleCopyCode = () => {
    navigator.clipboard.writeText(testScenario.testCode || "");
    showNotification({
      title: "Copied code",
      type: "success",
    });
  };

  const handleCopyOutput = () => {
    navigator.clipboard.writeText(testScenario.testOutput || "");
    showNotification({
      title: "Copied output",
      type: "success",
    });
  };

  const isScenarioIncorporated =
    testScenario.incorporationDetails?.branchIncorporationCommitSha ||
    testScenario.incorporationDetails?.isIncorporatedInPullRequestMerge;

  const isChecked = !!(scenarioSelections[testScenario.id] || isScenarioIncorporated);

  const isDisabled =
    disableSymbolAndTestScenarioSelection || !scenarioSelections.hasOwnProperty(testScenario.id);

  return (
    <ExpandableCard
      className={`space-y-4`}
      key={testScenario.id}
      header={
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-wrap items-center gap-2">
            <span className="flex items-center gap-2">{badges}</span>
            <CustomMarkdown className="font-medium text-sm text-gray-900 prose-code:text-xs prose-code:bg-gray-100 prose-code:px-1.5 prose-code:py-0.5 prose-code:rounded-sm prose-code:text-black">
              {`${
                testScenario.testScenarioMetadata?.checkOutputDetails?.heading ||
                truncate(testScenario.testScenarioMetadata?.testScenario, { length: 100 })
              }&nbsp;&nbsp;${emoji}`}
            </CustomMarkdown>
          </div>

          <TestScenarioFeedbackControls
            testScenario={testScenario}
            onFeedbackSubmit={onFeedbackSubmit}
            showNotification={showNotification}
          />
        </div>
      }
      defaultOpen={false}
      beforeChevron={
        allowSelectingTests && toggleScenarioSelection ? (
          <div className="flex items-center mr-1" onClick={(e) => e.stopPropagation()}>
            <input
              type="checkbox"
              id={`scenario-${testScenario.id}`}
              checked={isChecked}
              onChange={() => toggleScenarioSelection(testScenario.id, testFilePath, symbolName)}
              disabled={isDisabled}
              className={clsx(
                "h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500 appearance-none",
                isDisabled ? "opacity-60 cursor-not-allowed" : "",
              )}
            />
          </div>
        ) : null
      }
    >
      <div className="space-y-4">
        {testScenario.testScenarioMetadata && (
          <div className="prose prose-sm max-w-none prose-li:-my-1 prose-ul:mt-2 prose-fp:my-1 bg-gray-50 p-4 rounded-md [&>*:first-child]:mt-0!">
            {testScenario.testScenarioMetadata.checkOutputDetails?.assumption && (
              <>
                <b>Assumption:</b> {testScenario.testScenarioMetadata.checkOutputDetails.assumption}
              </>
            )}
            {size(testScenario.testScenarioMetadata.checkOutputDetails?.summaryBulletPoints) >
              0 && (
              <ul>
                {testScenario.testScenarioMetadata.checkOutputDetails?.summaryBulletPoints.map(
                  (point, i) => <li key={i}>{point}</li>,
                )}
              </ul>
            )}
            {isEmpty(testScenario.testScenarioMetadata.checkOutputDetails?.summaryBulletPoints) &&
              testScenario.testScenarioMetadata?.testScenario && (
                <>
                  <b>Full test scenario: </b>
                  <CustomMarkdown>{testScenario.testScenarioMetadata.testScenario}</CustomMarkdown>
                </>
              )}
            {testScenario.testScenarioMetadata.checkOutputDetails?.potentialFixBulletPoints && (
              <div className="mt-4">
                <b>Potential fix</b>
                <ul>
                  {testScenario.testScenarioMetadata.checkOutputDetails.potentialFixBulletPoints.map(
                    (point, i) => (
                      <li key={i}>{point}</li>
                    ),
                  )}
                </ul>
              </div>
            )}
            {testScenario.testScenarioMetadata.summaryOfTestIterations && (
              <div className="mt-4">
                <b>Summary of test iterations: </b>
                <CustomMarkdown>
                  {testScenario.testScenarioMetadata.summaryOfTestIterations}
                </CustomMarkdown>
              </div>
            )}
          </div>
        )}

        {testScenario.testCode && (
          <ExpandableCard
            header={<h4 className="font-small text-sm">Test code</h4>}
            defaultOpen={true}
            actions={[
              {
                icon: <GoCopy className="h-5 w-5" />,
                tooltip: "Copy code",
                onClick: handleCopyCode,
              },
            ]}
            includeDivider={false}
            bodyClassName="p-0"
          >
            <div className="h-[400px]">
              <CodeEditor
                initialCode={testScenario.testCode}
                language={getLanguageFromFilePath(testFilePath)}
                readOnly={true}
                className="h-[400px]"
              />
            </div>
          </ExpandableCard>
        )}

        {testScenario.testOutput && (
          <ExpandableCard
            header={<h4 className="font-small text-sm">Test output</h4>}
            defaultOpen={false}
            actions={[
              {
                icon: <GoCopy className="h-5 w-5" />,
                tooltip: "Copy output",
                onClick: handleCopyOutput,
              },
            ]}
            includeDivider={false}
            bodyClassName="p-0"
          >
            <div className="h-[400px]">
              <CodeEditor
                initialCode={testScenario.testOutput}
                language="plaintext"
                readOnly={true}
                className="h-[400px]"
              />
            </div>
          </ExpandableCard>
        )}

        {detailedView && testScenario.testIterationHistory && (
          <TestIterationHistory
            testIterationHistory={testScenario.testIterationHistory}
            showNotification={showNotification}
          />
        )}
      </div>
    </ExpandableCard>
  );
};

const TestFileTestScenarios = ({
  filePath,
  isNewFile,
  testScenarios,
  detailedView,
  showExcludedTests,
  isAdmin,
  symbolSelections,
  scenarioSelections,
  toggleSymbolSelection,
  toggleScenarioSelection,
  allowSelectingTests,
  onFeedbackSubmit,
  generatedTests,
  modifiedSymbols,
  disableSymbolAndTestScenarioSelection,
  showNotification,
}: {
  filePath: string;
  isNewFile: boolean;
  testScenarios: {
    symbol: string;
    passingCount: number;
    failingCount: number;
    errorCount: number;
    testScenarios: GraphQLTestScenario[];
  }[];
  detailedView: boolean;
  showExcludedTests: boolean;
  isAdmin: boolean;
  symbolSelections: SymbolSelection[];
  scenarioSelections: { [scenarioId: string]: boolean };
  toggleSymbolSelection: (filePath: string, symbol: string) => void;
  toggleScenarioSelection: (scenarioId: string, filePath: string, symbol: string) => void;
  allowSelectingTests: boolean;
  onFeedbackSubmit: (params: {
    testScenarioId: string;
    sentiment?: TestScenarioFeedbackSentiment;
    positiveFeedback?: TestScenarioPositiveFeedback[];
    negativeFeedback?: TestScenarioNegativeFeedback[];
    comment?: string;
    submitButtonClicked: boolean;
  }) => void;
  generatedTests?: LintedGeneratedTestFile[];
  modifiedSymbols?: {
    symbolName: string;
    filePath: string;
    symbolContext?: {
      definition?: string;
    };
  }[];
  disableSymbolAndTestScenarioSelection: boolean;
  showNotification: (config: Omit<IShowNotificationConfig, "id">) => void;
}) => {
  const SMALL_SCREEN_FILE_PATH_LENGTH = 40;
  const LARGE_SCREEN_FILE_PATH_LENGTH = 60;

  // Find the matching generated test file for this file path
  const generatedTest = generatedTests?.find((test) => test.path === filePath) || null;

  return (
    <div className="space-y-8 my-4">
      {testScenarios.map((testScenariosBySymbol) => {
        // Find if this symbol is selected (if selection functionality is enabled)
        const selection = symbolSelections?.find(
          (s) => s.filePath === filePath && s.symbol === testScenariosBySymbol.symbol,
        );
        const isSelected = selection?.selected ?? true;

        // Check if any of the test scenarios for this symbol have been incorporated
        const isIncorporated = testScenariosBySymbol.testScenarios.some(
          (scenario) =>
            scenario.incorporationDetails?.branchIncorporationCommitSha ||
            scenario.incorporationDetails?.isIncorporatedInPullRequestMerge,
        );

        const matchingSymbols = modifiedSymbols?.filter(
          (symbol) => symbol.symbolName === testScenariosBySymbol.symbol,
        );

        // If there are multiple matching symbols, concatenate their definitions
        // We cannot use filePath to find the definition since one file path is the source file and the other is the test file
        const modifiedSymbolDefinition = matchingSymbols
          ?.map((symbol) => symbol.symbolContext?.definition)
          .join("\n\n");

        const allScenariosForSymbolAreExcluded =
          testScenariosBySymbol.testScenarios.length > 0 &&
          testScenariosBySymbol.testScenarios.every((scenario) => !scenario.includedInCheckOutput);

        const scenariosForSymbol = testScenariosBySymbol.testScenarios || [];
        const someScenariosSelected = scenariosForSymbol.some(
          (scenario) => scenarioSelections[scenario.id],
        );

        return (
          <div key={testScenariosBySymbol.symbol} className={`space-y-4`}>
            <div className="flex flex-wrap items-start justify-between pb-2 gap-2">
              <div className="flex flex-wrap items-center gap-2 max-w-full">
                {/* Checkbox for symbol selection */}
                {symbolSelections && toggleSymbolSelection && (
                  <div className="flex items-center mr-1">
                    <input
                      type="checkbox"
                      id={`symbol-${filePath}-${testScenariosBySymbol.symbol}`}
                      checked={isSelected || isIncorporated}
                      onChange={() => toggleSymbolSelection(filePath, testScenariosBySymbol.symbol)}
                      disabled={
                        disableSymbolAndTestScenarioSelection || allScenariosForSymbolAreExcluded
                      }
                      className={clsx(
                        "h-4 w-4 rounded-sm border-gray-300 text-purple-600 focus:ring-purple-500 appearance-none",
                        disableSymbolAndTestScenarioSelection || allScenariosForSymbolAreExcluded
                          ? "opacity-60 cursor-not-allowed"
                          : "",
                      )}
                    />
                  </div>
                )}
                {detailedView && isNewFile && <SmallBadge text="New file" color="green" />}
                <div className="flex flex-wrap items-center gap-x-2">
                  <TooltipWrapper
                    tooltipText={filePath}
                    position="top-left"
                    tooltipClassName="w-auto"
                  >
                    <code className="text-sm bg-gray-100 px-2 py-1 rounded-sm break-all inline-flex items-center">
                      {/* For screens smaller than sm breakpoint (640px) */}
                      <span className="inline sm:hidden">
                        {filePath.length > SMALL_SCREEN_FILE_PATH_LENGTH
                          ? "..." + filePath.slice(-SMALL_SCREEN_FILE_PATH_LENGTH)
                          : filePath}
                      </span>
                      {/* For sm screens and larger */}
                      <span className="hidden sm:inline">
                        {filePath.length > LARGE_SCREEN_FILE_PATH_LENGTH
                          ? "..." + filePath.slice(-LARGE_SCREEN_FILE_PATH_LENGTH)
                          : filePath}
                      </span>
                    </code>
                  </TooltipWrapper>
                  <span className="text-gray-500 hidden sm:inline"> - </span>
                  <code className="text-sm bg-gray-100 px-2 py-1 rounded-sm break-all mt-2 sm:mt-0 inline-flex items-center">
                    {testScenariosBySymbol.symbol}
                  </code>
                </div>
              </div>
              <div className="text-sm text-gray-900 space-x-3 whitespace-nowrap">
                <span>{testScenariosBySymbol.testScenarios.length} generated</span>
                <span>{testScenariosBySymbol.passingCount || 0} ✅</span>
                <span>{testScenariosBySymbol.failingCount || 0} ❌</span>
                {testScenariosBySymbol.errorCount > 0 && (
                  <span>{testScenariosBySymbol.errorCount} ❓</span>
                )}
              </div>
            </div>

            {/* add modified symbol as drop down */}
            {modifiedSymbolDefinition && detailedView && (
              <div className="mb-4 space-y-4">
                <ExpandableCard
                  header={<h4 className="font-small text-sm">Symbol definition</h4>}
                  defaultOpen={false}
                  actions={[
                    {
                      icon: <GoCopy className="h-5 w-5" />,
                      tooltip: "Copy code",
                      onClick: () => {
                        navigator.clipboard.writeText(modifiedSymbolDefinition);
                        showNotification({
                          title: "Copied code",
                          type: "success",
                        });
                      },
                    },
                  ]}
                  includeDivider={false}
                  bodyClassName="p-0"
                >
                  <div className="h-[600px]">
                    <CodeEditor
                      initialCode={modifiedSymbolDefinition}
                      language={getLanguageFromFilePath(filePath)}
                      readOnly={true}
                      className="h-[600px]"
                    />
                  </div>
                </ExpandableCard>
              </div>
            )}

            <div className="flex flex-col gap-4">
              {testScenariosBySymbol.testScenarios.map((testScenario, index) => (
                <TestScenarioCard
                  key={testScenario.id}
                  index={index}
                  testFilePath={filePath}
                  testScenario={testScenario}
                  detailedView={detailedView}
                  showExcludedTests={showExcludedTests}
                  isAdmin={isAdmin}
                  onFeedbackSubmit={onFeedbackSubmit}
                  symbolName={testScenariosBySymbol.symbol}
                  disableSymbolAndTestScenarioSelection={disableSymbolAndTestScenarioSelection}
                  isSelected={scenarioSelections[testScenario.id]}
                  scenarioSelections={scenarioSelections}
                  toggleScenarioSelection={toggleScenarioSelection}
                  allowSelectingTests={allowSelectingTests}
                  showNotification={showNotification}
                />
              ))}
            </div>

            {/* Add file content and diff expandable cards */}
            {generatedTest && (
              <div className="mb-4 space-y-4">
                {generatedTest.content && (
                  <ExpandableCard
                    header={
                      <div className="flex items-center">
                        <FiFile className="mr-2 h-4 w-4 text-gray-500" />
                        <h4 className="font-medium text-sm">Full test file</h4>
                      </div>
                    }
                    defaultOpen={false}
                    actions={[
                      {
                        icon: <GoCopy className="h-5 w-5" />,
                        tooltip: "Copy code",
                        onClick: () => {
                          navigator.clipboard.writeText(generatedTest.content);
                          showNotification({
                            title: "Copied code",
                            type: "success",
                          });
                        },
                      },
                    ]}
                    includeDivider={false}
                    bodyClassName="p-0"
                  >
                    <div className="h-[600px]">
                      <CodeEditor
                        initialCode={generatedTest.content}
                        language={getLanguageFromFilePath(filePath)}
                        readOnly={true}
                        className="h-[600px]"
                      />
                    </div>
                  </ExpandableCard>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const CoverageGainsTable = ({
  coverageGains,
}: {
  coverageGains: {
    fileUnderTest: string;
    originalCoverage: number;
    tuskCoverage: number;
    coverageGain: number;
  }[];
}) => {
  if (!coverageGains || coverageGains.length === 0) return null;

  return (
    <div className="mb-8">
      <h3 className="text-md text-gray-900 font-medium mb-3">Coverage Metrics</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg text-sm">
          <thead>
            <tr className="bg-gray-50">
              <th className="py-1.5 px-3 border-b text-left text-sm">Source file</th>
              <th className="py-1.5 px-3 border-b text-right text-sm">Original</th>
              <th className="py-1.5 px-3 border-b text-right text-sm">After Tusk</th>
              <th className="py-1.5 px-3 border-b text-right text-sm">Gain</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {coverageGains.map((gain, index) => (
              <tr
                key={`${gain.fileUnderTest}-${index}`}
                className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
              >
                <td className="py-1.5 px-3 border-b">
                  <code className="text-xs bg-gray-100 px-1.5 py-0.5 rounded-sm break-all">
                    {gain.fileUnderTest}
                  </code>
                </td>
                <td className="py-1.5 px-3 border-b text-right text-gray-900 font-regular">
                  {gain.originalCoverage.toFixed(2)}%
                </td>
                <td className="py-1.5 px-3 border-b text-right text-gray-900 font-regular">
                  {gain.tuskCoverage.toFixed(2)}%
                </td>
                <td className="py-1.5 px-3 border-b text-right text-green-600 font-medium">
                  +{gain.coverageGain.toFixed(2)}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const TestScenarioFeedbackControls = ({
  testScenario,
  onFeedbackSubmit,
  showNotification,
}: {
  testScenario: GraphQLTestScenario;
  onFeedbackSubmit: (params: {
    testScenarioId: string;
    sentiment?: TestScenarioFeedbackSentiment;
    positiveFeedback?: TestScenarioPositiveFeedback[];
    negativeFeedback?: TestScenarioNegativeFeedback[];
    comment?: string;
    submitButtonClicked: boolean;
  }) => void;
  showNotification: (config: Omit<IShowNotificationConfig, "id">) => void;
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [feedbackType, setFeedbackType] = useState<"positive" | "negative" | null>(null);
  const [showHoverDetails, setShowHoverDetails] = useState<"positive" | "negative" | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0, right: 0 });
  const thumbsUpRef = useRef<HTMLDivElement>(null);
  const thumbsDownRef = useRef<HTMLDivElement>(null);

  // Add simple tooltip state for unselected buttons
  const [showSimpleTooltip, setShowSimpleTooltip] = useState<"positive" | "negative" | null>(null);

  const [positiveFeedback, setPositiveFeedback] = useState<TestScenarioPositiveFeedback[]>(
    testScenario.userFeedback?.positiveFeedback || [],
  );
  const [negativeFeedback, setNegativeFeedback] = useState<TestScenarioNegativeFeedback[]>(
    testScenario.userFeedback?.negativeFeedback || [],
  );
  const [comment, setComment] = useState<string>(testScenario.userFeedback?.comment || "");

  const [thumbsUpSelected, setThumbsUpSelected] = useState(
    testScenario.userFeedback?.sentiment === TestScenarioFeedbackSentiment.Positive,
  );
  const [thumbsDownSelected, setThumbsDownSelected] = useState(
    testScenario.userFeedback?.sentiment === TestScenarioFeedbackSentiment.Negative,
  );

  const handleThumbsUp = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    // Reset
    onFeedbackSubmit({
      testScenarioId: testScenario.id,
      sentiment: undefined,
      positiveFeedback: undefined,
      negativeFeedback: undefined,
      comment: undefined,
      submitButtonClicked: false,
    });
    setThumbsDownSelected(false);
    setNegativeFeedback([]);
    setPositiveFeedback([]);
    setComment("");

    setThumbsUpSelected(!thumbsUpSelected);
    if (!thumbsUpSelected) {
      setFeedbackType("positive");
      setIsPopupOpen(true);
    } else {
      setFeedbackType(null);
    }
  };

  const handleThumbsDown = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.stopPropagation();
    // Reset
    onFeedbackSubmit({
      testScenarioId: testScenario.id,
      sentiment: undefined,
      positiveFeedback: undefined,
      negativeFeedback: undefined,
      comment: undefined,
      submitButtonClicked: false,
    });

    setThumbsUpSelected(false);
    setPositiveFeedback([]);
    setNegativeFeedback([]);
    setComment("");

    setThumbsDownSelected(!thumbsDownSelected);
    if (!thumbsDownSelected) {
      setFeedbackType("negative");
      setIsPopupOpen(true);
    } else {
      setFeedbackType(null);
    }
  };

  const handleCancel = () => {
    setThumbsUpSelected(false);
    setThumbsDownSelected(false);
    setPositiveFeedback([]);
    setNegativeFeedback([]);
    setComment("");
    setIsPopupOpen(false);
    onFeedbackSubmit({
      testScenarioId: testScenario.id,
      sentiment: undefined,
      positiveFeedback: undefined,
      negativeFeedback: undefined,
      comment: undefined,
      submitButtonClicked: false,
    });
  };

  const handleSubmit = () => {
    if (feedbackType === "positive") {
      onFeedbackSubmit({
        testScenarioId: testScenario.id,
        sentiment: TestScenarioFeedbackSentiment.Positive,
        positiveFeedback: positiveFeedback.length > 0 ? positiveFeedback : undefined,
        comment: comment || undefined,
        submitButtonClicked: true,
      });
    } else if (feedbackType === "negative") {
      onFeedbackSubmit({
        testScenarioId: testScenario.id,
        sentiment: TestScenarioFeedbackSentiment.Negative,
        negativeFeedback: negativeFeedback.length > 0 ? negativeFeedback : undefined,
        comment: comment || undefined,
        submitButtonClicked: true,
      });
    }
    setIsPopupOpen(false);
  };

  // Format feedback text using the same approach as TestScenarioFeedbackModal
  const formatFeedbackText = (feedbackItems: string[]): JSX.Element => {
    if (!feedbackItems || feedbackItems.length === 0) {
      return <span className="text-gray-500 italic">No specific feedback</span>;
    }

    return (
      <div className="space-y-1">
        {feedbackItems.map((item) => (
          <div key={item} className="flex items-center">
            <span className="text-gray-700">
              {item
                .replace(/_/g, " ")
                .toLowerCase()
                .replace(/^\w/, (c: string) => c.toUpperCase())}
            </span>
          </div>
        ))}
      </div>
    );
  };

  // Calculate tooltip position when hover state changes
  useEffect(() => {
    const tooltipType = showHoverDetails || showSimpleTooltip;
    const ref = tooltipType === "positive" ? thumbsUpRef : thumbsDownRef;

    if (tooltipType && ref.current) {
      const rect = ref.current.getBoundingClientRect();

      // Different positioning based on tooltip type
      if (showSimpleTooltip) {
        // For simple tooltip, position below the button
        setTooltipPosition({
          top: rect.bottom + 5, // Position below the button with a small gap
          left: rect.left + rect.width / 2, // Center horizontally relative to the button
          right: window.innerWidth - (rect.left + rect.width / 2), // Add right position
        });
      } else if (showHoverDetails) {
        // For detailed tooltip, keep existing positioning or adjust as needed
        setTooltipPosition({
          top: rect.top,
          left: rect.left,
          right: window.innerWidth - rect.left,
        });
      }
    }
  }, [showHoverDetails, showSimpleTooltip]);

  return (
    <div className="text-sm">
      <div className="flex items-center justify-between">
        <div className="flex space-x-2">
          <div
            ref={thumbsUpRef}
            onClick={handleThumbsUp}
            onMouseEnter={() => {
              if (thumbsUpSelected) {
                setShowHoverDetails("positive");
              } else {
                setShowSimpleTooltip("positive");
              }
            }}
            onMouseLeave={() => {
              setShowHoverDetails(null);
              setShowSimpleTooltip(null);
            }}
            className={`flex items-center p-1.5 rounded-md transition ${
              thumbsUpSelected ? "bg-purple-100 text-purple-700" : "hover:bg-gray-100 text-gray-500"
            }`}
            aria-label="Thumbs up"
          >
            <FiThumbsUp className="h-4 w-4" />
          </div>
          <div
            ref={thumbsDownRef}
            onClick={handleThumbsDown}
            onMouseEnter={() => {
              if (thumbsDownSelected) {
                setShowHoverDetails("negative");
              } else {
                setShowSimpleTooltip("negative");
              }
            }}
            onMouseLeave={() => {
              setShowHoverDetails(null);
              setShowSimpleTooltip(null);
            }}
            className={`flex items-center p-1.5 rounded-md transition ${
              thumbsDownSelected
                ? "bg-purple-100 text-purple-700"
                : "hover:bg-gray-100 text-gray-500"
            }`}
            aria-label="Thumbs down"
          >
            <FiThumbsDown className="h-4 w-4" />
          </div>
        </div>
      </div>

      <TestScenarioFeedbackModal
        open={isPopupOpen}
        setOpen={setIsPopupOpen}
        feedbackType={feedbackType}
        positiveFeedback={positiveFeedback}
        setPositiveFeedback={setPositiveFeedback}
        negativeFeedback={negativeFeedback}
        setNegativeFeedback={setNegativeFeedback}
        comment={comment}
        setComment={setComment}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />

      {/* Existing detailed tooltip for selected feedback */}
      {showHoverDetails &&
        createPortal(
          <div
            className="fixed max-w-xs sm:max-w-sm md:max-w-md bg-white shadow-xl rounded-md p-4 border border-gray-200 transform-gpu"
            style={{
              top: `${tooltipPosition.top}px`,
              right: `${tooltipPosition.right}px`,
              zIndex: 9999, // Very high z-index to ensure it's on top of everything
            }}
          >
            <div className="space-y-3 text-sm">
              <div>
                <span className="block text-sm font-medium text-gray-700 mb-1">Feedback</span>
                {showHoverDetails === "positive"
                  ? formatFeedbackText(testScenario.userFeedback?.positiveFeedback || [])
                  : formatFeedbackText(testScenario.userFeedback?.negativeFeedback || [])}
              </div>

              {testScenario.userFeedback?.comment && (
                <div>
                  <span className="block text-sm font-medium text-gray-700 mb-1">Comment</span>
                  <div className="text-gray-700 bg-gray-50 p-2 rounded-md break-words whitespace-normal overflow-auto max-h-32">
                    {testScenario.userFeedback.comment}
                  </div>
                </div>
              )}
            </div>
          </div>,
          document.body,
        )}

      {/* New simple tooltip for unselected buttons */}
      {showSimpleTooltip &&
        createPortal(
          <div
            className="fixed bg-gray-800 text-white text-xs rounded-sm px-2 py-1 transform-gpu -translate-x-1/2"
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
              zIndex: 9999,
            }}
          >
            {showSimpleTooltip === "positive" ? "Helpful" : "Unhelpful"}
          </div>,
          document.body,
        )}
    </div>
  );
};
