// @ts-nocheck

import { createElement, useMemo, useState, useEffect, useCallback } from "react";
import {
  CircleStackIcon,
  PencilIcon,
  MagnifyingGlassCircleIcon,
  BookOpenIcon,
  CodeBracketSquareIcon,
  CommandLineIcon,
  LightBulbIcon,
  GlobeAmericasIcon,
  CursorArrowRippleIcon,
  ExclamationCircleIcon,
  PencilSquareIcon,
  CheckIcon,
  ArrowUpOnSquareIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  PhotoIcon,
  FolderIcon,
  TicketIcon,
  LinkIcon,
  CpuChipIcon,
  DocumentMagnifyingGlassIcon,
  RectangleGroupIcon,
  VideoCameraIcon,
  CogIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  EyeIcon,
  ComputerDesktopIcon,
  PaperClipIcon,
  ChatBubbleBottomCenterTextIcon,
  TrashIcon,
  ArchiveBoxIcon,
  CodeBracketIcon,
  ArrowPathRoundedSquareIcon,
  UserCircleIcon,
  QueueListIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/20/solid";
import { PencilSquareIcon as PencilSquareIconOutline } from "@heroicons/react/24/outline";
import { PencilSquareIcon as PencilSquareIconSolid } from "@heroicons/react/24/solid";
import {
  size,
  includes,
  isEmpty,
  map,
  join,
  split,
  replace,
  filter,
  find,
  sortBy,
  isString,
  flatMap,
  isError,
  isFunction,
  findIndex,
} from "lodash";
import { useNavigate } from "react-router-dom";
import { clsx } from "clsx";

import {
  ImageModal,
  HUMAN_READABLE_TASK_TYPES,
  AgentLogsList,
  CustomMarkdown,
  Button,
  RestorePullRequestModal,
  DeleteTaskModal,
  RetryGenerationModal,
  VideoModal,
  Toolbar,
  IToolbarOption,
  IToolConfig,
  IconTooltip,
} from "../components";
import { useAppContext, useNotificationContext } from "../providers";
import {
  AddressCodeReviewRunStatus,
  ClientFeatures,
  GeneratePullRequestRunStatus,
  GetTaskWithAllRunsQuery,
  InvestigationRunStatus,
  TestingRunStatus,
  GetTasksQuery,
  GeneratePullRequestRunInput,
  AddressCodeReviewRunInput,
} from "../__generatedGQL__/graphql";
import {
  getAppUrl,
  getGeneratePullRequestRunBranchUrl,
  getNumberWithOrdinal,
  TaskStatus,
  canRetryTesting,
  getTestingVideoUrl,
  canDeleteTask,
  canCreatePullRequest,
  canRetryGeneration,
  getPullRequestIdWithRepo,
  isTaskRunning,
} from "../utils";
import { IssueStatus } from "./IssuesTable";
import { useTaskHook } from "../hooks/useTaskHook";

import { isPaidPlan } from "../containers";
import { isApolloError } from "@apollo/client";
import { ReplicateToNewPullRequestModal } from "./ReplicateToNewPullRequest";
import { RunNotesModal } from "./RunNotesModal";
// TODO: Don't have this hardcoded
const codeToolNameMap: Record<string, IToolConfig> = {
  read_pull_request_review: { humanReadableName: "Read pull request review", icon: BookOpenIcon },
  infer_issue_type: {
    humanReadableName: "Inferred issue type",
    icon: TicketIcon,
  },
  image_analysis: { humanReadableName: "Analyzed images", icon: PhotoIcon },
  video_analysis: { humanReadableName: "Analyzed videos", icon: VideoCameraIcon },
  determine_repo: { humanReadableName: "Determined repo", icon: FolderIcon },
  external_link_analysis: { humanReadableName: "Analyzed external link", icon: LinkIcon },
  external_link_analysis_error: {
    humanReadableName: "Error analyzing external link",
    icon: ExclamationCircleIcon,
    color: "error",
  },
  use_merged_pr_memory: { humanReadableName: "Found similar pull requests", icon: CpuChipIcon },
  initial_exploration: {
    humanReadableName: "Explored codebase",
    icon: DocumentMagnifyingGlassIcon,
  },
  initial_component_library_exploration: {
    humanReadableName: "Explored component library",
    icon: RectangleGroupIcon,
  },
  update_plan: { humanReadableName: "Updated plan", icon: PencilIcon },
  query_vector_database: { humanReadableName: "Searched for relevant code", icon: CircleStackIcon },
  search_file_paths: { humanReadableName: "Searched file paths", icon: MagnifyingGlassCircleIcon },
  search_code: { humanReadableName: "Searched code", icon: MagnifyingGlassCircleIcon },
  read_file: { humanReadableName: "Read file", icon: BookOpenIcon },
  update_high_level_updates: { humanReadableName: "Updated coding instructions", icon: PencilIcon },
  finish_high_level_updates: {
    humanReadableName: "Finished coding instructions",
    icon: PencilIcon,
  },
  update_code_investigation_notes: {
    humanReadableName: "Updated investigation notes",
    icon: PencilIcon,
  },
  finish_code_investigation_notes: {
    humanReadableName: "Finished investigation notes",
    icon: PencilIcon,
  },
  generate_file: { humanReadableName: "Generated file", icon: CodeBracketSquareIcon },
  replicate_pull_request: {
    humanReadableName: "Replicated pull request",
    icon: ArrowPathRoundedSquareIcon,
  },
  create_pull_request: { humanReadableName: "Created pull request", icon: CommandLineIcon },
  update_pull_request: { humanReadableName: "Updated pull request", icon: CommandLineIcon },
  create_branch: { humanReadableName: "Created branch", icon: CommandLineIcon },
  update_branch: { humanReadableName: "Updated branch", icon: CommandLineIcon },
  human_update_branch: { humanReadableName: "Human updated branch", icon: UserCircleIcon },
  run_external_check: { humanReadableName: "Running checks", icon: CommandLineIcon },
  rerequest_code_review: {
    humanReadableName: "Re-requested review",
    icon: BookOpenIcon,
  },
  read_error_logs: {
    humanReadableName: "Read error logs",
    icon: ExclamationCircleIcon,
  },
  run_browser_testing: {
    humanReadableName: "Running browser testing",
    icon: ComputerDesktopIcon,
    isBeta: true,
  },
  browser_testing_result: {
    humanReadableName: "Browser testing result",
    icon: ComputerDesktopIcon,
    isBeta: true,
  },
  browser_testing_failure: {
    humanReadableName: "Browser testing failure",
    icon: ExclamationCircleIcon,
    color: "error",
    isBeta: true,
  },
  thinking: { humanReadableName: "Thinking", icon: LightBulbIcon },
  external_check_warning: {
    humanReadableName: "Automated checks warning",
    icon: ExclamationCircleIcon,
    color: "warning",
  },
  max_steps_reached: { humanReadableName: "Max steps reached", icon: ExclamationCircleIcon },
  self_failure: {
    humanReadableName: "Failed to finish",
    icon: ExclamationCircleIcon,
    color: "warning",
  },
  self_review_success: { humanReadableName: "Finished self review", icon: CheckIcon },
  self_review_fail: {
    humanReadableName: "Failed self review",
    icon: ExclamationCircleIcon,
    color: "error",
  },
  generated_advice: { humanReadableName: "Generated advice", icon: PencilSquareIcon },
  retrieved_pr_insights: {
    humanReadableName: "Retrieved insights from memory",
    icon: CogIcon,
  },
  generated_unknowns: {
    humanReadableName: "Identified unknowns",
    icon: ClipboardDocumentListIcon,
  },
  subtask_results: {
    humanReadableName: "Investigated unknowns",
    icon: ClipboardDocumentCheckIcon,
  },
  // Additional advanced tools for subtask agent
  contextual_search_file_paths: {
    humanReadableName: "Searched file paths",
    icon: MagnifyingGlassCircleIcon,
  },
  get_symbol_definition: {
    humanReadableName: "Retrieved symbol definitions",
    icon: DocumentTextIcon,
  },
  get_symbol_usage: {
    humanReadableName: "Retrieved symbol usages",
    icon: DocumentTextIcon,
  },
  find_symbol_definition: {
    humanReadableName: "Found symbol definitions",
    icon: DocumentTextIcon,
  },
  find_files: {
    humanReadableName: "Found files",
    icon: DocumentDuplicateIcon,
  },
  subtask_observation: {
    humanReadableName: "Observations",
    icon: EyeIcon,
  },
  gprr_samples: {
    humanReadableName: "Code reasoning samples",
    icon: QueueListIcon,
  },
  afr_samples: {
    humanReadableName: "Code reasoning samples",
    icon: QueueListIcon,
  },
  run_sampling: {
    humanReadableName: "Considering multiple solutions",
    icon: PuzzlePieceIcon,
  },
  complex_task_summary: {
    humanReadableName: "Task may be too complex",
    icon: ExclamationCircleIcon,
    color: "warning",
  },
};

const testingToolNameMap = {
  open_url: { humanReadableName: "Opened URL", icon: GlobeAmericasIcon },
  click: { humanReadableName: "Clicked element", icon: CursorArrowRippleIcon },
  set_value: { humanReadableName: "Set input value", icon: PencilSquareIcon },
  finish_testing: { humanReadableName: "Finished testing", icon: CheckIcon },
  failed_testing: { humanReadableName: "Failed testing", icon: ExclamationCircleIcon },
  upload_video: { humanReadableName: "Uploaded video", icon: ArrowUpOnSquareIcon },
  thinking: { humanReadableName: "Thinking", icon: LightBulbIcon },
};

const ADMIN_ONLY_TOOLS: (keyof typeof codeToolNameMap | keyof typeof testingToolNameMap)[] = [
  "update_plan",
  "gprr_samples",
  "afr_samples",
];

export interface IAgentLog {
  timestamp: number;
  type: "tool" | "observation";
  toolName?: string;
  thought?: string;
  observation?: string;
  toolArgs?: Record<string, unknown>;

  // This exists when FE merges a nested run into the main log (e.g. automated feedback run into main generate pull request run)
  nestedLogs?: IAgentLog[];
}

const addAutomatedFeedbackNestedLogs = (
  agentLogs: IAgentLog[],
  automatedFeedbackRuns: (
    | GetTaskWithAllRunsQuery["task"]["allGeneratePullRequestRuns"][number]["allExternalCheckRuns"][number]["allAutomatedFeedbackRuns"][number]
    | GetTaskWithAllRunsQuery["task"]["allGeneratePullRequestRuns"][number]["allBrowserTestingRuns"][number]["allAutomatedFeedbackRuns"][number]
    | GetTaskWithAllRunsQuery["task"]["allGeneratePullRequestRuns"][number]["activePullRequest"]["allAddressCodeReviewRuns"][number]["allExternalCheckRuns"][number]["allAutomatedFeedbackRuns"][number]
  )[],
): IAgentLog[] => {
  return map(agentLogs, (agentLog) => {
    // If nested logs already exist, don't add them again
    if (!isEmpty(agentLog.nestedLogs)) {
      return agentLog;
    }

    const automatedFeedbackRunId = agentLog.toolArgs?.automatedFeedbackRunId;
    if (
      (agentLog.toolName === "read_error_logs" || agentLog.toolName === "browser_testing_result") &&
      isString(automatedFeedbackRunId)
    ) {
      const automatedFeedbackRun = find(automatedFeedbackRuns, { id: automatedFeedbackRunId });
      if (automatedFeedbackRun) {
        return {
          ...agentLog,
          nestedLogs: automatedFeedbackRun?.logs || [],
        };
      }
    }
    return agentLog;
  });
};

interface SelectedGeneratePullRequestRun {
  id: string;
  logs: IAgentLog[];
  status: GeneratePullRequestRunStatus;
}

interface AddressCodeReviewRunWithLogs {
  id: string;
  logs: IAgentLog[];
  status: AddressCodeReviewRunStatus;
  finalCommitSha?: string;
}

interface IProps {
  task: GetTaskWithAllRunsQuery["task"];
  taskStatus: TaskStatus;

  onUpdateGeneratePullRequestRun: (id: string, input: GeneratePullRequestRunInput) => Promise<void>;
  onUpdateAddressCodeReviewRun: (id: string, input: AddressCodeReviewRunInput) => Promise<void>;
  onRestoreGeneratePullRequestRun: (
    taskId: string,
    restoreToGeneratePullRequestRunId: string,
  ) => Promise<void>;
  onReplicateUserRequestedRun: ({
    taskId,
    targetGeneratePullRequestRunId,
    targetAddressCodeReviewRunId,
  }: {
    taskId: string;
    targetGeneratePullRequestRunId?: string;
    targetAddressCodeReviewRunId?: string;
  }) => Promise<void>;
  refetchTask: () => Promise<void>;
  deleteRefreshTask: () => void;
}

export const TaskAgentLog = ({
  task,
  taskStatus,
  onUpdateGeneratePullRequestRun,
  onUpdateAddressCodeReviewRun,
  onRestoreGeneratePullRequestRun,
  onReplicateUserRequestedRun,
  refetchTask,
  deleteRefreshTask,
}: IProps) => {
  const { selectedClient, isAdmin } = useAppContext();
  const { showNotification } = useNotificationContext();

  const [isRestorePullRequestModalOpen, setIsRestorePullRequestModalOpen] = useState(false);
  const [isReplicateToNewPullRequestModalOpen, setIsReplicateToNewPullRequestModalOpen] =
    useState(false);
  const [replicateToNewPullRequestModalData, setReplicateToNewPullRequestModalData] = useState<{
    targetGeneratePullRequestRunId?: string;
    targetAddressCodeReviewRunId?: string;
    gprrAttempt: number;
    acrrAttempt?: number;
    pullRequestUrlToClose?: string;
    diffUrl: string;
    targetCommitSha: string;
  } | null>(null);
  const [isRunNotesModalOpen, setIsRunNotesModalOpen] = useState(false);
  const [runNotesModalData, setRunNotesModalData] = useState<{
    updateNote?: (note: string) => Promise<void>;
    currentNote?: string;
    generatePullRequestRunId?: string;
    addressCodeReviewRunId?: string;
    gprrAttempt: number;
    acrrAttempt?: number;
  } | null>(null);

  const [isTaskDetailsVisible, setIsTaskDetailsVisible] = useState(true);
  const [selectedGeneratePullRequestRunId, setSelectedGeneratePullRequestRunId] = useState<
    string | null
  >(task?.activeGeneratePullRequestRun?.id);
  const [fullSelectedGeneratePullRequestRun, setFullSelectedGeneratePullRequestRun] = useState<
    GetTaskWithAllRunsQuery["task"]["allGeneratePullRequestRuns"][number] | null
  >(null);
  const [latestRunId, setLatestRunId] = useState<string | null>(null);

  useEffect(() => {
    if (selectedGeneratePullRequestRunId) {
      const generatePullRequestRun = find(task.allGeneratePullRequestRuns, {
        id: selectedGeneratePullRequestRunId,
      });
      setFullSelectedGeneratePullRequestRun(generatePullRequestRun);
    }
  }, [selectedGeneratePullRequestRunId, task]);

  useEffect(() => {
    const activeGeneratePullRequestRunId = task?.activeGeneratePullRequestRun?.id;
    if (
      activeGeneratePullRequestRunId &&
      activeGeneratePullRequestRunId !== selectedGeneratePullRequestRunId
    ) {
      setSelectedGeneratePullRequestRunId(activeGeneratePullRequestRunId);
    }
  }, [task?.activeGeneratePullRequestRun?.id]);

  useEffect(() => {
    if (!task) return;

    let latestRun: { id: string; timestamp: number } | null = null;

    const updateLatestRun = (id: string, timestamp: number) => {
      if (!latestRun || timestamp > latestRun.timestamp) {
        latestRun = { id, timestamp };
      }
    };

    if (task.activeInvestigationRun) {
      updateLatestRun(
        task.activeInvestigationRun.id,
        new Date(task.activeInvestigationRun.createdAt).getTime(),
      );
    }

    const generatePullRequestRun = find(task.allGeneratePullRequestRuns, {
      id: selectedGeneratePullRequestRunId,
    });

    if (generatePullRequestRun) {
      updateLatestRun(
        generatePullRequestRun.id,
        new Date(generatePullRequestRun.createdAt).getTime(),
      );

      generatePullRequestRun.activePullRequest?.allAddressCodeReviewRuns.forEach((addressRun) => {
        updateLatestRun(addressRun.id, new Date(addressRun.createdAt).getTime());
      });
    }

    setLatestRunId(latestRun?.id || null);
  }, [task, selectedGeneratePullRequestRunId]);

  const generatePullRequestRunOptions = useMemo(() => {
    return map(sortBy(task?.allGeneratePullRequestRuns, "createdAt"), (generatePullRequestRun) => {
      return {
        id: generatePullRequestRun.id,
        status: generatePullRequestRun.status,
      };
    });
  }, [task]);

  const filterAgentLogs = useCallback(
    (agentLogs: IAgentLog[]) => {
      return filter(agentLogs, (log) => {
        if (isAdmin) {
          return true;
        }

        if (includes(ADMIN_ONLY_TOOLS, log.toolName)) {
          return false;
        }

        return true;
      });
    },
    [isAdmin],
  );

  const {
    selectedGeneratePullRequestRun,
    addressCodeReviewRuns,
  }: {
    selectedGeneratePullRequestRun?: SelectedGeneratePullRequestRun;
    addressCodeReviewRuns?: AddressCodeReviewRunWithLogs[];
  } = useMemo(() => {
    if (!selectedGeneratePullRequestRunId || !task) {
      return {};
    }

    const generatePullRequestRun = find(task?.allGeneratePullRequestRuns, {
      id: selectedGeneratePullRequestRunId,
    });

    if (!generatePullRequestRun) {
      return {};
    }

    const generatePullRequestRunLogs = addAutomatedFeedbackNestedLogs(
      generatePullRequestRun?.logs || [],
      flatMap(
        [
          ...generatePullRequestRun?.allExternalCheckRuns,
          ...generatePullRequestRun?.allBrowserTestingRuns,
        ],
        (run) => run.allAutomatedFeedbackRuns,
      ),
    );

    return {
      selectedGeneratePullRequestRun: {
        id: generatePullRequestRun.id,
        logs: generatePullRequestRunLogs,
        status: generatePullRequestRun.status,
      },
      addressCodeReviewRuns: map(
        sortBy(generatePullRequestRun.activePullRequest?.allAddressCodeReviewRuns, "createdAt"),
        (run) => ({
          id: run.id,
          logs: addAutomatedFeedbackNestedLogs(
            run.logs,
            flatMap(
              run.allExternalCheckRuns,
              (externalCheckRun) => externalCheckRun.allAutomatedFeedbackRuns,
            ),
          ),
          status: run.status,
        }),
      ),
    };
  }, [selectedGeneratePullRequestRunId, task]);

  const getGprrAttempt = useCallback(
    (generatePullRequestRunId: string) => {
      return (
        findIndex(generatePullRequestRunOptions, (run) => run.id === generatePullRequestRunId) + 1
      );
    },
    [generatePullRequestRunOptions],
  );

  const [isCodeGenerationAgentLogVisible, setIsCodeGenerationAgentLogVisible] = useState(false);

  const [visibleAddressingCodeReviewAgentLogs, setVisibleAddressingCodeReviewAgentLogs] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (!selectedGeneratePullRequestRun) {
      return;
    }

    setIsCodeGenerationAgentLogVisible(
      selectedGeneratePullRequestRun.status === GeneratePullRequestRunStatus.Running,
    );

    setVisibleAddressingCodeReviewAgentLogs(
      map(
        filter(
          addressCodeReviewRuns,
          (addressCodeReviewRun) =>
            addressCodeReviewRun.status === AddressCodeReviewRunStatus.Running,
        ),
        (addressCodeReviewRun) => addressCodeReviewRun.id,
      ),
    );
  }, [selectedGeneratePullRequestRun]);

  const investigationAgentLogs = task.activeInvestigationRun?.logs || [];
  const investigationRunStatus = task.activeInvestigationRun?.status;
  const [isInvestigationAgentLogVisible, setIsInvestigationAgentLogVisible] = useState(
    investigationRunStatus === InvestigationRunStatus.Running,
  );

  // Not implemented yet
  const activeTestingRunStatus = TestingRunStatus.Completed;
  const [isTestingAgentLogVisible, setIsTestingAgentLogVisible] = useState(false);

  const [loadingTask, setLoadingTask] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageModalUrl, setImageModalUrl] = useState("");

  const [isRetryGenerationModalOpen, setIsRetryGenerationModalOpen] = useState<boolean>(false);
  const [retryGenerationTask, setRetryGenerationTask] =
    useState<GetTaskWithAllRunsQuery["task"]>(null);

  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>(null);

  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState<boolean>(false);
  const [taskToDelete, setTaskToDelete] = useState<GetTaskWithAllRunsQuery["task"]>(null);

  const { retryTask, testTask, deleteTask, createPullRequestFromTask } = useTaskHook();

  const [description, descriptionHasImages] = useMemo(() => {
    if (size(task?.metadata?.images) === 0) {
      return [task?.metadata?.description, false];
    }

    let description = task?.metadata?.description;
    let descriptionHasImages = false;
    const taskImages = task?.metadata?.images || [];
    for (let imageIndex = 0; imageIndex < size(taskImages); imageIndex++) {
      const imageUrlPlaceholder = `Image URL index ${imageIndex}`;
      if (includes(description, imageUrlPlaceholder)) {
        descriptionHasImages = true;
        description = replace(description, imageUrlPlaceholder, taskImages[imageIndex]);
      }
    }
    return [description, descriptionHasImages];
  }, [task?.metadata?.description, task?.metadata?.images]);

  useEffect(() => {
    setIsInvestigationAgentLogVisible(task.activeInvestigationRun?.id === latestRunId);
  }, [latestRunId, task.activeInvestigationRun]);

  useEffect(() => {
    setIsCodeGenerationAgentLogVisible(selectedGeneratePullRequestRun?.id === latestRunId);
  }, [latestRunId, selectedGeneratePullRequestRun]);

  useEffect(() => {
    setVisibleAddressingCodeReviewAgentLogs(
      addressCodeReviewRuns?.filter((run) => run.id === latestRunId).map((run) => run.id) || [],
    );
  }, [latestRunId, addressCodeReviewRuns]);

  const openRetryGenerationModal = canRetryGeneration(task, taskStatus)
    ? async () => {
        setRetryGenerationTask(task);
        setIsRetryGenerationModalOpen(true);
      }
    : undefined;

  const retryTesting = canRetryTesting(taskStatus)
    ? async () => {
        const pullRequestId = task?.activeGeneratePullRequestRun?.activePullRequest?.id;
        if (!pullRequestId) {
          showNotification({
            title: "Unable to retry testing",
            message: "The pull request for this issue does not exist. Please try again.",
          });
          return;
        }
        setLoadingTask(true);
        await testTask(pullRequestId);
        await refetchTask();
        setLoadingTask(false);
        showNotification({
          title: "Testing in progress ⚙️",
          message:
            "Tusk is now testing the pull request. You can view the testing video once it is complete.",
        });
      }
    : undefined;

  const { testingVideoUrl, canViewTestingVideo } = getTestingVideoUrl(task);
  const viewTestingVideo = canViewTestingVideo
    ? () => {
        setVideoUrl(testingVideoUrl);
        setIsVideoModalOpen(true);
      }
    : undefined;

  const openDeleteTaskModal = canDeleteTask(task, selectedClient, isAdmin)
    ? async () => {
        setTaskToDelete(task);
        setIsDeleteTaskModalOpen(true);
      }
    : undefined;

  const createPullRequest = canCreatePullRequest(task.activeGeneratePullRequestRun)
    ? async () => {
        try {
          showNotification({
            title: "Creating pull request ⚙️",
            duration: 5000,
          });
          setLoadingTask(true);
          const pullRequestUrl = await createPullRequestFromTask(task.id);
          await refetchTask();
          showNotification({
            title: "Created pull request 🧑‍💻",
            message: `View pull request for "${task.metadata.title}" [here](${pullRequestUrl})`,
          });
        } catch (error: unknown) {
          console.error("Error creating pull request", error);
          if (isError(error) && isApolloError(error)) {
            showNotification({
              title: "Error creating pull request",
              message: error.message,
            });
          } else {
            showNotification({
              title: "Error creating pull request",
            });
          }
        } finally {
          setLoadingTask(false);
        }
      }
    : undefined;

  const toolbarOptions: IToolbarOption[] = [];

  if (isFunction(viewTestingVideo)) {
    toolbarOptions.push({
      Icon: ComputerDesktopIcon,
      onClickHandler: viewTestingVideo,
      disabled: false,
      hoverText: "View testing video",
    });
  }
  if (isFunction(openRetryGenerationModal)) {
    toolbarOptions.push({
      Icon: ArrowPathRoundedSquareIcon,
      onClickHandler: () => {
        if (!loadingTask) openRetryGenerationModal();
      },
      disabled: loadingTask,
      hoverText: "Retry generation",
    });
  }

  if (isFunction(retryTesting)) {
    toolbarOptions.push({
      Icon: ArrowPathRoundedSquareIcon,
      onClickHandler: async () => {
        if (!loadingTask) await retryTesting();
      },
      disabled: loadingTask,
      hoverText: "Retry testing",
    });
  }
  if (isFunction(openDeleteTaskModal)) {
    toolbarOptions.push({
      Icon: TrashIcon,
      onClickHandler: openDeleteTaskModal,
      disabled: false,
      hoverText: "Delete issue",
    });
  }
  if (isFunction(createPullRequest)) {
    toolbarOptions.push({
      Icon: CodeBracketIcon,
      onClickHandler: async () => {
        if (!loadingTask) await createPullRequest();
      },
      disabled: loadingTask,
      hoverText: "Create pull request",
    });
  }

  return (
    <>
      <div className="flow-root pb-8">
        <div
          className={clsx(
            "-ml-4 cursor-pointer",
            isTaskDetailsVisible ? "font-semibold" : "font-normal",
          )}
          onClick={() => setIsTaskDetailsVisible(!isTaskDetailsVisible)}
        >
          <h3 className="text-md font-medium flex items-center justify-between">
            <span>
              Issue details
              <IssueStatus status={taskStatus} className="ml-2" />
              {isTaskDetailsVisible ? (
                <ChevronDownIcon
                  className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                  aria-hidden="true"
                />
              ) : (
                <ChevronRightIcon
                  className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                  aria-hidden="true"
                />
              )}
            </span>
            <div onClick={(e) => e.stopPropagation()}>
              <Toolbar toolbarOptions={toolbarOptions} />
            </div>
          </h3>
        </div>
        {isTaskDetailsVisible && (
          <div className="mt-4">
            {task?.metadata?.title && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Title</p>
                <p>{task?.metadata?.title}</p>
              </div>
            )}
            {task?.type && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Type</p>
                <p>{HUMAN_READABLE_TASK_TYPES[task?.type]}</p>
              </div>
            )}
            {task?.metadata?.description && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Description</p>
                <div className="mt-2 prose max-w-none prose-headings:text-sm text-sm prose-img:max-h-40">
                  <CustomMarkdown>{description}</CustomMarkdown>
                </div>
              </div>
            )}
            {task?.metadata?.location && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Location</p>
                <p>{task?.metadata?.location}</p>
              </div>
            )}
            {!isEmpty(task?.metadata?.images) && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Images</p>
                <div className="flex space-x-4">
                  {map(task?.metadata?.images, (image, index) => (
                    <div
                      onClick={() => {
                        setImageModalUrl(image);
                        setIsImageModalOpen(true);
                      }}
                      key={index}
                      className="relative h-20 w-20 rounded-lg hover:cursor-pointer"
                    >
                      <img
                        src={image}
                        alt={`uploaded-image-${index}`}
                        className="h-full w-full object-cover rounded-lg"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {task?.repo?.name && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Repository</p>
                {isAdmin ? (
                  <a
                    href={`${getAppUrl()}/app/admin?repoId=${task?.repo?.id}&showTree=1`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-600 hover:text-purple-800 hover:underline"
                  >
                    {task?.repo?.name}
                  </a>
                ) : (
                  <p>{task?.repo?.name}</p>
                )}
              </div>
            )}
            {task?.externalTicketMetadata?.identifier && task?.externalTicketMetadata?.url && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">External issue</p>
                <a
                  href={task?.externalTicketMetadata?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-600 hover:text-purple-800 hover:underline"
                >
                  {task?.externalTicketMetadata?.identifier}
                </a>
              </div>
            )}
            {fullSelectedGeneratePullRequestRun?.activePullRequest?.externalUrl && (
              <div className="mt-2 text-sm text-gray-500">
                <div className="mt-4 mb-4 border-t border-gray-200"></div>
                <p className="font-semibold">Pull request</p>
                <a
                  href={fullSelectedGeneratePullRequestRun?.activePullRequest?.externalUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-600 hover:text-purple-800 hover:underline"
                >
                  {fullSelectedGeneratePullRequestRun?.activePullRequest?.externalUrl}
                </a>
              </div>
            )}
            {fullSelectedGeneratePullRequestRun?.activePullRequest?.dynamicEnvUrl && (
              <div className="mt-2 text-sm text-gray-500">
                <p className="font-semibold">Preview URL</p>
                <a
                  href={fullSelectedGeneratePullRequestRun?.activePullRequest?.dynamicEnvUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-600 hover:text-purple-800 hover:underline"
                >
                  {fullSelectedGeneratePullRequestRun?.activePullRequest?.dynamicEnvUrl}
                </a>
              </div>
            )}
            {!fullSelectedGeneratePullRequestRun?.activePullRequest?.externalUrl &&
              fullSelectedGeneratePullRequestRun?.branchName &&
              getGeneratePullRequestRunBranchUrl(task) && (
                <div className="mt-2 text-sm text-gray-500">
                  <div className="mt-4 mb-4 border-t border-gray-200"></div>
                  <p className="font-semibold">Branch</p>
                  <a
                    href={getGeneratePullRequestRunBranchUrl(task)}
                    target="_blank"
                    rel="noreferrer"
                    className="text-purple-600 hover:text-purple-800 hover:underline"
                  >
                    {fullSelectedGeneratePullRequestRun?.branchName}
                  </a>
                </div>
              )}
          </div>
        )}
        {investigationRunStatus && (
          <>
            <div
              className={clsx(
                "mt-8 -ml-4 cursor-pointer",
                isInvestigationAgentLogVisible ? "font-semibold" : "font-normal",
              )}
              onClick={() => setIsInvestigationAgentLogVisible(!isInvestigationAgentLogVisible)}
            >
              <h3 className="text-md font-medium inline-block">
                Investigation
                {investigationRunStatus && (
                  <span
                    className={clsx(
                      "ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
                      {
                        "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
                          [InvestigationRunStatus.Running],
                          investigationRunStatus,
                        ),
                        "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
                          [
                            InvestigationRunStatus.SelfFailure,
                            InvestigationRunStatus.InternalFailure,
                          ],
                          investigationRunStatus,
                        ),
                        "bg-yellow-50 text-yellow-700 ring-yellow-600/20": includes(
                          [InvestigationRunStatus.Pending],
                          investigationRunStatus,
                        ),
                        "bg-green-50 text-green-700 ring-green-600/20": includes(
                          [InvestigationRunStatus.Completed],
                          investigationRunStatus,
                        ),
                      },
                    )}
                  >
                    {join(split(investigationRunStatus, "_"), " ")}
                  </span>
                )}
                {isInvestigationAgentLogVisible ? (
                  <ChevronDownIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronRightIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                )}
              </h3>
            </div>
            {isInvestigationAgentLogVisible && (
              <>
                {investigationRunStatus !== InvestigationRunStatus.Running &&
                size(investigationAgentLogs) === 0 ? (
                  <div className="mt-4 text-sm text-gray-500">
                    <p>There are no investigation logs for this issue.</p>
                  </div>
                ) : (
                  <AgentLogsList
                    agentLogs={investigationAgentLogs}
                    isRunning={investigationRunStatus === InvestigationRunStatus.Running}
                    toolMap={codeToolNameMap}
                    isLatestRun={task.activeInvestigationRun?.id === latestRunId}
                  />
                )}
              </>
            )}
          </>
        )}

        <>
          <div
            className={clsx("-ml-4 cursor-pointer mt-8", "font-medium")}
            onClick={() => setIsCodeGenerationAgentLogVisible(!isCodeGenerationAgentLogVisible)}
          >
            <div className="flex flex-row items-center">
              <h3>Code generation</h3>
              {size(generatePullRequestRunOptions) > 1 && (
                <select
                  value={selectedGeneratePullRequestRunId}
                  onChange={(e) => {
                    setSelectedGeneratePullRequestRunId(e.target.value);
                  }}
                  className="ml-3 mr-2 p-1 pl-2 border rounded-md text-xs w-[10rem]"
                  onClick={(e) => e.stopPropagation()}
                >
                  {map(generatePullRequestRunOptions, (generatePullRequestRun, index) => {
                    const isActiveRun =
                      generatePullRequestRun.id === task.activeGeneratePullRequestRun?.id;
                    return (
                      <option key={generatePullRequestRun.id} value={generatePullRequestRun.id}>
                        {`${getNumberWithOrdinal(index + 1)} attempt${
                          isActiveRun ? " (current)" : ""
                        }`}
                      </option>
                    );
                  })}
                </select>
              )}
              {selectedGeneratePullRequestRun?.status && (
                <span
                  className={clsx(
                    "ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
                    {
                      "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
                        [GeneratePullRequestRunStatus.Running],
                        selectedGeneratePullRequestRun?.status,
                      ),
                      "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
                        [
                          GeneratePullRequestRunStatus.SelfFailure,
                          GeneratePullRequestRunStatus.InternalFailure,
                        ],
                        selectedGeneratePullRequestRun?.status,
                      ),
                      "bg-yellow-50 text-yellow-700 ring-yellow-600/20": includes(
                        [GeneratePullRequestRunStatus.Pending],
                        selectedGeneratePullRequestRun?.status,
                      ),
                      "bg-green-50 text-green-700 ring-green-600/20": includes(
                        [GeneratePullRequestRunStatus.Completed],
                        selectedGeneratePullRequestRun?.status,
                      ),
                    },
                  )}
                >
                  {join(split(selectedGeneratePullRequestRun?.status, "_"), " ")}
                </span>
              )}
              <div className="flex items-center ml-4 mr-2" onClick={(e) => e.stopPropagation()}>
                <IconTooltip
                  icon={
                    fullSelectedGeneratePullRequestRun?.notesMetadata?.userNote
                      ? PencilSquareIconSolid
                      : PencilSquareIconOutline
                  }
                  placement="top"
                  onClick={() => {
                    setRunNotesModalData({
                      currentNote: fullSelectedGeneratePullRequestRun?.notesMetadata?.userNote,
                      generatePullRequestRunId: selectedGeneratePullRequestRunId,
                      gprrAttempt: getGprrAttempt(selectedGeneratePullRequestRunId),
                      updateNote: async (note: string) => {
                        try {
                          await onUpdateGeneratePullRequestRun(selectedGeneratePullRequestRunId, {
                            notesMetadata: {
                              userNote: note,
                            },
                          });
                        } catch (error) {
                          console.error(error);
                          showNotification({
                            title: "Error updating note",
                            message: error.message,
                            type: "error",
                          });
                        }
                        await refetchTask();
                        setIsRunNotesModalOpen(false);
                      },
                    });
                    setIsRunNotesModalOpen(true);
                  }}
                  iconClassName={clsx(
                    fullSelectedGeneratePullRequestRun?.notesMetadata?.userNote
                      ? "text-gray-900"
                      : "text-gray-300",
                  )}
                  tooltipText={
                    fullSelectedGeneratePullRequestRun?.notesMetadata?.userNote || undefined
                  }
                />
              </div>
              <div>
                {isCodeGenerationAgentLogVisible ? (
                  <ChevronDownIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronRightIcon
                    className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
          </div>
          {isCodeGenerationAgentLogVisible && (
            <>
              {selectedGeneratePullRequestRun?.status !== GeneratePullRequestRunStatus.Running &&
              size(selectedGeneratePullRequestRun?.logs || []) === 0 ? (
                <div className="mt-4 text-sm text-gray-500">
                  <p>There are no code generation logs for this issue.</p>
                </div>
              ) : (
                <AgentLogsList
                  filterAgentLogs={filterAgentLogs}
                  agentLogs={selectedGeneratePullRequestRun?.logs || []}
                  isRunning={
                    selectedGeneratePullRequestRun?.status === GeneratePullRequestRunStatus.Running
                  }
                  toolMap={codeToolNameMap}
                  isLatestRun={selectedGeneratePullRequestRun?.id === latestRunId}
                />
              )}
              {/* Only show replicate button if GPRR has initialBaseCommitSha and finalCommitSha */}
              {fullSelectedGeneratePullRequestRun?.initialBaseCommitSha &&
                fullSelectedGeneratePullRequestRun?.finalCommitSha && (
                  <>
                    <Button
                      onClick={() => {
                        setReplicateToNewPullRequestModalData({
                          targetGeneratePullRequestRunId: fullSelectedGeneratePullRequestRun.id,
                          pullRequestUrlToClose:
                            task.activeGeneratePullRequestRun?.activePullRequest?.externalUrl,
                          diffUrl: `https://github.com/${task.repo.ownerName}/${task.repo.name}/compare/${task.repo.defaultBranch}...${fullSelectedGeneratePullRequestRun.finalCommitSha}`,
                          targetCommitSha: fullSelectedGeneratePullRequestRun.finalCommitSha,
                          gprrAttempt:
                            findIndex(
                              generatePullRequestRunOptions,
                              (run) => run.id === fullSelectedGeneratePullRequestRun.id,
                            ) + 1,
                        });
                        setIsReplicateToNewPullRequestModalOpen(true);
                      }}
                      variant="secondary"
                      size="xs"
                      className="mt-4 ml-12"
                      disabled={isTaskRunning(taskStatus)}
                    >
                      Replicate to new PR
                    </Button>
                  </>
                )}

              {/*
               * Only show restore button if pull request exists on a non-current GPRR attempt
               * TODO: for now hide this since we have replicate PR
               */}
              {/* {fullSelectedGeneratePullRequestRun?.id !== task?.activeGeneratePullRequestRun?.id &&
                fullSelectedGeneratePullRequestRun?.activePullRequest?.externalUrl && (
                  <>
                    <Button
                      onClick={() => setIsRestorePullRequestModalOpen(true)}
                      variant="secondary"
                      size="xs"
                      className="mt-4 ml-12"
                    >
                      Restore this pull request
                    </Button>
                    <RestorePullRequestModal
                      open={isRestorePullRequestModalOpen}
                      setOpen={setIsRestorePullRequestModalOpen}
                      onRestore={async () => {
                        try {
                          await onRestoreGeneratePullRequestRun(
                            task.id,
                            fullSelectedGeneratePullRequestRun?.id,
                          );
                          // refetch task
                          refetchTask();
                        } catch (error) {
                          console.error(error);
                          showNotification({
                            title: "Error restoring pull request",
                            message: error.message,
                            type: "error",
                          });
                        }
                      }}
                      pullRequestUrlToClose={
                        task.activeGeneratePullRequestRun?.activePullRequest?.externalUrl
                      }
                      pullRequestUrlToReopen={
                        fullSelectedGeneratePullRequestRun?.activePullRequest?.externalUrl
                      }
                    />
                  </>
                )} */}
            </>
          )}
        </>
        <>
          {map(addressCodeReviewRuns, (addressCodeReviewRun, addressCodeReviewRunIndex) => {
            const fullAddressCodeReviewRun = find(
              fullSelectedGeneratePullRequestRun?.activePullRequest?.allAddressCodeReviewRuns,
              (run) => run.id === addressCodeReviewRun.id,
            );
            return (
              <div key={addressCodeReviewRun.id}>
                <div
                  className={clsx("mt-8 -ml-4 cursor-pointer", "font-medium")}
                  onClick={() =>
                    setVisibleAddressingCodeReviewAgentLogs(
                      visibleAddressingCodeReviewAgentLogs.includes(addressCodeReviewRun.id)
                        ? visibleAddressingCodeReviewAgentLogs.filter(
                            (id) => id !== addressCodeReviewRun.id,
                          )
                        : [...visibleAddressingCodeReviewAgentLogs, addressCodeReviewRun.id],
                    )
                  }
                >
                  <div className="flex flex-row items-center">
                    <h3 className="text-md font-medium inline-block">
                      {`Addressing ${getNumberWithOrdinal(
                        addressCodeReviewRunIndex + 1,
                      )} code review`}
                    </h3>
                    {addressCodeReviewRun.status && (
                      <span
                        className={clsx(
                          "ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
                          {
                            "bg-purple-50 text-purple-700 ring-purple-600/20": includes(
                              [AddressCodeReviewRunStatus.Running],
                              addressCodeReviewRun.status,
                            ),
                            "bg-neutral-50 text-neutral-700 ring-neutral-600/20": includes(
                              [
                                AddressCodeReviewRunStatus.SelfFailure,
                                AddressCodeReviewRunStatus.InternalFailure,
                              ],
                              addressCodeReviewRun.status,
                            ),
                            "bg-yellow-50 text-yellow-700 ring-yellow-600/20": includes(
                              [AddressCodeReviewRunStatus.Pending],
                              addressCodeReviewRun.status,
                            ),
                            "bg-green-50 text-green-700 ring-green-600/20": includes(
                              [AddressCodeReviewRunStatus.Completed],
                              addressCodeReviewRun.status,
                            ),
                          },
                        )}
                      >
                        {join(split(addressCodeReviewRun.status, "_"), " ")}
                      </span>
                    )}
                    <div
                      className="flex items-center ml-4 mr-2"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <IconTooltip
                        icon={
                          fullAddressCodeReviewRun?.notesMetadata?.userNote
                            ? PencilSquareIconSolid
                            : PencilSquareIconOutline
                        }
                        placement="top"
                        onClick={() => {
                          setRunNotesModalData({
                            currentNote: fullAddressCodeReviewRun?.notesMetadata?.userNote,
                            addressCodeReviewRunId: addressCodeReviewRun.id,
                            gprrAttempt: getGprrAttempt(selectedGeneratePullRequestRunId),
                            acrrAttempt: addressCodeReviewRunIndex + 1,
                            updateNote: async (note: string) => {
                              try {
                                await onUpdateAddressCodeReviewRun(addressCodeReviewRun.id, {
                                  notesMetadata: {
                                    userNote: note,
                                  },
                                });
                              } catch (error) {
                                console.error(error);
                                showNotification({
                                  title: "Error updating note",
                                  message: error.message,
                                  type: "error",
                                });
                              }
                              await refetchTask();
                              setIsRunNotesModalOpen(false);
                            },
                          });
                          setIsRunNotesModalOpen(true);
                        }}
                        iconClassName={clsx(
                          fullAddressCodeReviewRun?.notesMetadata?.userNote
                            ? "text-gray-900"
                            : "text-gray-300",
                        )}
                        tooltipText={fullAddressCodeReviewRun?.notesMetadata?.userNote || undefined}
                      />
                    </div>
                    <div>
                      {visibleAddressingCodeReviewAgentLogs.includes(addressCodeReviewRun.id) ? (
                        <ChevronDownIcon
                          className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                          aria-hidden="true"
                        />
                      ) : (
                        <ChevronRightIcon
                          className="ml-1 mb-0.5 h-6 w-6 text-gray-400 inline-block"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {visibleAddressingCodeReviewAgentLogs.includes(addressCodeReviewRun.id) && (
                  <>
                    {addressCodeReviewRun.status !== AddressCodeReviewRunStatus.Running &&
                    size(addressCodeReviewRun.logs || []) === 0 ? (
                      <div className="mt-4 text-sm text-gray-500">
                        <p>There are no code review logs yet.</p>
                      </div>
                    ) : (
                      <>
                        <AgentLogsList
                          agentLogs={addressCodeReviewRun.logs || []}
                          isRunning={
                            addressCodeReviewRun.status === AddressCodeReviewRunStatus.Running
                          }
                          toolMap={codeToolNameMap}
                          isLatestRun={addressCodeReviewRun.id === latestRunId}
                        />
                        {/* Only show replicate button if GPRR has initialBaseCommitSha and ACRR has finalCommitSha */}
                        {fullSelectedGeneratePullRequestRun?.initialBaseCommitSha &&
                          fullAddressCodeReviewRun?.finalCommitSha && (
                            <>
                              <Button
                                onClick={() => {
                                  setReplicateToNewPullRequestModalData({
                                    targetAddressCodeReviewRunId: addressCodeReviewRun.id,
                                    pullRequestUrlToClose:
                                      task.activeGeneratePullRequestRun?.activePullRequest
                                        ?.externalUrl,
                                    diffUrl: `https://github.com/${task.repo.ownerName}/${task.repo.name}/compare/${task.repo.defaultBranch}...${fullAddressCodeReviewRun.finalCommitSha}`,
                                    targetCommitSha: fullAddressCodeReviewRun.finalCommitSha,
                                    gprrAttempt:
                                      findIndex(
                                        generatePullRequestRunOptions,
                                        (run) => run.id === fullSelectedGeneratePullRequestRun.id,
                                      ) + 1,
                                    acrrAttempt: addressCodeReviewRunIndex + 1,
                                  });
                                  setIsReplicateToNewPullRequestModalOpen(true);
                                }}
                                variant="secondary"
                                size="xs"
                                className="mt-4 ml-12"
                                disabled={isTaskRunning(taskStatus)}
                              >
                                Replicate to new PR
                              </Button>
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </>
      </div>
      <ImageModal
        modalOpen={isImageModalOpen}
        setModalOpen={setIsImageModalOpen}
        imageUrl={imageModalUrl}
      />
      <VideoModal
        modalOpen={isVideoModalOpen}
        setModalOpen={setIsVideoModalOpen}
        videoUrl={videoUrl}
      />
      <DeleteTaskModal
        taskTitle={taskToDelete?.metadata?.title}
        open={isDeleteTaskModalOpen}
        setOpen={setIsDeleteTaskModalOpen}
        onDelete={async () => {
          await deleteTask(taskToDelete.id);
          await deleteRefreshTask();
          setIsDeleteTaskModalOpen(false);
          setTaskToDelete(null);
          showNotification({
            title: "Issue deleted 🗑️",
            message: "The issue has been successfully deleted.",
          });
        }}
      />
      <RetryGenerationModal
        task={retryGenerationTask}
        open={isRetryGenerationModalOpen}
        setOpen={setIsRetryGenerationModalOpen}
        onRetry={async (additionalContext?: string) => {
          await retryTask(retryGenerationTask.id, additionalContext);
          await refetchTask();
          showNotification({
            title: "Code generation in progress ⚙️",
            message:
              "Tusk is now regenerating the pull request. You can view the pull request once it is complete.",
          });
          setIsRetryGenerationModalOpen(false);
          setRetryGenerationTask(null);
        }}
      />
      {replicateToNewPullRequestModalData && (
        <ReplicateToNewPullRequestModal
          open={isReplicateToNewPullRequestModalOpen}
          setOpen={setIsReplicateToNewPullRequestModalOpen}
          pullRequestUrlToClose={replicateToNewPullRequestModalData.pullRequestUrlToClose}
          diffUrl={replicateToNewPullRequestModalData.diffUrl}
          targetCommitSha={replicateToNewPullRequestModalData.targetCommitSha}
          gprrAttempt={replicateToNewPullRequestModalData.gprrAttempt}
          acrrAttempt={replicateToNewPullRequestModalData.acrrAttempt}
          onReplicate={async () => {
            try {
              await onReplicateUserRequestedRun({
                taskId: task.id,
                targetGeneratePullRequestRunId:
                  replicateToNewPullRequestModalData.targetGeneratePullRequestRunId,
                targetAddressCodeReviewRunId:
                  replicateToNewPullRequestModalData.targetAddressCodeReviewRunId,
              });
            } catch (error) {
              console.error(error);
              showNotification({
                title: "Error replicating to new pull request",
                message: `${error.message}. Pleaseontact support if this persists.`,
                type: "error",
              });
              setIsReplicateToNewPullRequestModalOpen(false);
              setReplicateToNewPullRequestModalData(null);
              return;
            }
            await refetchTask();
            showNotification({
              title: "Replicated to new pull request ✅",
              message: "The current attempt reflects this new PR.",
            });
            setIsReplicateToNewPullRequestModalOpen(false);
            setReplicateToNewPullRequestModalData(null);
          }}
        />
      )}
      {runNotesModalData && (
        <RunNotesModal
          key={
            runNotesModalData.generatePullRequestRunId + runNotesModalData.addressCodeReviewRunId
          }
          isOpen={isRunNotesModalOpen}
          onClose={() => setIsRunNotesModalOpen(false)}
          currentNote={runNotesModalData.currentNote}
          onUpdateNote={runNotesModalData.updateNote}
          gprrAttempt={runNotesModalData.gprrAttempt}
          acrrAttempt={runNotesModalData.acrrAttempt}
        />
      )}
    </>
  );
};
