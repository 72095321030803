import { ConnectExternalResource } from "../../components";
import { GetResourcesQuery } from "../../__generatedGQL__/graphql";
import { useNavigate } from "react-router-dom";

export const GitlabAuth = ({ resource }: { resource?: GetResourcesQuery["resources"][0] }) => {
  const navigate = useNavigate();
  const gitlabEnabled = !!resource;

  return (
    <ConnectExternalResource
      authed={gitlabEnabled}
      onConnect={() => navigate("/app/settings/connect-gitlab")}
      resourceName="GitLab"
      resourceLogo="https://cdn.brandfetch.io/idw382nG0m/w/800/h/763/theme/dark/symbol.png?c=1dxbfHSJFAPEGdCLU4o5B"
      resourceDescription={"Enable automated tests on repos"}
    />
  );
};
