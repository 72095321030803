import { useAppContext } from "../providers";
import { Client } from "../__generatedGQL__/graphql";
import { Button } from "../components";
import { FREE_PLAN, FREE_TRIAL_PLAN, getMaxSyncedRepos, getPlanName } from "./SubscriptionSettings";
import { useMemo } from "react";

export const STARTER_PLAN = "Starter";
export const TEAM_PLAN = "Team";
export const ENTERPRISE_PLAN = "Enterprise";
export const BILLING_URL = "https://billing.stripe.com/p/login/5kAg1T46ucm27wkfYY";

const getPlanFeatures = (subscriptionPlan: Client["subscriptionPlan"]) => {
  // Get the plan name if it's an object, otherwise use the plan string directly
  const repoCount = getMaxSyncedRepos(subscriptionPlan);
  const repoText = repoCount === 1 ? "1 synced repository" : `${repoCount} synced repositories`;

  switch (subscriptionPlan?.planName) {
    case STARTER_PLAN:
      return [repoText, "Test generation in PR/MR", "Custom testing guidelines", "Email support"];
    case TEAM_PLAN:
      return [
        repoText,
        "Test generation in PR/MR",
        "Ability to self-run tests",
        "Auto-iteration based on CI",
        "Custom testing guidelines",
        "VIP support via Slack",
      ];
    case ENTERPRISE_PLAN:
      return [
        repoText,
        "Everything in Team plan",
        "Backfill tests for existing files",
        "Self-hosting available",
        "SAML/SSO available",
        "Custom workflows",
        "White-glove implementation",
      ];
    default:
      return [repoText];
  }
};

export const BillingSettings = () => {
  const { selectedClient, user } = useAppContext();

  const { canManageStripeSubscription, managePlanDisabledTooltip } = useMemo(() => {
    let canManageStripeSubscription = false;
    let managePlanDisabledTooltip: string | undefined = undefined;

    if (selectedClient?.subscriptionPlan?.stripeSubscriptionEmail) {
      if (selectedClient?.subscriptionPlan?.stripeSubscriptionEmail === user?.email) {
        canManageStripeSubscription = true;
      } else {
        managePlanDisabledTooltip = `Contact ${selectedClient?.subscriptionPlan?.stripeSubscriptionEmail} to manage your plan`;
      }
    } else {
      managePlanDisabledTooltip = "Contact support@usetusk.ai to manage your plan";
    }
    return { canManageStripeSubscription, managePlanDisabledTooltip };
  }, [selectedClient, user]);

  const isFreePlan =
    !selectedClient?.subscriptionPlan?.planName ||
    selectedClient.subscriptionPlan.planName === FREE_PLAN ||
    selectedClient.subscriptionPlan.planName === FREE_TRIAL_PLAN;

  const getBillingUrl = () => {
    // For free plan users, return the Stripe payment link
    if (isFreePlan) {
      return "https://buy.stripe.com/fZe03Y2o8dYW8WkeVm";
    }

    // For existing paid subscribers, return the billing portal URL with email param
    const params = new URLSearchParams();
    if (user?.email) {
      params.append("prefilled_email", user.email);
    }
    return `${BILLING_URL}${params.toString() ? `?${params.toString()}` : ""}`;
  };

  // Update the button text based on plan
  const getButtonText = () => {
    if (isFreePlan) {
      return "Subscribe";
    }
    return "Manage plan";
  };

  return (
    <div className="space-y-8 sm:space-y-10">
      <div>
        <div className="pb-6 border-b border-gray-100">
          <h2 className="text-lg font-semibold leading-7 text-gray-900">Billing</h2>
          <p className="mt-1 text-sm leading-6 text-gray-600">
            View and manage billing details for your organization.
          </p>
        </div>
        <div className="mt-8 bg-white border border-gray-300 drop-shadow-xs sm:rounded-lg max-w-2xl">
          <div className="px-6 py-6">
            <div className="flex justify-between items-start">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {getPlanName(selectedClient?.subscriptionPlan)}
              </h3>
              <Button
                disabled={!isFreePlan && !canManageStripeSubscription}
                link={getBillingUrl()}
                tooltipText={isFreePlan ? undefined : managePlanDisabledTooltip}
                tooltipClassName="w-64"
              >
                {getButtonText()}
              </Button>
            </div>
            <div className="mt-2">
              <div className="max-w-xxl text-sm text-gray-500">
                <p>Your organization has access to:</p>
                <ul className="mt-2 list-disc list-inside">
                  {getPlanFeatures(selectedClient?.subscriptionPlan).map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
