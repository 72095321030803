// @ts-nocheck

import { find, isEmpty } from "lodash";
import { useQuery } from "@apollo/client";

import {
  CopyText,
  LoadingSpinner,
  IconTooltip,
  ScheduleOnboardingModal,
  NonAuthExternalResource,
  ErrorPage,
} from "../components";
import {
  JiraAuth,
  LinearAuth,
  GithubAuth,
  NotionAuth,
  FigmaAuth,
  GithubIssuesIntegration,
} from "./integrations-auth";

import { gql } from "../__generatedGQL__/gql";
import { ResourceType } from "../__generatedGQL__/graphql";
import { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../providers";
import { useExternalResourcesHook } from "../hooks/useExternalResourcesHook";
import { InformationBanner } from "../components";
import { getSlackChannelUrl } from "../utils/clientUtils";
import { GitlabAuth } from "./integrations-auth/GitlabAuth";

export const GET_RESOURCES = gql(`
  query GetResources {
    resources {
      id
      externalId
      type
      userId
      createdAt
      updatedAt
      config
    }
  }
`);

export const IntegrationSettings = () => {
  const { selectedClientId, selectedClient, user, showOldTuskUI } = useAppContext();
  const { loading, error, data, refetch } = useQuery(GET_RESOURCES);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const githubResource = find(data?.resources, { type: ResourceType.Github });
  const gitlabResource = find(data?.resources, { type: ResourceType.Gitlab });
  const linearResource = find(data?.resources, { type: ResourceType.Linear });
  const jiraResource = find(data?.resources, { type: ResourceType.Jira });
  const notionResource = find(data?.resources, { type: ResourceType.Notion });
  const figmaResource = find(data?.resources, { type: ResourceType.Figma });

  useEffect(() => {
    /**
     * User by default is dropped in DashboardPage. We get redirected to IntegrationSettings from DashboardPage and show onboarding modal if both are true:
     * - User doesn't have GitHub or GitLab connected
     * - Onboarding modal hasn't been shown yet for this session
     */

    const sessionStorageKey = `${selectedClientId}.${user?.id}.shownOnboardingModal`;
    const shownOnboardingModal = sessionStorage.getItem(sessionStorageKey);
    if (!loading && !githubResource && !gitlabResource && !shownOnboardingModal) {
      sessionStorage.setItem(`${selectedClientId}.${user?.id}.shownOnboardingModal`, "true");
      setOnboardingModalOpen(true);
    }
  }, [loading, githubResource, gitlabResource]);

  const { isExternalTicketingConnected, loading: loadingExternalResources } =
    useExternalResourcesHook();

  const integrationsSubheader = useMemo(() => {
    const isGithubConnected = !!githubResource;
    const isGitlabConnected = !!gitlabResource;
    const isNotionConnected = !!notionResource;

    if (showOldTuskUI) {
      if (!isGithubConnected) {
        return (
          <InformationBanner>
            Connect Tusk to your <span className="font-semibold">GitHub</span> to start creating
            issues. Learn more about{" "}
            <a
              href="https://docs.usetusk.ai/ui-fixes/onboarding"
              className="text-purple-600 hover:text-purple-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              syncing repos
            </a>{" "}
            and our{" "}
            <a
              href="https://docs.usetusk.ai/automated-tests/security-policy"
              className="text-purple-600 hover:text-purple-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              security practices
            </a>
            .
          </InformationBanner>
        );
      } else if (!isExternalTicketingConnected) {
        return (
          <InformationBanner>
            Connect Tusk to your <span className="font-semibold">project management app</span> to
            surface issues and assign them to Tusk. For help, read{" "}
            <a
              href="https://docs.usetusk.ai/ui-fixes/ticketing-integrations"
              className="text-purple-600 hover:text-purple-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              this doc{" "}
            </a>
            or{" "}
            {selectedClient.customerSlackChannelId ? (
              <a
                href={getSlackChannelUrl(selectedClient.customerSlackChannelId)}
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                reach out to us
              </a>
            ) : (
              <>
                reach out to <CopyText text="support@usetusk.ai" type="email" />
              </>
            )}
            .
          </InformationBanner>
        );
      } else if (isNotionConnected && isEmpty(notionResource.config?.databases)) {
        return (
          <InformationBanner>
            Please{" "}
            {selectedClient.customerSlackChannelId ? (
              <a
                href={getSlackChannelUrl(selectedClient.customerSlackChannelId)}
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                reach out to us
              </a>
            ) : (
              <>
                contact <CopyText text="support@usetusk.ai" type="email" />
              </>
            )}{" "}
            to finish setting up the <span className="font-semibold">Notion integration</span>.
          </InformationBanner>
        );
      } else {
        if (!isGithubConnected && !isGitlabConnected) {
          return (
            <InformationBanner>
              Connect Tusk to your <span className="font-semibold">GitHub</span> or{" "}
              <span className="font-semibold">GitLab</span> to enable automated tests on your PRs.
              Learn more about{" "}
              <a
                href="https://docs.usetusk.ai/automated-tests/onboarding"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                syncing repos
              </a>{" "}
              and our{" "}
              <a
                href="https://docs.usetusk.ai/automated-tests/security-policy"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                security practices
              </a>
              .
            </InformationBanner>
          );
        } else {
          return (
            <p className="mt-2 text-sm leading-6 text-gray-600">
              Connect Tusk to your workplace apps. For help, read{" "}
              <a
                href="https://docs.usetusk.ai/automated-tests/onboarding"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                this doc
              </a>{" "}
              or{" "}
              {selectedClient.customerSlackChannelId ? (
                <a
                  href={getSlackChannelUrl(selectedClient.customerSlackChannelId)}
                  className="text-purple-600 hover:text-purple-800"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  reach out to us
                </a>
              ) : (
                <>
                  contact <CopyText text="support@usetusk.ai" type="email" />
                </>
              )}
              .
            </p>
          );
        }
      }
    } else {
      if (!isGithubConnected && !isGitlabConnected) {
        return (
          <InformationBanner>
            Connect Tusk to your <span className="font-semibold">GitHub</span> or{" "}
            <span className="font-semibold">GitLab</span> to enable automated tests on your PRs.
            Learn more about{" "}
            <a
              href="https://docs.usetusk.ai/automated-tests/onboarding"
              className="text-purple-600 hover:text-purple-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              syncing repos
            </a>{" "}
            and our{" "}
            <a
              href="https://docs.usetusk.ai/automated-tests/security-policy"
              className="text-purple-600 hover:text-purple-800"
              target="_blank"
              rel="noopener noreferrer"
            >
              security practices
            </a>
            .
          </InformationBanner>
        );
      } else if (!isExternalTicketingConnected) {
        return (
          <InformationBanner>
            Connect Tusk to your <span className="font-semibold">project management app</span> so it
            has additional context about the expected behavior of your code. For help, please{" "}
            {selectedClient.customerSlackChannelId ? (
              <a
                href={getSlackChannelUrl(selectedClient.customerSlackChannelId)}
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                reach out to us
              </a>
            ) : (
              <>
                reach out to <CopyText text="support@usetusk.ai" type="email" />
              </>
            )}
            .
          </InformationBanner>
        );
      }
    }
  }, [
    githubResource,
    gitlabResource,
    linearResource,
    jiraResource,
    notionResource,
    isExternalTicketingConnected,
    showOldTuskUI,
    selectedClient,
  ]);

  if (loading || loadingExternalResources) return <LoadingSpinner />;
  if (error) {
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading integrations"
        errorDescription={error.message}
      />
    );
  }

  return (
    <>
      <div className="space-y-8 sm:space-y-8">
        <div>
          <h2 className="text-lg font-semibold leading-7 text-gray-900">Integrations</h2>
          {integrationsSubheader}
          <div className="mt-4 border-t border-gray-100">
            <div className="divide-y divide-gray-100" />
            <div className="mt-4 text-md font-semibold leading-6 text-gray-900">
              Code hosting platform
            </div>
            <div className="flex flex-wrap justify-start gap-4 mt-4">
              <GithubAuth resource={githubResource} />
              {!showOldTuskUI && <GitlabAuth resource={gitlabResource} />}
            </div>
          </div>
          <div className="mt-4 border-t border-gray-100">
            <div className="divide-y divide-gray-100" />
            <div className="flex items-center mt-4 text-md font-semibold leading-6 text-gray-900">
              Project management platform
              {showOldTuskUI && (
                <IconTooltip tooltipText="Once connected, you can add the 'Tusk' label to a ticket to generate a pull request. Our AI will also auto-surface tickets that it can complete." />
              )}
              {!showOldTuskUI && (
                <IconTooltip tooltipText="Once connected, Tusk analyzes your tickets linked to your pull requests to write more accurate and relevant tests." />
              )}
            </div>
            <div className="flex flex-wrap justify-start gap-4 mt-4">
              <LinearAuth resource={linearResource} />
              <JiraAuth resource={jiraResource} />
              {showOldTuskUI && (
                <>
                  <NotionAuth resource={notionResource} />
                  <GithubIssuesIntegration
                    resource={githubResource}
                    refetchResources={async () => {
                      await refetch();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {showOldTuskUI && (
          <div className="mt-4 border-t border-gray-100">
            <div className="divide-y divide-gray-100" />
            <div className="flex items-center mt-4 text-md font-semibold leading-6 text-gray-900">
              External link integrations
              <IconTooltip tooltipText="Connect other integrations that are referred to in your tickets to provide more context." />
            </div>
            <div className="flex flex-wrap justify-start gap-4 mt-4">
              <FigmaAuth resource={figmaResource} />
              <NonAuthExternalResource
                resourceName="Jam"
                resourceLogo="https://storage.googleapis.com/use-tusk-dev-static/jam-logo.jpeg"
                resourceDescription="Extract information from Jam links"
                tooltipText="Tusk automatically extracts information from public Jam links"
              />
              <NonAuthExternalResource
                resourceName="Bird Eats Bug"
                resourceLogo="https://storage.googleapis.com/use-tusk-dev-static/birdeatsbug-logo.svg"
                resourceDescription="Extract information from BEB links"
                tooltipText="Tusk automatically extracts information from public Bird Eats Bug links"
              />
              <NonAuthExternalResource
                resourceName="Loom"
                resourceLogo="https://asset.brandfetch.io/iddLCZGlbs/id3sYlMdzs.png"
                resourceDescription="Extract information from Loom videos"
                tooltipText="Tusk automatically extracts information from public Loom videos"
              />
              <NonAuthExternalResource
                resourceName="GitHub"
                resourceLogo="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png"
                resourceDescription="Extract information from GitHub links"
                tooltipText="Tusk automatically extracts information from public and private (if authed) GitHub links"
              />
            </div>
          </div>
        )}
      </div>
      <ScheduleOnboardingModal open={onboardingModalOpen} setOpen={setOnboardingModalOpen} />
    </>
  );
};
