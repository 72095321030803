import { Fragment } from "react";
import { clsx } from "clsx";
import { Menu, Transition } from "@headlessui/react";
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  HeartIcon,
  PencilSquareIcon,
  TrashIcon,
  UserPlusIcon,
  EllipsisVerticalIcon,
  CodeBracketIcon,
  ComputerDesktopIcon,
  ArrowPathRoundedSquareIcon,
} from "@heroicons/react/20/solid";
import { isFunction } from "lodash";

export const IssueOptions = ({
  viewAgentLogs,
  viewPullRequest,
  viewBranch,
  viewTestingVideo,
  retryGenerationDisabled,
  openRetryGenerationModal,
  retryTestingDisabled,
  retryTesting,
  openDeleteTaskModal,
  createPullRequestDisabled,
  createPullRequest,
  position = "center",
}: {
  viewAgentLogs?: () => void;
  viewPullRequest?: () => void;
  viewBranch?: () => void;
  viewTestingVideo?: () => void;
  retryGenerationDisabled?: boolean;
  openRetryGenerationModal?: () => void;
  retryTestingDisabled?: boolean;
  retryTesting?: () => Promise<void>;
  openDeleteTaskModal?: () => void;
  createPullRequest?: () => Promise<void>;
  createPullRequestDisabled?: boolean;
  position?: "top" | "center" | "bottom";
}) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={clsx(
            "flex justify-center items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-hidden focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100",
            // { 'opacity-50 cursor-not-allowed': disabled }  // Apply styles when disabled
          )}
          // disabled={disabled}
        >
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            "absolute z-50 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-hidden",
            {
              "left-0 top-0 transform -translate-x-full": position === "top",
              "left-0 top-1/2 transform -translate-x-full -translate-y-1/2": position === "center",
              "left-0 bottom-0 transform -translate-x-full": position === "bottom",
            },
          )}
        >
          <div className="py-1">
            {isFunction(viewAgentLogs) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={viewAgentLogs}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                    )}
                  >
                    <ArchiveBoxIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    View activity log
                  </a>
                )}
              </Menu.Item>
            )}
            {isFunction(viewPullRequest) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={viewPullRequest}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                    )}
                  >
                    <CodeBracketIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    View pull request
                  </a>
                )}
              </Menu.Item>
            )}
            {isFunction(viewBranch) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={viewBranch}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                    )}
                  >
                    <CodeBracketIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    View branch
                  </a>
                )}
              </Menu.Item>
            )}
            {isFunction(viewTestingVideo) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={viewTestingVideo}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                    )}
                  >
                    <ComputerDesktopIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    View testing video
                  </a>
                )}
              </Menu.Item>
            )}
            {isFunction(openRetryGenerationModal) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => {
                      if (!retryGenerationDisabled) openRetryGenerationModal();
                    }}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                      { "opacity-50 cursor-not-allowed": retryGenerationDisabled },
                    )}
                    aria-disabled={retryGenerationDisabled}
                  >
                    <ArrowPathRoundedSquareIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Retry generation
                  </a>
                )}
              </Menu.Item>
            )}
            {isFunction(retryTesting) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={async () => {
                      if (!retryTestingDisabled) await retryTesting();
                    }}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                      { "opacity-50 cursor-not-allowed": retryTestingDisabled },
                    )}
                    aria-disabled={retryTestingDisabled}
                  >
                    <ArrowPathRoundedSquareIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Retry testing
                  </a>
                )}
              </Menu.Item>
            )}
            {isFunction(openDeleteTaskModal) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={openDeleteTaskModal}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                    )}
                  >
                    <TrashIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete issue
                  </a>
                )}
              </Menu.Item>
            )}
            {isFunction(createPullRequest) && (
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={async () => {
                      if (!createPullRequestDisabled) await createPullRequest();
                    }}
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900 cursor-pointer" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm",
                      { "opacity-50 cursor-not-allowed": createPullRequestDisabled },
                    )}
                    aria-disabled={createPullRequestDisabled}
                  >
                    <CodeBracketIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Create pull request
                  </a>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
