// @ts-nocheck

import { Fragment, useState, useEffect, useRef, useMemo } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  BookOpenIcon,
  SparklesIcon,
  NewspaperIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { clsx } from "clsx";
import { size, map, includes, some, find, compact } from "lodash";
import { usePostHog } from "posthog-js/react";

import { TuskLogo } from "./TuskLogo";
import { TuskTLogo } from "./TuskTLogo";
import { useAppContext } from "../providers";
import { AvatarButtonGroup } from "./AvatarButtonGroup";
import { MARKETING_WEB_URL } from "../utils/environment";

export const getInitials = (name: string): string => {
  return name
    .split(" ")
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
};

export const getInitialsColor = (name: string): string => {
  const initials = getInitials(name);
  const colors = [
    "bg-red-500",
    "bg-orange-500",
    "bg-yellow-500",
    "bg-lime-500",
    "bg-emerald-500",
    "bg-cyan-500",
    "bg-blue-500",
    "bg-violet-500",
    "bg-fuchsia-500",
    "bg-pink-500",
  ];
  const color = colors[(initials.charCodeAt(0) || 0 + initials.charCodeAt(1) || 0) % colors.length];
  return color;
};

export const AppSidebar = ({ children }) => {
  const { showOldTuskUI } = useAppContext();
  const location = useLocation();
  const { logout: logoutAuth0 } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAvatarMenuVisible, setIsAvatarMenuVisible] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
  const menuRef = useRef(null);
  const initialsButtonRef = useRef(null);

  const navigation = useMemo(() => {
    return compact([
      showOldTuskUI && {
        name: "Dashboard",
        path: "/app/dashboard",
        additionalPaths: [],
        icon: HomeIcon,
      },
      showOldTuskUI && {
        name: "Suggested Issues",
        path: "/app/suggested-issues",
        additionalPaths: [],
        icon: SparklesIcon,
      },
      {
        name: "Settings",
        path: "/app/settings/integrations",
        additionalPaths: [
          "/app/settings/repos",
          "/app/settings/subscription",
          "/app/settings/integrations",
          "/app/settings/team",
          "/app/settings/billing",
          "/app/settings/seats",
          "/app/settings/customization",
        ],
        icon: Cog6ToothIcon,
      },
      {
        name: "Documentation",
        icon: BookOpenIcon,
        path: showOldTuskUI
          ? "https://docs.usetusk.ai/ui-fixes"
          : "https://docs.usetusk.ai/automated-tests",
        external: true,
      },
    ]);
  }, [showOldTuskUI]);

  const handleClose = () => {
    setIsAvatarMenuVisible(false);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      initialsButtonRef.current &&
      !initialsButtonRef.current.contains(event.target) &&
      !event.target.closest(".avatar-button-group")
    ) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const { user, clients, selectedClientId, setSelectedClientId, isAdmin, toggleAdminView } =
    useAppContext();
  const posthog = usePostHog();

  const logout = () => {
    posthog?.capture("user_logged_out");
    posthog?.reset();
    logoutAuth0({
      logoutParams: {
        returnTo: MARKETING_WEB_URL,
      },
    });
  };

  const buttons = [
    {
      icon: NewspaperIcon,
      text: "What's new",
      onClick: () => window.open("https://blog.usetusk.ai", "_blank"),
    },
    ...(user?.isAdmin
      ? [
          {
            icon: isAdmin ? EyeSlashIcon : EyeIcon,
            text: isAdmin ? "View as non-admin" : "View as admin",
            onClick: toggleAdminView,
          },
        ]
      : []),
    {
      icon: ArrowLeftOnRectangleIcon,
      text: "Log out",
      onClick: logout,
    },
    // Add more buttons as needed
  ];

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  const renderOrganizationItem = (client, isClickable) => (
    <li key={client.id}>
      {isClickable ? (
        <Link
          to="#"
          onClick={(event) => {
            event.preventDefault();
            setSelectedClientId(client.id);
          }}
          className={clsx(
            client.id === selectedClientId
              ? "bg-gray-800 text-white"
              : "text-gray-400 hover:text-white hover:bg-gray-800",
            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
            isSidebarCollapsed ? "justify-center" : "py-2 px-3",
          )}
          aria-current={client.id === selectedClientId ? "page" : undefined}
        >
          {renderOrganizationContent(client)}
        </Link>
      ) : (
        <div
          className={clsx(
            "bg-gray-800 text-white",
            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
            isSidebarCollapsed ? "justify-center" : "py-2 px-3",
          )}
          aria-label={`Current organization: ${client.name}`}
        >
          {renderOrganizationContent(client)}
        </div>
      )}
    </li>
  );

  const renderOrganizationContent = (client) => (
    <>
      <span
        className={clsx(
          "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400",
        )}
        aria-hidden="true"
      >
        {(client.name || "Team")[0]}
      </span>
      {!isSidebarCollapsed && (
        <span className="truncate">
          {client.name}
          {!includes(map(user.clients, "id"), client.id) ? " (Admin)" : ""}
        </span>
      )}
    </>
  );

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex pt-6 h-16 shrink-0 items-center">
                      <div className="w-auto ml-0.5" style={{ width: "110px" }}>
                        <a href={MARKETING_WEB_URL} target="_blank" aria-label="Home">
                          <TuskLogo />
                        </a>
                      </div>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) =>
                              item.external ? (
                                <a
                                  key={item.name}
                                  href={item.path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={`group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800 ${
                                    isSidebarCollapsed ? "justify-center" : "ml-1"
                                  }`}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {!isSidebarCollapsed && <span>{item.name}</span>}
                                </a>
                              ) : (
                                <Link
                                  key={item.name}
                                  to={item.path}
                                  className={clsx(
                                    some(
                                      map([item.path, ...item.additionalPaths], (path) =>
                                        location.pathname.startsWith(path),
                                      ),
                                    )
                                      ? "bg-gray-800 text-white"
                                      : "text-gray-400 hover:text-white hover:bg-gray-800",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",
                                    isSidebarCollapsed ? "justify-center" : "ml-1",
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {!isSidebarCollapsed && <span>{item.name}</span>}
                                </Link>
                              ),
                            )}
                          </ul>
                        </li>
                        <li>
                          <div className="ml-1 text-xs font-semibold leading-6 text-gray-400">
                            Your organizations
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {clients && clients.length > 0 ? (
                              map(clients, (client) =>
                                renderOrganizationItem(client, size(clients) > 1),
                              )
                            ) : (
                              <li>No organizations available</li>
                            )}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={`hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:flex-col ${
            isSidebarCollapsed ? "lg:w-20" : "lg:w-72"
          } transition-all duration-300 ease-in-out`}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 relative">
            <div className="flex items-center pt-6 h-16">
              <div
                className={`transition-all duration-300 ease-in-out ${
                  isSidebarCollapsed ? "w-0 opacity-0" : "w-[110px] opacity-100 ml-0.5"
                }`}
              >
                <a href={MARKETING_WEB_URL} target="_blank" aria-label="Home">
                  <TuskLogo />
                </a>
              </div>
              <div
                className={`transition-all duration-300 ease-in-out ${
                  isSidebarCollapsed ? "w-7 opacity-100 ml-0.5" : "w-0 opacity-0"
                }`}
              >
                <a href={MARKETING_WEB_URL} target="_blank" aria-label="Home">
                  <TuskTLogo className="w-7" />
                </a>
              </div>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) =>
                      item.external ? (
                        <a
                          key={item.name}
                          href={item.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="group flex gap-x-3 items-center rounded-md p-2 text-sm leading-6 font-semibold text-gray-400 hover:text-white hover:bg-gray-800"
                        >
                          <div
                            className={`flex items-center ${
                              isSidebarCollapsed ? "w-full justify-center" : "ml-1"
                            }`}
                          >
                            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          </div>
                          <span className={`${isSidebarCollapsed ? "hidden" : "block"} `}>
                            {item.name}
                          </span>
                        </a>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.path}
                          className={clsx(
                            some(
                              map([item.path, ...item.additionalPaths], (path) =>
                                location.pathname.startsWith(path),
                              ),
                            )
                              ? "bg-gray-800 text-white"
                              : "text-gray-400 hover:text-white hover:bg-gray-800",
                            "group flex gap-x-3 items-center rounded-md p-2 text-sm leading-6 font-semibold",
                          )}
                        >
                          <div
                            className={`flex items-center ${
                              isSidebarCollapsed ? "w-full justify-center" : "ml-1"
                            }`}
                          >
                            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                          </div>
                          <span
                            className={`${
                              isSidebarCollapsed ? "hidden" : "block"
                            } whitespace-nowrap`}
                          >
                            {item.name}
                          </span>
                        </Link>
                      ),
                    )}
                  </ul>
                </li>
                <li className="relative">
                  <div
                    className={clsx(
                      "ml-1 text-xs font-semibold leading-6 text-gray-400 transition-all duration-300 ease-in-out",
                      isSidebarCollapsed
                        ? "opacity-0 max-h-0 overflow-hidden"
                        : "opacity-100 max-h-6 whitespace-nowrap",
                    )}
                  >
                    Your organizations
                  </div>
                  <ul
                    role="list"
                    className={clsx(
                      "-mx-2 mt-2 space-y-1 transition-all duration-300 ease-in-out",
                      isSidebarCollapsed ? "mt-8" : "mt-2",
                    )}
                  >
                    {map(clients, (client) => renderOrganizationItem(client, size(clients) > 1))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          {/* Move the AvatarButtonGroup outside of the sidebar */}
          <div className="fixed bottom-0 left-0">
            <div className="relative" ref={menuRef}>
              <button
                ref={initialsButtonRef}
                onClick={() => setIsAvatarMenuVisible(!isAvatarMenuVisible)}
                className={`flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-white hover:bg-gray-800 w-full ${
                  isSidebarCollapsed ? "px-6 justify-center" : "px-6"
                }`}
                style={{ width: isSidebarCollapsed ? "80px" : "288px" }}
              >
                <div
                  className={clsx(
                    "h-8 w-8 rounded-full flex items-center justify-center text-white",
                    getInitialsColor(user.name),
                  )}
                  title={user.name}
                  aria-label={`${user.name}'s profile`}
                >
                  {getInitials(user.name)}
                </div>
                {!isSidebarCollapsed && (
                  <>
                    <span className="sr-only">Your profile</span>
                    <span aria-hidden="true">{user.name}</span>
                  </>
                )}
              </button>
              <AvatarButtonGroup
                isVisible={isAvatarMenuVisible}
                buttons={buttons}
                isSidebarCollapsed={isSidebarCollapsed}
              />
            </div>
          </div>
          {/* Add the toggle button here */}
          <button
            onClick={toggleSidebar}
            className="absolute top-1/2 -right-1 transform -translate-y-1/2 p-1 rounded-full bg-gray-800 bg-opacity-80 text-gray-400 hover:bg-gray-700 hover:bg-opacity-90 hover:text-white focus:outline-hidden"
            aria-label={isSidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {isSidebarCollapsed ? (
              <ChevronDoubleRightIcon className="h-3 w-3" aria-hidden="true" />
            ) : (
              <ChevronDoubleLeftIcon className="h-3 w-3" aria-hidden="true" />
            )}
          </button>
        </div>

        <div className="sticky top-0 z-10 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-xs sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-white">
            {find(
              navigation,
              (item) =>
                item.path === location.pathname ||
                includes(item.additionalPaths, location.pathname) ||
                location.pathname.startsWith(item.path),
            )?.name || "Dashboard"}
          </div>
          <div className="relative" ref={menuRef}>
            <button
              onClick={() => setIsAvatarMenuVisible(!isAvatarMenuVisible)}
              className="flex items-center gap-x-4 text-sm font-semibold leading-6 text-white"
            >
              <div
                className={clsx(
                  "h-8 w-8 rounded-full flex items-center justify-center text-white",
                  getInitialsColor(user.name),
                )}
                title={user.name}
              >
                {getInitials(user.name)}
              </div>
            </button>
            <AvatarButtonGroup
              isVisible={isAvatarMenuVisible}
              buttons={buttons}
              isSidebarCollapsed={isSidebarCollapsed}
            />
          </div>
        </div>

        <main
          className={`py-10 ${
            isSidebarCollapsed ? "lg:pl-20" : "lg:pl-72"
          } transition-all duration-300`}
        >
          <div className="px-8 sm:px-10 lg:px-14">{children}</div>
        </main>
      </div>
    </>
  );
};
