import React from "react";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

interface Action {
  icon: React.ReactNode;
  tooltip: string;
  onClick: (e: React.MouseEvent) => void;
}

interface IProps {
  header: React.ReactNode;
  children: React.ReactNode;
  defaultOpen?: boolean;
  includeDivider?: boolean;
  className?: string;
  headerClassName?: string;
  expandedHeaderClassName?: string;
  collapsedHeaderClassName?: string;
  bodyClassName?: string;
  actions?: Action[];
  beforeChevron?: React.ReactNode;
}

export const ExpandableCard = ({
  className,
  header,
  children,
  defaultOpen = false,
  includeDivider = true,
  headerClassName = "",
  expandedHeaderClassName = "",
  collapsedHeaderClassName = "",
  bodyClassName = "",
  actions = [],
  beforeChevron = null,
}: IProps) => {
  const handleActionClick = (e: React.MouseEvent, onClick: (e: React.MouseEvent) => void) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <div
      className={clsx(
        "overflow-hidden rounded-lg bg-white shadow-sm",
        includeDivider && "divide-y divide-gray-200",
        className,
      )}
    >
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton className="w-full">
              <div
                className={clsx(
                  "flex items-center justify-start px-2 py-1.5 sm:px-4 hover:bg-gray-50",
                  headerClassName,
                  open ? expandedHeaderClassName : collapsedHeaderClassName,
                )}
              >
                {beforeChevron && <div className="mr-2">{beforeChevron}</div>}
                <ChevronRightIcon
                  className={`h-4 w-4 text-gray-500 transform transition-transform duration-100 mr-2 ${
                    open ? "rotate-90" : ""
                  }`}
                />
                <div className="flex-1 text-left">{header}</div>

                {actions.length > 0 && (
                  <div className="flex items-center gap-2 ml-4">
                    {actions.map((action, index) => (
                      <div
                        key={index}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.stopPropagation();
                            action.onClick(e as unknown as React.MouseEvent);
                          }
                        }}
                        onClick={(e) => handleActionClick(e, action.onClick)}
                        title={action.tooltip}
                        className="p-1 rounded-sm hover:bg-gray-200 text-gray-500 hover:text-gray-700"
                      >
                        {action.icon}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </DisclosureButton>

            <DisclosurePanel>
              <div className={clsx(bodyClassName ? bodyClassName : `p-4 sm:p-6`)}>{children}</div>
            </DisclosurePanel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
