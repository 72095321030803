import { ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import { clsx } from "clsx";

type TooltipPosition =
  | "right"
  | "left"
  | "top"
  | "bottom"
  | "top-left"
  | "top-right"
  | "bottom-left"
  | "bottom-right";

interface TooltipWrapperProps {
  children: ReactNode;
  tooltipText?: string;
  wrapperClassName?: string;
  tooltipClassName?: string;
  position?: TooltipPosition;
}

const tooltipPositionClasses: Record<TooltipPosition, string> = {
  right: "top-1/2 left-full transform -translate-y-1/2 ml-2",
  left: "top-1/2 right-full transform -translate-y-1/2 mr-2",
  top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
  bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
  "top-left": "bottom-full left-0 mb-2",
  "top-right": "bottom-full right-0 mb-2",
  "bottom-left": "top-full left-0 mt-2",
  "bottom-right": "top-full right-0 mt-2",
};

export const TooltipWrapper = ({
  children,
  tooltipText,
  wrapperClassName,
  tooltipClassName,
  position = "right",
}: TooltipWrapperProps) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div
      className={clsx("relative inline-block", wrapperClassName)}
      onMouseEnter={() => setIsTooltipVisible(true)}
      onMouseLeave={() => setIsTooltipVisible(false)}
    >
      {children}
      {tooltipText && (
        <Transition
          show={isTooltipVisible}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={clsx(
              "absolute z-10 bg-gray-900 text-white text-xs font-normal rounded-sm py-1 px-2",
              tooltipPositionClasses[position],
              position.includes("left") || position.includes("right") ? "w-60" : "w-auto max-w-xs",
              tooltipClassName,
            )}
          >
            {tooltipText}
          </div>
        </Transition>
      )}
    </div>
  );
};
