import clsx from "clsx";
import { ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/20/solid";

export const AlertBanner = ({
  className,
  title,
  message,
  type = "warning",
}: {
  type?: "info" | "warning";
  className?: string;
  title: string;
  message: React.ReactNode | string;
}) => {
  return (
    <div
      className={clsx(
        "rounded-md p-4",
        type === "warning" ? "bg-yellow-50" : "bg-purple-50",
        className,
      )}
    >
      <div className="flex">
        <div className="shrink-0">
          {type === "warning" ? (
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          ) : (
            <InformationCircleIcon className="h-5 w-5 text-purple-400" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3">
          <h3
            className={clsx(
              "text-sm font-medium",
              type === "warning" ? "text-yellow-800" : "text-purple-800",
            )}
          >
            {title}
          </h3>
          <div
            className={clsx(
              "mt-2 text-sm",
              type === "warning" ? "text-yellow-700" : "text-purple-700",
            )}
          >
            {message}
          </div>
        </div>
      </div>
    </div>
  );
};
