import { Fragment, useState } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { ExclamationTriangleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { Link, useNavigate } from "react-router-dom";
import { useNotificationContext } from "../providers/NotificationProvider";
import { useAppContext } from "../providers/AppProvider";

export const RetryTestGenerationModal = ({
  open,
  setOpen,
  onRetry,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onRetry: () => Promise<string>;
}) => {
  const { selectedClientId } = useAppContext();
  const [isRetrying, setIsRetrying] = useState(false);
  const navigate = useNavigate();
  const { showNotification } = useNotificationContext();

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start sm:pr-4">
                  <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-purple-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-purple-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900 flex"
                    >
                      Retry Test Generation
                    </DialogTitle>
                    <div className="mt-4">
                      <p className="text-sm text-gray-500 text-left">
                        You are about to retry test generation for this commit.
                      </p>
                      <ul className="mt-2 text-sm text-gray-500 text-left list-disc pl-5 space-y-1">
                        <li>This creates a new run and may take several minutes to complete.</li>
                        <li>You will be redirected to the new run.</li>
                      </ul>
                      <p className="mt-2 text-sm text-gray-500 text-left">
                        You may also consider updating your{" "}
                        <Link
                          to={`/app/settings/customization`}
                          target="_blank"
                          className="text-purple-600"
                        >
                          repo customization
                        </Link>{" "}
                        to help improve test generation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:pr-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="bg-purple-600 text-white shadow-xs hover:bg-purple-500 sm:ml-3 sm:w-auto"
                    onClick={async () => {
                      setIsRetrying(true);
                      try {
                        const redirectUrl = await onRetry();

                        const cleanRedirectUrl = redirectUrl.startsWith("http")
                          ? new URL(redirectUrl).pathname
                          : redirectUrl;

                        showNotification({
                          type: "success",
                          title: "Test generation retry started",
                          message: "A new test generation run has been initiated successfully.",
                          duration: 5000,
                        });

                        navigate(cleanRedirectUrl);
                      } catch (error) {
                        console.error("Error retrying test generation:", error);
                      } finally {
                        setIsRetrying(false);
                        setOpen(false);
                      }
                    }}
                    loading={isRetrying}
                  >
                    Confirm
                  </Button>
                  <Button variant="secondary" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
