import { createContext, useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  fromPromise,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { useAuth0 } from "@auth0/auth0-react";
import { find, includes, isEmpty, size } from "lodash";
import { usePostHog } from "posthog-js/react";

import { getEnvironment } from "../utils";
import { gql } from "../__generatedGQL__/gql";
import { ClientFeatures, GetUserQuery } from "../__generatedGQL__/graphql";
import { LoadingSpinner, ErrorPage } from "../components";

type IGraphQLTuskClient = NonNullable<GetUserQuery["user"]>["clients"][0];

export interface IAppContext {
  user?: GetUserQuery["user"];
  clients?: IGraphQLTuskClient[];
  selectedClient?: IGraphQLTuskClient;
  selectedClientId?: string;
  setSelectedClientId?: (clientId: string) => void;
  isAuthenticated: boolean;
  isLoadingAppContext: boolean;
  authToken: string;
  showOldTuskUI: boolean;
  allowSelectingTests: boolean;
  /** Whether a user is admin. Use this instead of user?.isAdmin so that we can force non-admin view. */
  isAdmin: boolean;
  toggleAdminView?: () => void;
}

export const AppContext = createContext<IAppContext>({
  isAuthenticated: false,
  isLoadingAppContext: false,
  authToken: "",
  user: {} as GetUserQuery["user"],
  clients: [] as IGraphQLTuskClient[],
  selectedClient: {} as IGraphQLTuskClient,
  selectedClientId: "",
  setSelectedClientId: (clientId: string) => {},
  showOldTuskUI: false,
  allowSelectingTests: false,
  isAdmin: false,
  toggleAdminView: () => {},
});

export const useAppContext = () => useContext(AppContext);

const GET_USER = gql(`
  query GetUser {
    user {
      id
      name
      email
      isAdmin
      clients {
        id
        name
        features
        subscriptionPlan {
          planName
          pricePerMonth
          maxSyncedRepos
          maxSuccessfulTasks
          maxSeats
          stripeSubscriptionEmail
        }
        customerSlackChannelId
      }
    }
  }
`);

const GET_CLIENT = gql(`
  query GetClient($id: String!) {
    client(id: $id) {
      id
      name
      features
      subscriptionPlan {
        planName
        pricePerMonth
        maxSyncedRepos
        maxSuccessfulTasks
        maxSeats
        stripeSubscriptionEmail
      }
      customerSlackChannelId
    }
  }
`);

const CREATE_USER = gql(`
  mutation CreateUser {
    createUser {
      id
      name
      email
      isAdmin
      clients {
        id
        name
        features
        subscriptionPlan {
          planName
          pricePerMonth
          maxSyncedRepos
          maxSuccessfulTasks
          maxSeats
          stripeSubscriptionEmail
        }
      }
    }
  }
`);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading: isAuthLoading,
    user: auth0User,
  } = useAuth0();

  const navigate = useNavigate();
  const posthog = usePostHog();

  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(
    null,
  );
  const [authToken, setAuthToken] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<GetUserQuery["user"] | undefined>(undefined);
  const [selectedClient, setSelectedClient] = useState<IGraphQLTuskClient | undefined>(undefined);
  const [selectedClientId, setSelectedClientId] = useState<string | undefined>(undefined);
  const [hasError, setHasError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // this is the client object for a client that is not in the user's clients array, but client id is provided in the URL
  // only admins can access clients in this way (verified in the middleware)
  const [adminClient, setAdminClient] = useState<IGraphQLTuskClient | undefined>(undefined);

  const [forceNonAdmin, setForceNonAdmin] = useState<boolean>(false);

  const allClients = adminClient ? [...(user?.clients || []), adminClient] : user?.clients || [];

  useEffect(() => {
    const fetchSelectedClient = async () => {
      if (!apolloClient) {
        return;
      }

      if (user && selectedClientId && selectedClient?.id !== selectedClientId) {
        const selectedClient = find(allClients, (client) => client.id === selectedClientId);
        if (selectedClient) {
          setSelectedClient(selectedClient);
        } else if (user?.isAdmin) {
          try {
            const adminClientResponse = await apolloClient.query({
              query: GET_CLIENT,
              variables: {
                id: selectedClientId,
              },
            });
            const adminClient = adminClientResponse.data?.client;
            if (adminClient) {
              setAdminClient(adminClient);
              setSelectedClient(adminClient);
            } else {
              console.log("Admin client not found");
            }
          } catch (error) {
            console.error("Error fetching admin client", error);
          }
        }
      }
    };

    fetchSelectedClient();
  }, [selectedClientId, allClients, apolloClient, user?.isAdmin]);

  useEffect(() => {
    if (
      size(allClients) > 1 &&
      selectedClientId &&
      selectedClientId !== searchParams.get("client")
    ) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("client", selectedClientId);
      setSearchParams(newSearchParams, { replace: true });
    }
  }, [selectedClientId, allClients, searchParams, setSearchParams]);

  // this is used to set the selected client ID from the URL
  useEffect(() => {
    const urlClient = searchParams.get("client");
    if (!urlClient) {
      return;
    }

    if (urlClient === selectedClientId) {
      return;
    }

    const allClientIds = allClients?.map((client) => client.id);
    if (!isEmpty(allClientIds) && !includes(allClientIds, urlClient)) {
      console.log(`Client ID ${urlClient} not found`);
      return;
    }

    setSelectedClientId(urlClient);

    // don't have allClients be a dependency bc this causes an infinite loop
  }, [searchParams]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (!authToken) {
      return;
    }

    const httpLink = createHttpLink({
      uri: `${process.env.API_BASE_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: authToken ? `Bearer ${authToken}` : "",
          ...(selectedClientId ? { "selected-client-id": selectedClientId } : {}),
        },
      };
    }).concat(httpLink);

    const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.extensions?.code) {
            case "UNAUTHORIZED":
              return fromPromise(
                getAccessTokenSilently()
                  .then((newToken) => {
                    setAuthToken(newToken);
                    // Do this so that the new token is used in the retry
                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        ...headers,
                        authorization: `Bearer ${newToken}`,
                      },
                    }));
                    return operation;
                  })
                  .catch((err) => {
                    console.log("Error getting new token", err);
                    setHasError(true);
                    return operation;
                  }),
              ).flatMap(forward);
          }
        }
      }
    });

    const apolloClient = new ApolloClient({
      link: from([errorLink, authLink]),
      cache: new InMemoryCache(),
    });

    setApolloClient(apolloClient);
  }, [authToken, isAuthenticated, selectedClientId, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthLoading) {
      return;
    }

    if (isAuthenticated) {
      getAccessTokenSilently()
        .then((token) => {
          if (token !== authToken) {
            posthog?.capture("user_logged_in");
          }
          setAuthToken(token);
        })
        .catch((err) => {
          console.log("Error setting up GraphQL", err);
          setHasError(true);
        });
    } else {
      console.log("User is not authenticated, redirecting to home page");
      navigate("/app");
    }
  }, [isAuthLoading, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (apolloClient) {
      apolloClient
        .query({
          query: GET_USER,
        })
        .then((res) => {
          const user = res.data?.user;
          if (user) {
            setUser(user);
            setSelectedClientId(selectedClientId || user?.clients[0]?.id);
          } else {
            // User doesn't exist, create them
            apolloClient
              .mutate({
                mutation: CREATE_USER,
              })
              .then((res) => {
                const createdUser = res.data?.createUser;
                if (createdUser) {
                  setUser(createdUser);
                  setSelectedClientId(createdUser?.clients[0]?.id);
                  setHasError(false);
                  posthog?.capture("user_signed_up");
                } else {
                  throw new Error("User not created");
                }
              })
              .catch((err) => {
                console.log("Error creating user", err);
                setHasError(true);
              });
          }
        })
        .catch((err) => {
          console.log("Error getting user", err);
          setHasError(true);
        });
    }
  }, [apolloClient]);

  useEffect(() => {
    if (user) {
      posthog?.identify(user.id, {
        name: user.name,
        email: user.email,
      });

      // don't send group call if user is not in selected client (true for god mode login)
      if (
        includes(user.clients?.map((client) => client.id), selectedClient?.id) &&
        selectedClient
      ) {
        posthog?.resetGroups();
        posthog?.group("company", selectedClient.id, {
          // add name as property if it's not a default name
          name: selectedClient?.name !== "Default" ? selectedClient?.name : undefined,
        });
      }
    }
  }, [user, selectedClient]);

  const isLoadingAppContext = isAuthLoading || !apolloClient || !user || !selectedClient;

  // Compute the effective admin status
  const isAdmin = user?.isAdmin && !forceNonAdmin;

  // Function to toggle admin view
  const toggleAdminView = () => {
    setForceNonAdmin(!forceNonAdmin);
  };

  if (hasError) {
    return (
      <ErrorPage
        errorCode=""
        errorTitle="Error logging in"
        errorDescription="Sorry about this. Contact support and we'll figure this out right away."
        homePage="marketing"
      />
    );
  }

  return (
    <AppContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoadingAppContext,
        clients: allClients,
        selectedClient,
        selectedClientId,
        showOldTuskUI: selectedClient?.features?.includes(ClientFeatures.ShowOldTuskUi) || false,
        allowSelectingTests:
          selectedClient?.features?.includes(ClientFeatures.AllowSelectingTests) || false,
        setSelectedClientId,
        authToken: authToken || "",
        isAdmin: isAdmin || false,
        toggleAdminView,
      }}
    >
      {isLoadingAppContext ? (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <LoadingSpinner />
        </div>
      ) : (
        <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      )}
    </AppContext.Provider>
  );
};
