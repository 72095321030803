import { Navigate, Route, Routes } from "react-router-dom";

import { AppSidebar, ErrorPage } from "../components";
import { useAppContext } from "../providers";

import { SettingsPage } from "./SettingsPage";
import { DashboardPage } from "./DashboardPage";
import { AdminPage } from "./AdminPage";
import { AutoTriagePage } from "./AutoTriagePage";
import { ConnectGitlabPage } from "./ConnectGitlabPage";
import { TestingCommitCheckPage } from "../containers/testing-commit-check/TestingCommitCheckPage";
export const AppPage = () => {
  const { showOldTuskUI, selectedClientId } = useAppContext();

  return (
    <div>
      <Routes>
        {/** Refactor so admin has it's own login */}
        <Route path="/admin/*" element={<AdminPage />} />
        <Route
          path="/*"
          element={
            <AppSidebar>
              <Routes>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/suggested-issues" element={<AutoTriagePage />} />
                <Route path="/settings/*" element={<SettingsPage />} />
                <Route path="/settings/connect-gitlab" element={<ConnectGitlabPage />} />
                <Route path="/task/:taskId" element={<DashboardPage />} />
                <Route
                  path="/testing-commit-check/:testingCommitCheckRunId"
                  element={<TestingCommitCheckPage />}
                />
                {showOldTuskUI ? (
                  <Route
                    path="/"
                    element={<Navigate to={`/app/dashboard?client=${selectedClientId}`} replace />}
                  />
                ) : (
                  <Route
                    path="/"
                    element={<Navigate to={`/app/settings?client=${selectedClientId}`} replace />}
                  />
                )}
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </AppSidebar>
          }
        />
      </Routes>
    </div>
  );
};
