// @ts-nocheck

import { capitalize } from "lodash";
import { useNotificationContext } from "../providers";

export const CopyText = ({ text, type }: { text: string; type: string }) => {
  const { showNotification } = useNotificationContext();
  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(text);
    showNotification({
      title: `${capitalize(type)} copied 📋`,
    });
  };

  return (
    <a
      href="#"
      onClick={copyToClipboard}
      className="text-purple-600 hover:text-purple-800 visited:text-purple-700"
    >
      {text}
    </a>
  );
};
