import { ConnectExternalResource } from "../../components";
import { useAppContext, useNotificationContext } from "../../providers";
import { GetResourcesQuery } from "../../__generatedGQL__/graphql";
import { gql } from "../../__generatedGQL__/gql";
import { useMutation } from "@apollo/client";

export const ENABLE_GITHUB_ISSUES_INTEGRATION = gql(`
  mutation EnableGithubIssuesIntegration($resourceId: Int!) {
    enableGithubIssuesIntegration(resourceId: $resourceId)
  }
`);

export const GithubIssuesIntegration = ({
  resource,
  refetchResources,
}: {
  resource: GetResourcesQuery["resources"][0];
  refetchResources: () => Promise<void>;
}) => {
  const { showNotification } = useNotificationContext();
  const { showOldTuskUI } = useAppContext();

  const issuesIntegrationEnabled = !!resource && resource.config?.issuesIntegrationEnabled;

  const [enableGithubIssuesIntegration] = useMutation(ENABLE_GITHUB_ISSUES_INTEGRATION);

  const onConnect = async () => {
    try {
      await enableGithubIssuesIntegration({ variables: { resourceId: resource.id } });
      await refetchResources();
      showNotification({
        title: "GitHub issues integration enabled",
        message: 'Add the "Tusk" label to a GitHub issue to assign it to Tusk',
        type: "success",
      });
    } catch (error) {
      console.error(error);
      showNotification({
        title: "Error enabling GitHub issues integration",
        type: "error",
      });
    }
  };

  return (
    <ConnectExternalResource
      authed={issuesIntegrationEnabled}
      onConnect={onConnect}
      resourceName="GitHub Issues"
      resourceLogo="https://asset.brandfetch.io/idZAyF9rlg/idw3q8jby5.png"
      resourceDescription={
        showOldTuskUI
          ? "Assign issues using a GitHub label"
          : "Analyze GitHub issues linked to your PRs"
      }
      connectCopy="Enable"
      connectedCopy="Enabled"
      connectDisabled={!resource}
      connectDisabledTooltip="Connect your GitHub account to enable this feature"
    />
  );
};
