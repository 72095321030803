import { clsx } from "clsx";
import { useMemo } from "react";
import { useNavigate, useLocation, Routes, Route, Navigate, Link } from "react-router-dom";
import {
  SubscriptionSettings,
  IntegrationSettings,
  UserSettings,
  RepoConfigSettings,
  RepoSettings,
  Seats,
  BillingSettings,
} from "../containers";
import { PageHeading } from "../components";
import { useAppContext } from "../providers";
import { compact, startsWith } from "lodash";
import { useExternalResourcesHook } from "../hooks/useExternalResourcesHook";

export const SettingsPage = () => {
  const { showOldTuskUI, selectedClientId } = useAppContext();
  const { hasEnabledRepos } = useExternalResourcesHook();
  const navigate = useNavigate();
  const location = useLocation();

  const showSeatsTab = !showOldTuskUI;

  const tabs = useMemo(() => {
    return compact([
      { name: "Integrations", href: "/app/settings/integrations", current: false },
      { name: "Repos", href: "/app/settings/repos", current: false },
      hasEnabledRepos && {
        name: "Customization",
        href: "/app/settings/customization",
        current: false,
      },
      showOldTuskUI
        ? { name: "Subscription", href: "/app/settings/subscription", current: false }
        : { name: "Billing", href: "/app/settings/billing", current: false },
      ,
      { name: "Team", href: "/app/settings/team", current: false },
      showSeatsTab && { name: "Seats", href: "/app/settings/seats", current: false },
    ]);
  }, [showOldTuskUI, hasEnabledRepos]);

  return (
    <>
      <PageHeading name="Settings" />
      <div className="border-b border-gray-200 pb-5 mb-8 sm:pb-0">
        <div>
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-purple-500 focus:outline-hidden focus:ring-purple-500 sm:text-sm"
              onChange={(e) => navigate(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.name} value={tab.href}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.href}
                  className={clsx(
                    startsWith(location.pathname, tab.href)
                      ? "border-purple-500 text-purple-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium hover:cursor-pointer",
                  )}
                  aria-current={tab.href === location.pathname ? "page" : undefined}
                >
                  {tab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div>
        <Routes>
          <Route path="integrations" element={<IntegrationSettings />} />
          <Route path="repos" element={<RepoSettings />} />
          <Route path="customization" element={<RepoConfigSettings />} />
          <Route path="customization/repo/:repoId" element={<RepoConfigSettings />} />
          <Route
            path="customization/repo/:repoId/testing-sandbox-config/:testingSandboxConfigId/:tab"
            element={<RepoConfigSettings />}
          />
          <Route path="billing" element={<BillingSettings />} />
          <Route path="subscription" element={<SubscriptionSettings />} />
          <Route path="team" element={<UserSettings />} />
          <Route path="seats" element={<Seats />} />
          {/**
           *  For some reason, the default route is not working when user clicks on Settings from AppSidebar if AppSidebar Settings route is /app/settings
           *  Workaround is to have AppSidebar Settings route as /app/settings/integrations
           *
           *  Seems likely this is related to React Router v7 upgrade? Might be some recursive redirect issue?
           */}
          <Route
            path="/"
            element={
              <Navigate to={`/app/settings/integrations?client=${selectedClientId}`} replace />
            }
          />
        </Routes>
      </div>
    </>
  );
};
