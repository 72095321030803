import { some, find, size } from "lodash";
import { useMutation, useQuery } from "@apollo/client";

import {
  AlertBanner,
  CopyText,
  ErrorPage,
  LoadingSpinner,
  RepoEnablementForm,
} from "../components";
import { GithubAuth } from "./integrations-auth";
import { useAppContext, useNotificationContext } from "../providers";
import { gql } from "../__generatedGQL__/gql";
import { RepoUpdateInput, ResourceType } from "../__generatedGQL__/graphql";
import { getEnvironment } from "../utils";
import { useExternalResourcesHook } from "../hooks/useExternalResourcesHook";
import { GitlabAuth } from "./integrations-auth/GitlabAuth";
import { useSearchParams } from "react-router-dom";

export const FIND_RESOURCE = gql(`
  query FindResource($type: ResourceType!) {
    resource(type: $type) {
      id
      externalId
      type
      userId
      createdAt
      updatedAt
    }
  }
`);

export const GET_REPOS = gql(`
  query GetRepos($resourceId: Int!) {
    repos(resourceId: $resourceId) {
      id
      name
      ownerName
      enabled
      lastSyncedAt
      config {
        blockedDirs
      }
      defaultBranch
      context
      testingSandboxConfigs {
        id
        type
        appDir
        testFramework
        description
      }
      testingConfig {
        testCheckEnabled
      }
    }
  }
`);

const UPDATE_ENABLED_REPOS = gql(`
  mutation UpdateEnabledRepos($resourceId: Int!, $repoIds: [Int!]!) {
    updateEnabledRepos(resourceId: $resourceId, repoIds: $repoIds)
  }
`);

const SYNC_REPOS = gql(`
  mutation SyncRepos($resourceId: Int!) {
    syncRepos(resourceId: $resourceId)
  }
`);

const UPDATE_REPO = gql(`
  mutation UpdateRepo($id: Int!, $input: RepoUpdateInput!) {
    updateRepo(id: $id, input: $input) {
      id
    }
  }
`);

export const RepoSettings = () => {
  const { showOldTuskUI } = useAppContext();
  const { showNotification } = useNotificationContext();

  const [searchParams] = useSearchParams();
  // See AuthController.ts for the different types of installation errors
  const installationError = searchParams.get("installation-error");

  const { githubResource, gitlabResource, loading: loadingResources } = useExternalResourcesHook();
  // For now, assume we only have one code hosting resource
  const codeHostingResource = githubResource || gitlabResource;

  const {
    loading: loadingRepos,
    error: reposError,
    data: reposData,
    refetch: refetchRepos,
  } = useQuery(GET_REPOS, {
    variables: {
      resourceId: codeHostingResource?.id ?? 0,
    },
    skip: !codeHostingResource?.id,
  });

  const organizationName =
    size(reposData?.repos) > 0 &&
    reposData?.repos.every((repo) => repo.ownerName === reposData.repos[0].ownerName)
      ? reposData.repos[0].ownerName
      : undefined;
  const codeHostingEnabled = !!codeHostingResource;

  const [updateEnabledReposMutation, { error: updateEnabledReposError }] =
    useMutation(UPDATE_ENABLED_REPOS);
  const [updateRepoMutation, { error: updateRepoError }] = useMutation(UPDATE_REPO);

  const updateRepo = async (id: number, input: RepoUpdateInput) => {
    await updateRepoMutation({ variables: { id, input } });
    await refetchRepos();
  };

  const updateEnabledRepos = async (repoIds: number[]) => {
    await updateEnabledReposMutation({
      variables: { resourceId: codeHostingResource?.id ?? 0, repoIds },
    });
    await refetchRepos();

    const newReposEnabled = some(
      repoIds,
      (repoId) => !find(reposData?.repos, (repo) => repo.id === repoId)?.enabled,
    );
    let message: string | undefined;
    if (newReposEnabled) {
      if (showOldTuskUI) {
        message =
          "Syncing may take up to 5 minutes. If you run into problems when creating an issue, please wait a few minutes before trying again.";
      } else {
        message =
          "Enabling automated tests can take up to 5 minutes. If additional setup is required our team is notified and will reach out to you.";
      }
    }
    showNotification({
      title: "Updated synced repos",
      message,
    });
  };

  const [syncReposMutation, { error: syncReposError }] = useMutation(SYNC_REPOS);
  const syncRepos = async () => {
    await syncReposMutation({ variables: { resourceId: codeHostingResource?.id ?? 0 } });
    await refetchRepos();
  };

  if (loadingResources || loadingRepos) return <LoadingSpinner />;

  const error = reposError || updateEnabledReposError || syncReposError;
  if (error)
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading Repo settings"
        errorDescription={error.message}
      />
    );

  if (installationError === "github-request-installation-no-permissions") {
    return (
      <AlertBanner
        title="Admin approval required"
        message={
          <>
            Please contact an admin in your organization to approve the installation on GitHub. If
            you have any questions feel free to reach out to{" "}
            <CopyText text="founders@tusk.com" type="email" />.
          </>
        }
      />
    );
  }

  if (installationError === "github-approve-install-no-state") {
    return (
      <AlertBanner
        title="Additional setup required"
        message={
          <>
            Thanks for approving the Tusk app on GitHub. Please contact{" "}
            <CopyText text="founders@tusk.com" type="email" /> to complete setup.
          </>
        }
      />
    );
  }

  return (
    <>
      {codeHostingEnabled ? (
        <>
          <RepoEnablementForm
            organizationName={organizationName}
            repos={reposData?.repos ?? []}
            updateEnabledRepos={updateEnabledRepos}
            syncRepos={syncRepos}
            updateRepo={updateRepo}
          />
        </>
      ) : (
        <div className="flex flex-wrap justify-start gap-8">
          <GithubAuth resource={githubResource} />
          <GitlabAuth resource={gitlabResource} />
        </div>
      )}
    </>
  );
};
