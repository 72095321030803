import { useMemo, useState } from "react";
import { InformationBanner, PageHeading, Button, Input, CopyText } from "../components";
import { GoCopy } from "react-icons/go";
import { useNotificationContext } from "../providers";
import { useExternalResourcesHook } from "../hooks/useExternalResourcesHook";
import { useNavigate } from "react-router-dom";

import { getWebhookApiBaseUrl } from "../utils";
import { gql } from "../__generatedGQL__";
import { useMutation } from "@apollo/client";

const CONNECT_GITLAB_MUTATION = gql(`
  mutation ConnectGitlabResource($gitlabHost: String!, $gitlabAccessToken: String!, $webhookSecretToken: String!) {
    connectGitlabResource(gitlabHost: $gitlabHost, gitlabAccessToken: $gitlabAccessToken, webhookSecretToken: $webhookSecretToken) {
      success
      message
    }
  }
`);

const GITLAB_DOCS_URL =
  "https://docs.usetusk.ai/automated-tests/onboarding#connect-your-gitlab-group-project";

const DEFAULT_GITLAB_HOST = "https://gitlab.com";

export const ConnectGitlabPage = () => {
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();
  const { gitlabResource, refetchResources } = useExternalResourcesHook();

  const [connectGitlabResource] = useMutation(CONNECT_GITLAB_MUTATION);
  const [isConnecting, setIsConnecting] = useState(false);

  const [gitlabHost, setGitlabHost] = useState("");
  const [gitlabAccessToken, setGitlabAccessToken] = useState("");
  const [webhookConfirmed, setWebhookConfirmed] = useState(false);
  const webhookSecretToken = useMemo(() => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = 64;
    let result = "";
    const randomValues = new Uint32Array(length);
    crypto.getRandomValues(randomValues);
    for (let i = 0; i < length; i++) {
      result += characters.charAt(randomValues[i] % characters.length);
    }
    return result;
  }, []);
  const webhookUrl = `${getWebhookApiBaseUrl()}/api/webhook/gitlab`;

  const onClickConnectGitlab = async () => {
    try {
      setIsConnecting(true);
      const { data } = await connectGitlabResource({
        variables: {
          gitlabHost: gitlabHost ?? undefined,
          gitlabAccessToken,
          webhookSecretToken,
        },
      });
      if (data?.connectGitlabResource?.success) {
        showNotification({
          title: "GitLab connected successfully",
        });
        await refetchResources();
        navigate("/app/settings/repos");
      } else {
        showNotification({
          title: "Error connecting GitLab",
          message:
            data?.connectGitlabResource?.message ||
            "Please contact support if this issue persists.",
        });
      }
    } catch (error) {
      showNotification({
        title: "Unexpected error connecting GitLab",
        message: "Please contact support if this issue persists.",
      });
    } finally {
      setIsConnecting(false);
    }
  };

  const isFormValid = gitlabAccessToken.trim() !== "" && webhookConfirmed;

  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text);
    showNotification({
      title: `Copied ${type}`,
    });
  };

  if (gitlabResource) {
    navigate("/app/settings/integrations");
    return null;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <PageHeading name="Connect GitLab Group" />
      <InformationBanner>
        Setting up GitLab takes a few minutes of manual work due to limitations with the GitLab API.
        Please reach out to <CopyText text="support@usetusk.ai" type="email" /> if you have any
        questions. View additional documentation{" "}
        <a
          href={GITLAB_DOCS_URL}
          className="text-purple-600 hover:text-purple-800"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </InformationBanner>

      <div className="space-y-6 mt-6">
        {/* Step 1: GitLab Host */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="font-medium text-lg mb-2">Step 1: Navigate to GitLab Group</h3>
          <div className="text-sm text-gray-600 space-y-2 mb-4">
            <p>
              Go to your GitLab group from within the GitLab UI. If you're using a self-hosted
              GitLab instance, please provide your GitLab host URL below (e.g. if the group URL is{" "}
              <a
                href="https://gitlab.ex.com/example-group"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 hover:text-purple-800"
              >
                https://gitlab.ex.com/group
              </a>{" "}
              then your GitLab host would be{" "}
              <a
                href="https://gitlab.ex.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 hover:text-purple-800"
              >
                https://gitlab.ex.com
              </a>
              ).
            </p>
            <p>
              You can leave this empty if you're just using{" "}
              <a
                href="https://gitlab.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 hover:text-purple-800"
              >
                https://gitlab.com
              </a>
              .
            </p>
          </div>
          <Input
            label="GitLab Host"
            value={gitlabHost}
            onChange={(e) => setGitlabHost(e.target.value)}
            placeholder={DEFAULT_GITLAB_HOST}
            error={(() => {
              if (!gitlabHost) {
                return undefined;
              }
              try {
                new URL(gitlabHost);
              } catch (error) {
                return "Provide a valid GitLab host URL";
              }
            })()}
          />
        </div>

        {/* Step 2: Access Token */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="font-medium text-lg mb-2">Step 2: Create Access Token</h3>
          <div className="text-sm text-gray-600">
            <p className="mb-4">
              In the left sidebar, select Settings &gt; Access tokens. You can create either a{" "}
              <a
                href="https://docs.gitlab.com/ee/user/group/settings/group_access_tokens.html#create-a-group-access-token-using-ui"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                group access token
              </a>{" "}
              or a{" "}
              <a
                href="https://docs.gitlab.com/ee/user/project/settings/project_access_tokens.html#create-a-project-access-token"
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                project access token
              </a>
              . We recommend using a group access token so that you don't need to repeat this setup
              when adding additional projects.
            </p>
            <div className="mb-4">
              <p className="mb-2">Configure the token with these settings:</p>
              <div className="ml-4 space-y-2">
                <div className="flex items-center gap-2">
                  <span className="font-semibold">Token name:</span>{" "}
                  <span className="font-mono">Tusk</span>
                </div>
                <div>
                  <span className="font-semibold">Expiry date:</span> Set as far in the future as
                  possible to avoid needing to re-create the token.
                </div>
                <div className="flex items-center gap-2">
                  <span className="font-semibold">Role:</span>{" "}
                  <span className="font-mono">Developer</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="font-semibold">Scopes:</span>{" "}
                  <div className="inline-flex items-center">
                    <span className="font-mono">api</span>,&nbsp;&nbsp;
                    <span className="font-mono">read_repository</span>,&nbsp;&nbsp;
                    <span className="font-mono">write_repository</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Input
            label="Access Token"
            value={gitlabAccessToken}
            onChange={(e) => setGitlabAccessToken(e.target.value)}
            placeholder="Enter your GitLab access token"
          />
        </div>

        {/* Step 3: Webhook */}
        <div className="bg-gray-50 rounded-lg p-6">
          <h3 className="font-medium text-lg mb-2">Step 3: Create Webhook</h3>
          <div className="text-sm text-gray-600 mb-4">
            <p className="text-gray-600 mb-4">
              Go to Settings &gt; Webhooks in the left sidebar and create a new webhook with the
              following details:
            </p>
            <div className="space-y-2 ml-4">
              <div className="flex flex-wrap items-start gap-2">
                <span className="min-w-16 font-semibold">URL:</span>
                <div className="flex items-center gap-2 font-mono">
                  {webhookUrl}
                  <button
                    onClick={() => copyToClipboard(webhookUrl, "webhook URL")}
                    className="text-purple-500 hover:text-purple-800"
                  >
                    <GoCopy size={16} />
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap items-start gap-2">
                <span className="min-w-16 font-semibold">Secret token:</span>
                <div className="flex items-center font-mono gap-2 break-all">
                  {webhookSecretToken}
                  <button
                    onClick={() => copyToClipboard(webhookSecretToken, "webhook secret token")}
                    className="text-purple-500 hover:text-purple-800"
                  >
                    <GoCopy size={16} />
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap items-start gap-2">
                <span className="min-w-16 font-semibold">Triggers:</span>
                <div className="flex flex-wrap items-center">
                  <span className="font-mono">Push events (all branches)</span>,&nbsp;&nbsp;
                  <span className="font-mono">Comments</span>,&nbsp;&nbsp;
                  <span className="font-mono">Issues events</span>,&nbsp;&nbsp;
                  <span className="font-mono">Merge request events</span>,&nbsp;&nbsp;
                  <span className="font-mono">Emoji events</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              id="webhookConfirmed"
              checked={webhookConfirmed}
              onChange={(e) => setWebhookConfirmed(e.target.checked)}
              className="h-4 w-4 text-purple-600 rounded-sm focus:ring-purple-500 focus:ring-2 focus:ring-offset-2"
            />
            <label htmlFor="webhookConfirmed" className="text-sm text-gray-600">
              I have created the webhook with the above configuration
            </label>
          </div>
        </div>

        <div className="flex justify-center">
          <Button
            loading={isConnecting}
            onClick={onClickConnectGitlab}
            disabled={!isFormValid}
            className="w-full"
            tooltipText={
              isFormValid
                ? undefined
                : "Please provide access token and confirm webhook configuration"
            }
          >
            Connect GitLab
          </Button>
        </div>
      </div>
    </div>
  );
};
