import { Button, InformationBanner, LoadingSpinner } from "../../components";
import {
  TestingSandboxConfigType,
  TestingSandboxConfigUpdateInput,
} from "../../__generatedGQL__/graphql";
import { useState } from "react";
import { gql } from "../../__generatedGQL__/gql";
import { useMutation, useQuery } from "@apollo/client";
import { MarkdownEditor } from "../../components/editors/markdown/MarkdownEditor";

const UPDATE_TESTING_SANDBOX_CONFIG = gql(`
  mutation UpdateTestingSandboxConfig($id: String!, $input: TestingSandboxConfigUpdateInput!) {
    updateTestingSandboxConfig(id: $id, input: $input)
  }
`);

const GET_TESTING_SANDBOX_CONFIG = gql(`
  query GetTestingSandboxConfig($id: String!) {
    testingSandboxConfig(id: $id) {
      id
      type
      parentConfigId
      appDir
      description
      testingConfig {
        symbolEligibilityContext
        testScenarioContext
        testGenerationContext
        testLocationContext
      }
      parentConfig {
        id
        description
      }
    }
  }
`);

/**
 * Instructions:
 * - description (short description of what this test config is for, e.g. "Backend unit tests")
 * - symbolEligibility (what symbols should/should not be tested)
 * - testScenarioContext (what types of test cases should we consider) OPTIONAL
 * - testGenerationContext (context on how to write tests, include info about mocks, etc.)
 * - testLocationContext (where to put tests, how to name tests, etc.)
 *
 * Execution:
 * - testFramework
 * - appDir
 * - testFileRegex
 * - dockerfile
 * - dockerComposeFile
 * - dockerComposeConfig
 * - setupScript
 * - testScript
 * - lintScript
 * - envVars
 */

export const TestingSandboxCustomization = ({
  testingSandboxConfigId,
}: {
  testingSandboxConfigId: string;
}) => {
  const {
    loading: loadingTestingSandboxConfig,
    error: testingSandboxConfigError,
    data: testingSandboxConfigData,
    refetch: refetchTestingSandboxConfig,
  } = useQuery(GET_TESTING_SANDBOX_CONFIG, {
    variables: {
      id: testingSandboxConfigId,
    },
    onCompleted: (data) => {
      setDescription(data.testingSandboxConfig.description || "");
    },
  });

  const [updateTestingSandboxConfigMutation, { error: updateTestingSandboxConfigError }] =
    useMutation(UPDATE_TESTING_SANDBOX_CONFIG, {
      refetchQueries: [
        {
          query: GET_TESTING_SANDBOX_CONFIG,
          variables: { id: testingSandboxConfigId },
        },
      ],
    });

  const [description, setDescription] = useState(
    testingSandboxConfigData?.testingSandboxConfig?.description || "",
  );
  const [isSavingDescription, setIsSavingDescription] = useState(false);
  const [symbolEligibilityContext, setSymbolEligibilityContext] = useState(
    testingSandboxConfigData?.testingSandboxConfig?.testingConfig?.symbolEligibilityContext || "",
  );
  const [testScenarioContext, setTestScenarioContext] = useState(
    testingSandboxConfigData?.testingSandboxConfig?.testingConfig?.testScenarioContext || "",
  );
  const [testGenerationContext, setTestGenerationContext] = useState(
    testingSandboxConfigData?.testingSandboxConfig?.testingConfig?.testGenerationContext || "",
  );
  const [testLocationContext, setTestLocationContext] = useState(
    testingSandboxConfigData?.testingSandboxConfig?.testingConfig?.testLocationContext || "",
  );

  if (loadingTestingSandboxConfig) {
    return <LoadingSpinner className="mt-12" />;
  }

  return (
    <>
      <div className="space-y-12 sm:space-y-16">
        <div className="pb-12 pl-12">
          {testingSandboxConfigData?.testingSandboxConfig?.type ===
            TestingSandboxConfigType.Parent && (
            <div className="mt-8">
              <InformationBanner>
                <p>
                  This is a <b>parent testing environment</b>. Customization will be applied to all
                  testing environments that inherit from this environment.
                </p>
              </InformationBanner>
            </div>
          )}
          {testingSandboxConfigData?.testingSandboxConfig?.parentConfig && (
            <div className="mt-8">
              <InformationBanner>
                <p>
                  This testing environment inherits from{" "}
                  <b>{testingSandboxConfigData?.testingSandboxConfig?.parentConfig?.description}</b>
                  . Customization made here will be provided as additional context.
                </p>
              </InformationBanner>
            </div>
          )}
          <div className="space-y-8  border-gray-900/10 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0">
            {/* Environment Directory */}
            {testingSandboxConfigData?.testingSandboxConfig?.appDir && (
              <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-6">
                <div>
                  <label
                    htmlFor="appDir"
                    className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                  >
                    Environment Directory
                  </label>
                  <p className="mt-2 text-sm text-gray-500">Directory for testing environment</p>
                </div>

                <div className="mt-2 sm:col-span-3 sm:mt-4 items-center">
                  <div className="flex items-center gap-x-8">
                    <div className="flex flex-1 rounded-md shadow-xs ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-600">
                      <input
                        key={`appDir-${testingSandboxConfigId}`}
                        type="text"
                        id="appDir"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-3 font-mono text-gray-600 focus:ring-0 sm:text-sm sm:leading-6 placeholder:text-gray-400"
                        value={testingSandboxConfigData?.testingSandboxConfig?.appDir || ""}
                        readOnly
                        placeholder="App directory path"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Description */}
            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-6">
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Description
                </label>
                <p className="mt-2 text-sm text-gray-500">Type of tests for this config</p>
              </div>
              <div className="mt-2 sm:col-span-3 sm:mt-4 items-center">
                <div className="flex items-center gap-x-8">
                  <div className="flex flex-1 rounded-md shadow-xs ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-purple-600">
                    <input
                      key={`description-${testingSandboxConfigId}`}
                      type="text"
                      id="description"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-3 prose focus:ring-0 sm:text-sm sm:leading-6 placeholder:text-gray-400"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Backend unit tests"
                    />
                  </div>
                  <Button
                    variant="secondary"
                    size="sm"
                    disabled={
                      description ===
                      (testingSandboxConfigData?.testingSandboxConfig?.description || "")
                    }
                    loading={isSavingDescription}
                    onClick={async () => {
                      setIsSavingDescription(true);
                      await updateTestingSandboxConfigMutation({
                        variables: {
                          id: testingSandboxConfigId,
                          input: {
                            description: description,
                          },
                        },
                      });
                      setIsSavingDescription(false);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-6">
              <div>
                <label
                  htmlFor="symbolEligibility"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Symbol selection guidelines
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  The types of symbols (i.e. functions, methods) that should or should not be tested
                </p>
              </div>
              <div className="sm:col-span-3">
                <MarkdownEditor
                  key={`symbolEligibilityContext-${testingSandboxConfigId}`}
                  editorClassName="min-h-[140px] max-h-[200px]"
                  placeholder="Do not test any symbols that require calling an LLM API (e.g., OpenAI, Anthropic) or fetching data from the DB."
                  initialContent={
                    testingSandboxConfigData?.testingSandboxConfig?.testingConfig
                      ?.symbolEligibilityContext || ""
                  }
                  onUpdate={setSymbolEligibilityContext}
                  onSave={async () => {
                    await updateTestingSandboxConfigMutation({
                      variables: {
                        id: testingSandboxConfigId,
                        input: {
                          testingConfig: {
                            symbolEligibilityContext,
                          },
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-6">
              <div>
                <label
                  htmlFor="testGeneration"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Test code guidelines
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Best practices for writing tests, creating mocks, using factories, importing
                  dependencies, etc.
                </p>
              </div>
              <div className="sm:col-span-3">
                <MarkdownEditor
                  key={`testGenerationContext-${testingSandboxConfigId}`}
                  editorClassName="min-h-[140px] max-h-[200px]"
                  placeholder="When creating a test double, default to using a stub instead of a mock unless the code change you're testing involves calling an external API."
                  initialContent={
                    testingSandboxConfigData?.testingSandboxConfig?.testingConfig
                      ?.testGenerationContext || ""
                  }
                  onUpdate={setTestGenerationContext}
                  onSave={async () => {
                    await updateTestingSandboxConfigMutation({
                      variables: {
                        id: testingSandboxConfigId,
                        input: {
                          testingConfig: {
                            testGenerationContext,
                          },
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-6">
              <div>
                <label
                  htmlFor="testScenarioContext"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Edge case guidelines
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  Guidelines on the types of edge cases to focus on
                </p>
              </div>
              <div className="sm:col-span-3">
                <MarkdownEditor
                  key={`testScenarioContext-${testingSandboxConfigId}`}
                  editorClassName="min-h-[140px] max-h-[200px]"
                  placeholder="Focus on finding edge cases involving a user not being logged in, a user not having access to a feature, etc. Avoid edge cases that involve handling an error gracefully if there is a low likelihood of the error occurring."
                  initialContent={
                    testingSandboxConfigData?.testingSandboxConfig?.testingConfig
                      ?.testScenarioContext || ""
                  }
                  onUpdate={setTestScenarioContext}
                  onSave={async () => {
                    await updateTestingSandboxConfigMutation({
                      variables: {
                        id: testingSandboxConfigId,
                        input: {
                          testingConfig: {
                            testScenarioContext,
                          },
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-6">
              <div>
                <label
                  htmlFor="testLocation"
                  className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                >
                  Test location guidelines
                </label>
                <p className="mt-2 text-sm text-gray-500">
                  How test files should be named and where they should be located
                </p>
              </div>
              <div className="sm:col-span-3">
                <MarkdownEditor
                  key={`testLocationContext-${testingSandboxConfigId}`}
                  editorClassName="min-h-[140px] max-h-[200px]"
                  placeholder="Test files should be named like `example.test.ts` and placed in the `backend/src/tests/unit` directory."
                  initialContent={
                    testingSandboxConfigData?.testingSandboxConfig?.testingConfig
                      ?.testLocationContext || ""
                  }
                  onUpdate={setTestLocationContext}
                  onSave={async () => {
                    await updateTestingSandboxConfigMutation({
                      variables: {
                        id: testingSandboxConfigId,
                        input: {
                          testingConfig: {
                            testLocationContext,
                          },
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
