import { useState, useEffect, useRef, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ErrorPage } from "../components/ErrorPage";
import { Button } from "../components/Button";
import { Switch } from "../components/Switch";
import { size } from "lodash";
import { CopyText, IssuesTablePagination } from "../components";
import { useAppContext } from "../providers";
import { getSlackChannelUrl } from "../utils/clientUtils";
import { useExternalResourcesHook } from "../hooks/useExternalResourcesHook";
import { GetSeatsQuery } from "../__generatedGQL__/graphql";
import { gql } from "../__generatedGQL__/gql";
import { XMarkIcon } from "@heroicons/react/20/solid";
import pluralize from "pluralize";

const GET_MAX_ALLOWED_SEATS = gql(`
  query GetMaxAllowedSeats {
    maxAllowedSeats
  }
`);

const GET_SEATS = gql(`
  query GetSeats {
    seats {
      id
      username
      active
      metadata {
        avatarUrl
      }
    }
  }
`);

type Seat = NonNullable<GetSeatsQuery["seats"]>[number];

const ACTIVATE_SEAT = gql(`
  mutation ActivateSeat($seatId: String!) {
    activateSeat(seatId: $seatId)
  }
`);

const DEACTIVATE_SEAT = gql(`
  mutation DeactivateSeat($seatId: String!) {
    deactivateSeat(seatId: $seatId)
  }
`);

const SYNC_SEATS = gql(`
  mutation SyncSeats {
    syncSeats
  }
`);

export const Seats = () => {
  const { selectedClient } = useAppContext();
  const { data, loading, error, refetch } = useQuery(GET_SEATS);
  const { data: maxSeatsData } = useQuery(GET_MAX_ALLOWED_SEATS);
  const [activateSeat] = useMutation(ACTIVATE_SEAT);
  const [deactivateSeat] = useMutation(DEACTIVATE_SEAT);
  const [syncSeats, { loading: syncing }] = useMutation(SYNC_SEATS);
  const [updatingSeatIds, setUpdatingSeatIds] = useState<string[]>([]);
  const [sortedSeats, setSortedSeats] = useState<Seat[]>([]);

  const [searchQuery, setSearchQuery] = useState("");
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { githubResource, gitlabResource } = useExternalResourcesHook();
  const codeHostingResource = githubResource || gitlabResource;
  const isOrganizationResource = codeHostingResource?.config?.belongsToOrganization;

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const maxAllowedSeats = maxSeatsData?.maxAllowedSeats ?? 0;

  const totalSeats = sortedSeats.length;
  const indexOfFirstSeat = totalSeats === 0 ? 0 : (currentPage - 1) * itemsPerPage;
  const indexOfLastSeat = totalSeats === 0 ? 0 : Math.min(currentPage * itemsPerPage, totalSeats);

  const isMaxSeatsReached = sortedSeats.filter((s) => s.active).length >= maxAllowedSeats;

  const handleToggleSeat = async (seat: { id: string; active: boolean }) => {
    if (!seat.active && isMaxSeatsReached) {
      console.error("Maximum number of active seats reached");
      return;
    }

    setUpdatingSeatIds((prev) => [...prev, seat.id]);
    try {
      if (seat.active) {
        await deactivateSeat({ variables: { seatId: seat.id } });
      } else {
        await activateSeat({ variables: { seatId: seat.id } });
      }
      await refetch();
    } catch (err) {
      console.error("Error toggling seat activation:", err);
    } finally {
      setUpdatingSeatIds((prev) => prev.filter((id) => id !== seat.id));
    }
  };

  const handleSyncSeats = async () => {
    try {
      await syncSeats();
      await refetch();
    } catch (err) {
      console.error("Error syncing seats:", err);
    }
  };

  const currentSeats = useMemo(() => {
    let filtered = sortedSeats;

    if (searchQuery) {
      filtered = filtered.filter((seat) =>
        seat.username.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }

    return filtered.slice(indexOfFirstSeat, indexOfLastSeat);
  }, [sortedSeats, searchQuery, indexOfFirstSeat, indexOfLastSeat]);

  const filteredSeats = useMemo(() => {
    if (!searchQuery) return sortedSeats;
    return sortedSeats.filter((seat) =>
      seat.username.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [sortedSeats, searchQuery]);

  // Only re-sort the seats on initial load
  // When data is updated, insert updates to seats directly in the correct position
  useEffect(() => {
    if (data?.seats) {
      if (sortedSeats.length === 0) {
        // Initial sort: active first, then by username
        const sorted = [...data.seats].sort((a, b) => {
          if (a.active !== b.active) {
            return a.active ? -1 : 1;
          }
          return a.username.localeCompare(b.username);
        });
        setSortedSeats(sorted);
      } else {
        // Update existing seats
        const updatedSeats = [...sortedSeats];

        // Find and add any new seats
        const newSeats = data.seats.filter(
          (newSeat) => !sortedSeats.some((existingSeat) => existingSeat.id === newSeat.id),
        );

        // If there are new seats, add them to the list maintaining the original sort rules
        if (newSeats.length > 0) {
          newSeats.sort((a, b) => {
            if (a.active !== b.active) {
              return a.active ? -1 : 1;
            }
            return a.username.localeCompare(b.username);
          });

          // Insert new seats in the appropriate position based on active status
          const activeSeatsCount = updatedSeats.filter((seat) => seat.active).length;
          updatedSeats.splice(activeSeatsCount, 0, ...newSeats);
        }

        // Update existing seats with new data
        const finalSeats = updatedSeats.map((sortedSeat) => {
          const updatedSeat = data.seats.find((s) => s.id === sortedSeat.id);
          return updatedSeat || sortedSeat;
        });

        setSortedSeats(finalSeats);
      }
    }
  }, [data?.seats]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "f") {
        event.preventDefault();
        searchInputRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (loading || !selectedClient) return <LoadingSpinner />;
  if (error) {
    return (
      <ErrorPage
        errorCode="500"
        errorTitle="Error loading seats"
        errorDescription={error.message}
      />
    );
  }

  const previousButtonDisabled = currentPage === 1;
  const nextButtonDisabled = currentPage >= Math.ceil(sortedSeats.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevCurrent) => prevCurrent + 1);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevCurrent) => prevCurrent - 1);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Seats</h2>
        <Button
          onClick={handleSyncSeats}
          disabled={syncing || !isOrganizationResource}
          loading={syncing}
          variant="secondary"
          size="sm"
        >
          Sync members
        </Button>
      </div>
      {isOrganizationResource && (
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <p className="text-sm text-gray-600 shrink-0">
            {data?.seats.filter((s: { active: boolean }) => s.active).length} of {maxAllowedSeats}{" "}
            {pluralize("seat", maxAllowedSeats)} in use. To add more, please{" "}
            {selectedClient.customerSlackChannelId ? (
              <a
                href={getSlackChannelUrl(selectedClient.customerSlackChannelId)}
                className="text-purple-600 hover:text-purple-800"
                target="_blank"
                rel="noopener noreferrer"
              >
                reach out to us
              </a>
            ) : (
              <>
                reach out to <CopyText text="support@usetusk.ai" type="email" />
              </>
            )}
            .
          </p>
          <div className="relative w-full sm:w-auto sm:max-w-xs">
            <input
              ref={searchInputRef}
              id="search"
              name="search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 pr-20 text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 text-sm sm:leading-6"
              placeholder="Search members..."
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-1.5">
              {searchQuery && (
                <button
                  type="button"
                  onClick={() => setSearchQuery("")}
                  className="p-1 text-gray-400 hover:text-gray-600"
                  aria-label="Clear search"
                >
                  <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              )}
              <kbd className="ml-1 inline-flex items-center rounded-sm border border-gray-200 px-1 font-sans text-xs text-gray-400">
                ⌘F
              </kbd>
            </div>
          </div>
        </div>
      )}
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr className="grid grid-cols-12">
            <th
              scope="col"
              className="col-span-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Username
            </th>
            <th
              scope="col"
              className="col-span-2 px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {currentSeats.map((seat) => (
            <tr key={seat.id} className="grid grid-cols-12 first:pt-2">
              <td className="col-span-10 px-3 py-4 text-sm text-gray-500">
                <div className="flex items-center gap-2">
                  <img
                    src={seat.metadata?.avatarUrl ?? ""}
                    alt={`${seat.username}'s avatar`}
                    className="h-8 w-8 rounded-full"
                  />
                  <span>{seat.username}</span>
                </div>
              </td>
              <td className="col-span-2 px-3 py-4 text-sm text-gray-500 text-right">
                <div className="flex justify-end items-center h-full">
                  <Switch
                    checked={seat.active}
                    disabled={
                      updatingSeatIds.includes(seat.id) || (!seat.active && isMaxSeatsReached)
                    }
                    onChange={() => handleToggleSeat(seat)}
                  />
                </div>
              </td>
            </tr>
          ))}
          {size(sortedSeats) === 0 && (
            <tr className="grid grid-cols-12">
              <td className="col-span-12 px-3 py-4 text-sm text-gray-500 text-center">
                No seats available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {size(sortedSeats) > 0 && (
        <IssuesTablePagination
          totalTasks={filteredSeats.length}
          indexOfFirstTask={indexOfFirstSeat + 1}
          indexOfLastTask={Math.min(indexOfLastSeat, filteredSeats.length)}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          previousButtonDisabled={previousButtonDisabled}
          nextButtonDisabled={nextButtonDisabled}
          unit="members"
        />
      )}
    </div>
  );
};
